import React from 'react';
import moment from 'moment-timezone';

import { useAudioPlayer } from './AudioPlayer/AudioPlayerContext';

const PreviewBeam = ({
  metadata,
  currentId,
  onScrollToAndHighlight,
  onShowEntryLoading,
  onDeleteEntry,
  isEntryLoading,
  repliesMap,
}) => {
  const { isPlaying, play, pause, currentUrl } = useAudioPlayer();

  const PREVIOUS = 'Предшествующий';
  const NEXT = 'След.';
  const PRIVATE = 'Private';
  const FIRST_BEAM = 'Первый бим';

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  // Parse the entry_datetime as UTC and then convert to the local timezone
  const date = moment.utc(metadata.entry_datetime).tz(timeZone);
  // Format the date using moment-timezone
  // const localizedDate = date.format('DD MMMM YYYY [в] HH:mm');
  const localizedTime = date.format('HH:mm');
  // Get the relative time using moment
  // const timeAgo = date.fromNow();

  const handleTogglePlayer = event => {
    event.stopPropagation();
    isPlaying && currentUrl === metadata.audio_url ? pause() : play(metadata.audio_url);
  };

  return (
    <div>
      {metadata.original_beam_id && (
        <button
          key={currentId}
          onClick={() => onScrollToAndHighlight(metadata.original_beam_id)}
          className="flex mt-4 -mb-[0.6rem] ml-[0.8rem]"
        >
          <div className="flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className=" text-slate-200  rotate-90"
              width="44"
              height="44"
              viewBox="0 0 24 24"
              strokeWidth="1.1"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M19 19h-6a8 8 0 0 1 -8 -8v-6" />
            </svg>
            <div>
              <div className="text-sm -mt-1 bg-slate-100 text-slate-700 px-2 -ml-[0.6rem] py-1 rounded-full">
                {PREVIOUS}
              </div>
            </div>
          </div>
        </button>
      )}
      <div
        onClick={() =>
          onShowEntryLoading(currentId, metadata.entry_id, metadata.is_private ? 'True' : 'False')
        }
      >
        <div
          id={metadata.entry_id}
          className="rounded-3xl cursor-default px-4 py-3 bg-white border-2 border-slate-400/20 mb-4 transition ease-in-out duration-100 hover:bg-slate-200"
        >
          <div className="flex justify-between mb-2">
            <div className="flex items-center gap-2">
              <div className="border border-transparent text-xs px-2 py-1 text-slate-800 font-medium rounded-full bg-slate-100 inline-flex ">
                {localizedTime}
              </div>
              {!metadata.original_beam_id && (
                <div className="border border-rose-600 text-rose-600 text-xs px-2 py-1 font-medium rounded-full">
                  {FIRST_BEAM}
                </div>
              )}
            </div>
            {metadata.is_private && <div>{PRIVATE}</div>}
            <button
              onClick={e => {
                e.stopPropagation();
                onDeleteEntry(metadata.entry_id, metadata.is_private ? 'True' : 'False');
              }}
              className=" hover:bg-slate-300 text-slate-300 hover:text-slate-600 font-normal text-base px-2 -mr-2 rounded-full flex items-center focus:outline-none focus:shadow-outline"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                />
              </svg>
            </button>
          </div>
          <div className="font-semibold text-sm mb-2">{metadata.entry_prompt_title}</div>
          <div className="flex justify-between items-center mb-2 text-slate-800">
            <div className="flex gap-3">
              {metadata.audio_url && (
                <div>
                  <button
                    onClick={handleTogglePlayer}
                    className="p-1.5 bg-gradient-to-tr from-[#cb2d3e] to-[#ef473a] hover:to-[#cb2d3e] text-white flex items-center justify-center rounded-full"
                  >
                    {isPlaying && currentUrl === metadata.audio_url ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2}
                        stroke="currentColor"
                        className="w-4 h-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15.75 5.25v13.5m-7.5-13.5v13.5"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2}
                        stroke="currentColor"
                        className="w-4 h-4 -mr-px "
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
                        />
                      </svg>
                    )}
                  </button>
                </div>
              )}
              {metadata.text.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  {index < metadata.text.split('\n').length - 1 && <br />}
                </React.Fragment>
              ))}
            </div>
            <div className=" text-slate-500">
              <div className="">
                {isEntryLoading[currentId] ? (
                  <svg
                    id={currentId}
                    aria-hidden="true"
                    className="w-4 h-4  text-rose-500 animate-spin dark:text-gray-600 fill-slate-50"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                ) : (
                  <svg
                    id={currentId}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                    />
                  </svg>
                )}
              </div>
            </div>
          </div>
          <div className="text-sm flex justify-start items-center text-slate-500">
            {/* {localizedDate} */}
          </div>
        </div>
      </div>
      <div className="ml-[0.8rem] mb-2">
        {repliesMap[metadata.entry_id] &&
          repliesMap[metadata.entry_id].map(reply => (
            <button
              key={reply.id}
              onClick={() => onScrollToAndHighlight(reply.metadata.entry_id)}
              className="-mt-2"
            >
              <div className="flex pb-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className=" text-slate-200 -mt-[1.1rem]"
                  width="44"
                  height="44"
                  viewBox="0 0 24 24"
                  strokeWidth="1.1"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M19 19h-6a8 8 0 0 1 -8 -8v-6" />
                </svg>
                <div className="text-sm mt-1 bg-slate-100 hover:bg-slate-300 text-slate-700 px-2 -ml-[0.6rem] py-1 rounded-full">
                  {NEXT}
                </div>
              </div>
            </button>
          ))}
      </div>
    </div>
  );
};

export default PreviewBeam;

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth, useUser } from '@clerk/clerk-react';

import { routes } from '../../const/routes';
import { Button, Switcher, Textarea, Typography } from '../../ui';
import { ReactComponent as MagicIcon } from '../../ui/icons/magic.svg';
import { ReactComponent as UserVoiceIcon } from '../../ui/icons/user-voice.svg';
import { ReactComponent as PlayIcon } from '../../ui/icons/play.svg';
import { ReactComponent as PauseIcon } from '../../ui/icons/pause.svg';
import { PLACEHOLDER_QUESTION_TITLE, PLACEHOLDER_QUESTION_ID } from '../input';
import RecorderWidgetIcon from './RecorderWidgetIcon';
import { useRecorderWidget, RECORD_STATE } from './RecorderWidgetContext';
import { useAudioPlayer } from '../AudioPlayer/AudioPlayerContext';
import { ReactComponent as ArrowRightIcon } from './assets/arrow-right.svg';
import { ReactComponent as DeleteIcon } from './assets/delete.svg';
import './RecorderWidget.css';

const RECORDING_STATUS = {
  RECORDING: 'recording',
  PAUSED: 'paused',
  COMPLETE: 'complete',
  CANCELED: 'canceled',
  INACTIVE: 'inactive',
};

// Helper function to format the timer into mm:ss format
const formatTime = time => {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};

function millisToMinutesAndSeconds(millis) {
  const minutes = Math.floor(millis / 60000);
  const seconds = ((millis % 60000) / 1000).toFixed(0);

  return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
}

const MAX_RECORD_TIME = 420000; // 7 minutes
const IS_VOICE = 'isVoice';
const IS_INCOGNITO = 'isIncognito';

const WHAT_ARE_YOU_THINKING_ABOUT_TODAY = 'О чём думаешь сегодня?';
const LISTENING_TO_YOU = 'Clique слушает тебя';
const EDIT_YOUR_THOUGHT = 'Отредактируй свою мысль';
const DONE = 'Готово';
const VOICE = 'Голос';
const TEXT = 'Текст';
const LISTENING = 'Слушаю...';
const TRANSCRIBING_PLACEHOLDER = 'Расшифровываю запись...';
const RESUMED_LISTENING = 'Продолжаю слушать...';
const PAUSED_LISTENING = 'Запись на паузе';
const DELETE_VOICE = 'Удалить голос';
const BEAM_IT = 'Опубликовать';
const TRANSCRIBING = 'Обработка...';
const INPUT_PLACEHOLDER = 'Напишите свой бим';
const GENERATING = 'Генерирую...';
const BEAMING_THOUGHTS = 'Загружаю бим в коллективное сознание...';
const PUBLISH_INCOGNITO = 'Опубликовать инкогнито';
const IMPROVE_TEXT = 'Улучшить текст';
const TEXT_IMPROVED = 'Текст улучшен';
const DURATION = 'длительность';

function RecorderWidget() {
  const { userId } = useAuth();
  const { user } = useUser();
  const navigate = useNavigate();
  const {
    isRecorderWidgetOpen: isOpen,
    recorderWidgetState,
    showPreviewRecorderWidget,
    showRecorderWidget,
  } = useRecorderWidget();
  const { isPlaying, currentUrl, play, pause, stop } = useAudioPlayer();
  const mediaRecorderRef = useRef(null);
  const textareaRef = useRef(null); // Create a ref for the textarea
  const recordingTimeoutRef = useRef(null); // For stopping the recording
  const wakeLockRef = useRef(null);
  const [response, setResponse] = useState(null);
  const [timer, setTimer] = useState(null);
  const [intervalId, setIntervalId] = useState(null);
  const [isResponseLoading, setIsResponseLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAudioTranscribing, setIsAudioTranscribing] = useState(false);
  const [improvedTextDone, setImprovedTextDone] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isImproveEntryTextLoading, setIsImproveEntryTextLoading] = useState(false);
  const [isOnVoice, setIsOnVoice] = useState(localStorage.getItem(IS_VOICE) === 'true');
  const [isIncognito, setIsIncognito] = useState(localStorage.getItem(IS_INCOGNITO) === 'true');
  const [retryCount, setRetryCount] = useState(0);
  const [originalBeamId, setOriginalBeamId] = useState('');
  const [text, setText] = useState('');
  const [statusText, setStatusText] = useState('');
  const [audioUrl, setAudioUrl] = useState('');
  const [placeholderQuestionTitle, setPlaceholderQuestionTitle] = useState(localStorage.getItem(PLACEHOLDER_QUESTION_TITLE));
  const [placeholderQuestionId, setPlaceholderQuestionId] = useState(localStorage.getItem(PLACEHOLDER_QUESTION_ID));
  const [recordingStatus, setRecordingStatus] = useState('');
  const [beamReplyNotification, setBeamReplyNotification] = useState('');

  useEffect(() => {
    // Clear the timeouts when the component is unmounted to avoid memory leaks
    return () => {
      if (recordingTimeoutRef.current) clearTimeout(recordingTimeoutRef.current);
      if (wakeLockRef.current) {
        wakeLockRef.current.release();
        wakeLockRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    const preventDefault = e => {
      e.preventDefault();
    };

    if (isOpen) {
      // Add event listeners to disable scroll
      window.addEventListener('wheel', preventDefault, { passive: false });
      window.addEventListener('touchmove', preventDefault, { passive: false });

      // Remove event listeners on cleanup
      return () => {
        window.removeEventListener('wheel', preventDefault);
        window.removeEventListener('touchmove', preventDefault);
      };
    }
  }, [isOpen]);

  useEffect(() => {
    if (
      isOpen &&
      isOnVoice &&
      recorderWidgetState === RECORD_STATE.NEW_RECORD
    ) {
      resetRecording();
      startRecording();
    }

    if (recorderWidgetState === RECORD_STATE.INIT) {
      completeRecording();
      setText("");
      stop();
    }

    if (!isOnVoice) {
      resetRecording();
    }
  }, [isOpen, isOnVoice, recorderWidgetState]);

  useEffect(() => {
    if (recordingStatus === RECORDING_STATUS.RECORDING) {
      // Start or resume the timer
      const id = setInterval(() => {
        setTimer(prevTimer => prevTimer + 1);
      }, 1000);
      setIntervalId(id);
    } else if (
      recordingStatus === RECORDING_STATUS.PAUSED ||
      recordingStatus === RECORDING_STATUS.COMPLETE
    ) {
      // Pause the timer
      if (intervalId) {
        clearInterval(intervalId);
        setIntervalId(null);
      }
    } else {
      // Stop and reset the timer
      clearInterval(intervalId);
      setIntervalId(null);
      setTimer(0);
    }

    return () => {
      // Clean up interval on component unmount
      clearInterval(intervalId);
    };
  }, [recordingStatus]);

  const handleTogglePlayer = () => {
    isPlaying && currentUrl === audioUrl ? pause() : play(audioUrl);
  };

  const handleToggleVoiceOrText = (isOn) => {
    setIsOnVoice(isOn);
    localStorage.setItem(IS_VOICE, `${isOn}`);
  };

  const handleDone = e => {
    e.stopPropagation();
    if (isOnVoice) {
      completeRecording();
    }
    showPreviewRecorderWidget();
  };

  const blobToBase64 = blob => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const fetchWithTimeout = (url, options = {}, timeout = 8000) => {
    // Create an AbortController instance
    const controller = new AbortController();
    const { signal } = controller;

    // Set a timeout to abort the request
    const timeoutId = setTimeout(() => controller.abort(), timeout);

    // Add the signal to the options
    options.signal = signal;

    return fetch(url, options)
      .then(response => {
        // Clear the timeout if the request completes
        clearTimeout(timeoutId);
        return response;
      })
      .catch(error => {
        // Check if the request was aborted
        if (error.name === 'AbortError') {
          throw new Error('Request timed out');
        }
        throw error;
      });
  };

  const handleRecordCompleteText = async responseText => {
    setIsAudioTranscribing(false);
    setAudioUrl(responseText.audio_url);
    setText(responseText.transcription);
    console.log('TAGS:', responseText.tags.tags);
  };

  const sendAudioForTranscription = async (audioBlob, retryCount = 0, maxRetries = 5) => {
    try {
      const base64Audio = await blobToBase64(audioBlob);

      // Append the base64 audio data to FormData
      const formData = new FormData();
      formData.append('audio', base64Audio);

      // Make a POST request to the backend
      const response = await fetchWithTimeout(
        process.env.REACT_APP_MAIN_URL + '/transcribe',
        {
          method: 'POST',
          body: formData,
        },
        30000
      );
      // Позже добавить корреляцию с размером блоба

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      // Parse and handle the response
      const data = await response.json();
      console.log('Transcription Data:', data);
      setIsLoading(false);
      await handleRecordCompleteText(data);
      setRetryCount(0); // Reset retry count on success
      setStatusText('Transcription successful!');
    } catch (error) {
      console.error('Error in transcription:', error);
      if (retryCount < maxRetries) {
        setText(`Продолжаю транскрипцию... Попытка ${retryCount + 1}/${maxRetries}`);
        setTimeout(
          () => {
            sendAudioForTranscription(audioBlob, retryCount + 1, maxRetries);
          },
          2500 * (retryCount + 1)
        ); // Exponential back-off
      } else {
        console.error('Max retries reached. Giving up.');
        setText('Max retries reached. Please check your connection and retry.');
      }
    }
  };

  const handleRecordComplete = status => {
    setIsAudioTranscribing(true);
    setText(status);
  };

  const pauseRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.pause();
      setRecordingStatus(RECORDING_STATUS.PAUSED);
      handleRecordComplete(PAUSED_LISTENING);
    }
  };

  // Handle visibility change (e.g., control center or background)
  const handleVisibilityChange = () => {
    if (
      document.visibilityState === 'hidden' &&
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state === RECORDING_STATUS.RECORDING
    ) {
      pauseRecording();
      console.log('Recording stopped due to app going to the background');
    } else if (mediaRecorderRef.current.state !== RECORDING_STATUS.INACTIVE) {
      resumeRecording();
    } else {
      startRecording();
    }
  };

  const handleToggleRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state === RECORDING_STATUS.RECORDING
    ) {
      pauseRecording();
    } else {
      resumeRecording();
    }
  };

  const resumeRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.resume();
      setRecordingStatus('recording');
      handleRecordComplete(RESUMED_LISTENING);
    }
    // Clear any data you've stored from the recording
  };

  const handleCheckboxChangeIncognito = (isIncognito) => {
    setIsIncognito(isIncognito);
    localStorage.setItem(IS_INCOGNITO, `${isIncognito}`);
  };

  const clearInputText = () => {
    setText('');
    setStatusText('');
    setImprovedTextDone(false);
  };

  const handleKeyDown = async event => {
    if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
      await submitForm(event);
    }
  };

  const clearBeamReplyNotification = () => {
    setBeamReplyNotification('');
    setOriginalBeamId('');
    textareaRef.current.placeholder = INPUT_PLACEHOLDER; // Set placeholder to " "
  };

  const startRecording = async () => {
    try {
      // Request audio recording permission and get the audio stream
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });

      // Initialize the media recorder
      mediaRecorderRef.current = new MediaRecorder(stream);
      const audioChunks = [];

      // Event triggered when recording stops
      mediaRecorderRef.current.onstop = async () => {
        // Check if any audio data was recorded
        if (audioChunks.length === 0) {
          console.error('No audio chunks available');
          // setStatusText('No audio data was recorded.');
          return;
        }

        // Create an audio blob from the collected chunks
        const audioBlob = new Blob(audioChunks, { type: 'audio/mp4' });
        console.log(`Audio Blob Size: ${audioBlob.size} bytes`);

        if (audioBlob.size === 0) {
          console.error('Audio Blob is empty.');
          // setStatusText('The audio recording is empty. Please try again.');
          return;
        }

        // Send the audio blob for transcription
        await sendAudioForTranscription(audioBlob);
      };

      // Collect audio data chunks as they become available
      mediaRecorderRef.current.ondataavailable = event => {
        if (event.data && event.data.size > 0) {
          console.log('Audio chunk captured:', event.data);
          audioChunks.push(event.data);
        } else {
          console.warn('Empty or missing audio data in this chunk.');
        }
      };

      // Start the media recorder
      mediaRecorderRef.current.start();
      setRecordingStatus(RECORDING_STATUS.RECORDING);
      handleRecordComplete(LISTENING);
      setStatusText('');
      setImprovedTextDone(false);

      // Set up a timer for the recording limit
      recordingTimeoutRef.current = window.setTimeout(() => {
        completeRecording();
      }, MAX_RECORD_TIME);

      // Request a wake lock if supported
      try {
        if ('wakeLock' in navigator && navigator.wakeLock.request) {
          // @ts-ignore
          wakeLockRef.current = await navigator.wakeLock.request('screen');
        }
      } catch (err) {
        console.error(`Could not obtain wake lock: ${err.message}`);
      }

      setIsRecording(true);
      document.addEventListener('visibilitychange', handleVisibilityChange);
    } catch (err) {
      // Handle possible permission issues or other errors
      console.error(`Error starting recording: ${err.message}`);
      setStatusText('Recording could not start. Please check your device settings or permissions.');
    }
  };

  const completeRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      setRecordingStatus(RECORDING_STATUS.COMPLETE);
      handleRecordComplete(TRANSCRIBING_PLACEHOLDER);

      // Clear the timeouts as we manually stopped the recording
      if (recordingTimeoutRef.current) clearTimeout(recordingTimeoutRef.current);
      console.log('stopping tracks');
      mediaRecorderRef.current?.stream.getTracks().forEach(track => track.stop());
    }
    if (wakeLockRef.current) {
      wakeLockRef.current.release();
      wakeLockRef.current = null;
      console.log('Wake Lock has been released');
    }
  };

  const resetRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      setRecordingStatus(RECORDING_STATUS.CANCELED);

      // Clear the timeouts as we manually stopped the recording
      if (recordingTimeoutRef.current) clearTimeout(recordingTimeoutRef.current);
      console.log('stopping tracks');
      mediaRecorderRef.current?.stream.getTracks().forEach(track => track.stop());
    }
    setIsRecording(false);
    setAudioUrl('');
  };

  const improveEntryText = async (text, retryCount = 0, maxRetries = 5) => {
    setIsImproveEntryTextLoading(true);

    try {
      const response = await fetch(process.env.REACT_APP_MAIN_URL + '/improve-text', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({ text, userId }),
      });
      const data = await response.json();

      if (response.ok) {
        setText(data.improved_text);
        setImprovedTextDone(true);
      } else {
        throw new Error(`Failed to fetch feedback: ${data.message}`);
      }
    } catch (error) {
      console.error('Error fetching feedback:', error);
      if (retryCount < maxRetries) {
        setTimeout(
          () => {
            improveEntryText(text, retryCount + 1, maxRetries);
          },
          2000 * (retryCount + 1)
        ); // Exponential back-off
      } else {
        console.error('Max retries reached. Giving up.');
      }
    } finally {
      setIsImproveEntryTextLoading(false);
    }
  };

  const handleChangeTextarea = (e) => {
    setText(e.target.value);
    setImprovedTextDone(false);
  }

  const submitForm = async (e, retryCount = 0, maxRetries = 3) => {
    e.preventDefault();
    setIsResponseLoading(true);
    setText(BEAMING_THOUGHTS);

    setPlaceholderQuestionTitle();
    setPlaceholderQuestionId();
    localStorage.setItem(PLACEHOLDER_QUESTION_TITLE, '');
    localStorage.setItem(PLACEHOLDER_QUESTION_ID, '');

    clearBeamReplyNotification();
    setStatusText('');
    setImprovedTextDone(false);

    if (!text.trim()) {
      setIsResponseLoading(false);
      setText('');
      alert('Please write something before beaming it into collective consciousness.'); // Or handle this error as appropriate for your application
      return; // Exit the function early
    }

    if (isIncognito) {
      resetRecording();
    }

    try {
      const response = await fetch(process.env.REACT_APP_MAIN_URL + '/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
          username: user.username ? user.username : '',
          user_id: userId,
          text: text,
          email: user.primaryEmailAddress.emailAddress,
          audio_url: audioUrl,
          entry_prompt_title: placeholderQuestionTitle || '',
          entry_prompt_id: placeholderQuestionId || '',
          original_beam_id: originalBeamId || '',
          is_private: false, // isPrivate
          is_incognito: isIncognito,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        setResponse(data);
        setIsResponseLoading(false);
        setText('');
        setAudioUrl('');
        textareaRef.current.placeholder = INPUT_PLACEHOLDER;
        if (mediaRecorderRef.current) {
          mediaRecorderRef.current = null;
          setRecordingStatus(RECORDING_STATUS.CANCELED);
          setIsRecording(false);
        }
        stop();
        // navigate(routes.beamById(data.entry.entry_id), { replace: true });
        navigate(`/e/${data.entry.entry_id}`, { replace: true });
        console.log('DATA: ', data);
      } else {
        throw new Error(`Server error: ${response.status}`);
      }
    } catch (error) {
      console.error('Error during form submission:', error);
      if (retryCount < maxRetries) {
        const retryDelay = 2000 * (retryCount + 1);
        setText(`Повторяю загрузку... Попытка ${retryCount + 1}/${maxRetries}`);
        console.log(`Повторяю загрузку... Попытка ${retryCount + 1}/${maxRetries}`);
        setTimeout(() => submitForm(e, retryCount + 1, maxRetries), retryDelay); // Exponential back-off
      } else {
        console.error('Max retries reached. Giving up on submission.');
        setText('Failed to beam your thoughts. Please check your connection and try again.');
        setIsResponseLoading(false);
      }
    }
  };

  return (
    <div className="recorder-widget">
      <div
        className={`flex items-center justify-center flex-col ${isOpen === true && 'bg-primary'} ${isOpen === false && 'bg-primary-out'}`}
      >
        <div
          className={`animation ${isOpen === true && 'fill-animation'} ${isOpen === false && 'fillout-animation'} ${recorderWidgetState === RECORD_STATE.PREVIEW_RECORD && 'bg-secondary'}`}
        />
        <div className="main max-w-screen-sm flex items-center justify-center flex-col w-full px-3 sm:px-0">
          {recorderWidgetState === RECORD_STATE.INIT && (
            <div className="flex flex-col items-center justify-center">
              <Typography component="h3" variant={isOpen ? "light" : "dark"} className="text-center">
                {placeholderQuestionTitle || WHAT_ARE_YOU_THINKING_ABOUT_TODAY}
              </Typography>
              <RecorderWidgetIcon
                className="clique my-0 mx-auto cursor-pointer max-w-[345px]"
                onClick={() => showRecorderWidget()}
              />
            </div>
          )}
          {recorderWidgetState === RECORD_STATE.NEW_RECORD && (
            <>
              {isOpen && (
                <Switcher
                  isInverted
                  onToggle={handleToggleVoiceOrText}
                  defaultState={isOnVoice}
                  leftLabel={VOICE}
                  rightLabel={TEXT}
                />
              )}
              {isOpen && placeholderQuestionTitle && !isOnVoice && (
                <Typography component="h3" variant="light" className="text-center mt-2 mb-5">
                  {placeholderQuestionTitle}
                </Typography>
              )}
              {isOpen && isOnVoice && (
                <RecorderWidgetIcon
                  isAnimated={mediaRecorderRef?.current?.state === RECORDING_STATUS.RECORDING}
                  className="clique my-0 mx-auto max-w-[345px]"
                  onClick={() => handleToggleRecording()}
                />
              )}
              {isOpen && !isOnVoice && (
                <Textarea
                  ref={textareaRef}
                  id="entry"
                  name="text"
                  rows={1}
                  loading={isResponseLoading || isAudioTranscribing}
                  placeholder={INPUT_PLACEHOLDER}
                  value={text}
                  onChange={handleChangeTextarea}
                  onKeyDown={handleKeyDown}
                />
              )}
              {isOpen && (
                <div className="flex items-center flex-col gap-3 text-white">
                  {isOnVoice && (
                    <>
                      <Typography component="h3" variant="light">{LISTENING_TO_YOU}</Typography>
                      <p>
                        {formatTime(timer)}
                        <span> / {millisToMinutesAndSeconds(MAX_RECORD_TIME)}</span>
                      </p>
                    </>
                  )}
                  <Button
                    variant="light"
                    Icon={ArrowRightIcon}
                    onClick={handleDone}
                    className="mt-5"
                  >
                    {DONE}
                  </Button>
                </div>
              )}
            </>
          )}
          {recorderWidgetState === RECORD_STATE.PREVIEW_RECORD && (
            <div className="w-full">
              <div className="mb-3">
                <Typography component="h1" className="mb-8">
                  {EDIT_YOUR_THOUGHT}
                </Typography>
                {recordingStatus === RECORDING_STATUS.COMPLETE && audioUrl && (
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-3">
                      <Button
                        Icon={isPlaying && currentUrl === audioUrl ? PauseIcon : PlayIcon}
                        type="button"
                        onClick={handleTogglePlayer}
                      />
                      <div className="flex flex-col">
                        <Typography component="bb">{DURATION}</Typography>
                        <Typography component="h3">{formatTime(timer)}</Typography>
                      </div>
                    </div>
                    {!isResponseLoading && (
                      <Button
                        variant="secondary"
                        Icon={UserVoiceIcon}
                        onClick={e => {
                          e.stopPropagation();
                          resetRecording();
                        }}
                      >
                        {DELETE_VOICE}
                      </Button>
                    )}
                  </div>
                )}
              </div>
              <div className="relative pt-3 bg-[#F2F2F2]">
                <div className="preview-background" />
                <form onSubmit={submitForm} className="w-full">
                  <div>
                    <Textarea
                      ref={textareaRef}
                      variant="light"
                      id="entry"
                      name="text"
                      rows={1}
                      loading={isResponseLoading || isAudioTranscribing}
                      placeholder={INPUT_PLACEHOLDER}
                      value={text}
                      onChange={handleChangeTextarea}
                      onKeyDown={handleKeyDown}
                    >
                      <Button
                        isFull
                        variant="secondary"
                        onClick={() => improveEntryText(text)}
                        loading={isImproveEntryTextLoading}
                        disabled={improvedTextDone || !text}
                        Icon={MagicIcon}
                      >
                        {isImproveEntryTextLoading && !improvedTextDone && GENERATING}
                        {!isImproveEntryTextLoading && !improvedTextDone && IMPROVE_TEXT}
                        {improvedTextDone && TEXT_IMPROVED}
                      </Button>
                    </Textarea>
                  </div>

                  <div className="flex justify-between my-5">
                    {(statusText === 'Transcription successful!' ||
                      (text &&
                        recordingStatus !== RECORDING_STATUS.RECORDING &&
                        recordingStatus !== RECORDING_STATUS.COMPLETE &&
                        recordingStatus !== RECORDING_STATUS.PAUSED &&
                        !isResponseLoading)) && (
                      <Switcher
                        variant="primary"
                        onToggle={handleCheckboxChangeIncognito}
                        defaultState={isIncognito}
                        rightLabel={PUBLISH_INCOGNITO}
                      />
                    )}

                    {text &&
                    recordingStatus !== RECORDING_STATUS.RECORDING &&
                    recordingStatus !== RECORDING_STATUS.COMPLETE &&
                    recordingStatus !== RECORDING_STATUS.PAUSED &&
                    !isResponseLoading && (
                      <Button
                        Icon={DeleteIcon}
                        onClick={e => {
                          e.stopPropagation();
                          clearInputText();
                        }}
                      />
                    )}
                  </div>

                  {recordingStatus !== RECORDING_STATUS.RECORDING &&
                  recordingStatus !== RECORDING_STATUS.PAUSED &&
                  text !== '' && (
                    <Button
                      variant="primary"
                      Icon={ArrowRightIcon}
                      isFull
                      loading={
                        isResponseLoading ||
                        isAudioTranscribing ||
                        recordingStatus === RECORDING_STATUS.RECORDING ||
                        recordingStatus === RECORDING_STATUS.PAUSED
                      }
                      type="submit"
                    >
                      {isResponseLoading || isAudioTranscribing ? TRANSCRIBING : BEAM_IT}
                    </Button>
                  )}
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default RecorderWidget;

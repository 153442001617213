import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Form = () => {
  const navigate = useNavigate();

  // useEffect(() => {
  //     const googleFormUrl = "https://docs.google.com/forms/d/e/1FAIpQLSeWUeHlV0A5Vfot47d_02fGCQuCGmEFiP65GUDaBsW5Gpag7g/viewform?usp=sf_link"; // Replace with your Google Form URL
  //     window.location.href = googleFormUrl;
  // }, [navigate]);

  return (
    <div className="h-[100svh] bg-slate-100">
      <Helmet>
        <title>Clique - Форма раннего доступа</title>
        <meta name="description" content="Заполни форму чтобы получить ранний доступ в Клик." />
        <meta property="og:title" content="Clique - Форма раннего доступа" />
        <meta property="og:description" content="Description of your page" />
        <meta property="og:url" content="https://meetclique.com/form" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://rnjyfufrpnxocfzxmeih.supabase.co/storage/v1/object/public/bot_assets/clique_logo.svg"
        />
        <link rel="preconnect" href="https://rsms.me/" />
        <link rel="stylesheet" href="https://rsms.me/inter/inter.css" />
        <script src="https://cdnjs.cloudflare.com/ajax/libs/lamejs/1.2.0/lame.min.js"></script>
        <script
          defer
          data-domain="meetclique.com"
          src="https://plausible.io/js/script.tagged-events.js"
        ></script>
        <meta name="theme-color" content="#ef473a" />
      </Helmet>
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSeWUeHlV0A5Vfot47d_02fGCQuCGmEFiP65GUDaBsW5Gpag7g/viewform?embedded=true"
        width="100%"
        height="100%"
        frameBorder="0"
        marginHeight="0"
        marginWidth="0"
      >
        Loading…
      </iframe>
    </div>
  );
};

export default Form;

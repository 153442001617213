import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { SignedIn, SignedOut, SignInButton, useUser, SignOutButton, useAuth } from '@clerk/clerk-react';
import { Popover } from '@headlessui/react';
import { formatDistanceToNowStrict } from 'date-fns';
import { ru } from 'date-fns/locale';

import {supabase} from '../../supabaseClient';
import { routes } from '../../const/routes';
import { Button } from '../../ui';
import { RECORD_STATE, useRecorderWidget } from '../RecorderWidget/RecorderWidgetContext';
import { useModal } from '../Modal/ModalContext';
import { ReactComponent as LogoTypeIcon } from './assets/logo.svg';
import { ReactComponent as BurgerIcon } from './assets/burger.svg';
import { ReactComponent as BackIcon } from './assets/back.svg';
import './Header.css';

const options = { year: 'numeric', month: 'long', day: 'numeric' };
const LOGIN = 'Войти';
const LOGOUT = 'Выйти';
const NOTIFICATIONS = 'Уведомления';
const NO_NOTIFICATIONS = 'Нет новых уведомлений';

const getFormattedDate = (createdAt) => {
  const date = new Date(createdAt);
  return `В сервисе с ${date.toLocaleDateString('ru-RU', options)}`;
}

const groupNotificationsByEntry = notifications => {
  return notifications.reduce((acc, notification) => {
    const { original_entry_id, match_score } = notification;
    if (!acc[original_entry_id]) {
      acc[original_entry_id] = {
        ...notification,
        count: 1,
        max_score: match_score, // Initialize max_score with the first notification's score
      };
    } else {
      // Increment count
      acc[original_entry_id].count += 1;
      // Update max_score if the current notification's score is higher
      if (match_score > acc[original_entry_id].max_score) {
        acc[original_entry_id].max_score = match_score;
      }
    }

    return acc;
  }, {});
};

// Function to fetch notifications from Supabase
const fetchNotifications = async userId => {
  // Fetch all notifications where match_user_id equals the given userId
  const { data, error } = await supabase
    .from('notifications')
    .select('*')
    .eq('match_user_id', userId)
    .order('created_at', { ascending: false }); // Adjust this field as per your schema

  if (error) {
    console.error('Error fetching notifications:', error.message);
    return [];
  }

  return data;
};

function Header() {
  const navigate = useNavigate();
  const { userId } = useAuth();
  const { user } = useUser();
  const { isRecorderWidgetOpen, recorderWidgetState, goBack } = useRecorderWidget();
  const { showModal, hideModal } = useModal();
  const [unreadCount, setUnreadCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [isNotificationLoading, setIsNotificationLoading] = useState(false);
  const [readStatus, setReadStatus] = useState({}); // New state for read status

  useEffect(() => {
    const count = notifications.filter(notif => !readStatus[notif.id]).length;
    setUnreadCount(count);
  }, [readStatus, notifications]);

  useEffect(() => {
    const fetchInitialNotifications = async () => {
      const initialData = await fetchNotifications(userId);
      const groupedNotifications = groupNotificationsByEntry(initialData);
      setNotifications(Object.values(groupedNotifications));

      // Set read status for fetched notifications
      const readStatusMap = initialData.reduce((acc, notif) => {
        acc[notif.id] = notif.status === 'read';
        return acc;
      }, {});
      setReadStatus(readStatusMap);
    };

    fetchInitialNotifications();

    const notificationChannel = listenForNewNotifications(userId, updateUI);

    return () => {
      supabase.removeChannel(notificationChannel);
    };
  }, [userId]);

  // Function to listen for new notifications using Supabase Realtime
  const listenForNewNotifications = (userId, updateUI) => {
    // Make sure to use the correct notation to set up a channel subscription
    const channel = supabase
      .channel(`public:notifications`)
      .on(
        'postgres_changes',
        {
          event: 'INSERT',
          schema: 'public',
          table: 'notifications',
          filter: `match_user_id=eq.${userId}`,
        },
        payload => {
          // Check if the notification is not from the same user and handle different types
          switch (payload.new.type) {
            case 'new_match':
              if (payload.new.original_entry_user_id !== userId) {
                console.log('New notification:', payload.new);
                updateUI(payload.new); // Add the new notification to the UI
              }
              break;
            case 'new_reply':
              if (payload.new.original_entry_user_id !== userId) {
                console.log('New notification:', payload.new);
                updateUI(payload.new); // Add the new notification to the UI
              }
              break;
            case 'new_chat_invite':
              if (payload.new.original_entry_user_id !== userId) {
                console.log('New notification:', payload.new);
                updateUI(payload.new); // Add the new notification to the UI
              }
              break;
            case 'invite_accepted':
              if (payload.new.original_entry_user_id !== userId) {
                console.log('New notification:', payload.new);
                updateUI(payload.new); // Add the new notification to the UI
              }
              break;
            case 'beam_chat_mention':
              if (payload.new.original_entry_user_id !== userId) {
                console.log('New notification:', payload.new);
                updateUI(payload.new); // Add the new notification to the UI
              }
              break;
            case 'beam_chat_support':
              if (payload.new.original_entry_user_id !== userId) {
                console.log('New notification:', payload.new);
                updateUI(payload.new); // Add the new notification to the UI
              }
              break;
            default:
              console.log('Unhandled notification type:', payload.new.type);
          }
        }
      )
      .subscribe();
    return channel; // Return the channel to manage subscriptions if needed
  };

  // Function to update the state with new notifications
  const updateUI = newNotification => {
    setNotifications(prev => {
      // Prepend the new notification to the list and then group
      const updatedList = [newNotification, ...prev];
      const grouped = groupNotificationsByEntry(updatedList);
      return Object.values(grouped); // Convert the grouped object back to an array
    });
  };

  const handleSignOut = () => {
    hideModal();
  }
  // Function to handle entry loading and notification deletion
  const showEntryLoading = async (notif, closePopover) => {
    setIsNotificationLoading(prevState => ({ ...prevState, [notif.id]: true }));
    // Navigate to the specified entry
    console.log(notif.original_entry_id);

    try {
      // Mark the notification as read
      const { error } = await supabase
        .from('notifications')
        .update({ status: 'read' })
        .eq('id', notif.id);

      if (error) {
        console.error('Error marking notification as read:', error.message);
        setIsNotificationLoading(prevState => ({ ...prevState, [notif.id]: false }));
        return;
      }

      // Update the read status state
      setReadStatus(prevState => {
        const newStatus = { ...prevState, [notif.id]: true };
        return newStatus;
      });
      // Handle navigation based on notification type
      switch (notif.type) {
        case 'new_match':
        case 'new_reply':
          navigate(`/e/${notif.original_entry_id}`, { replace: false });
          break;
        case 'new_chat_invite':
          navigate(`/dms/${userId}`, { replace: false });
          break;
        case 'invite_accepted':
          navigate(`/dms/${userId}`, { replace: false });
          break;
        case 'beam_chat_mention':
          navigate(`/dms/${userId}`, { replace: false });
          break;
        case 'beam_chat_support':
          navigate(`/dms/${userId}`, { replace: false });
          break;
        default:
          console.log('Unhandled notification type');
      }

      // Set the loading state to false after 3 seconds
      setTimeout(() => {
        setIsNotificationLoading(prevState => ({ ...prevState, [notif.id]: false }));
        window.scrollTo(0, 0);
        closePopover(); // Close the Popover
      }, 3000);
    } catch (error) {
      console.error('Error handling notification:', error);
      setIsNotificationLoading(prevState => ({ ...prevState, [notif.id]: false }));
    }
  };

  const handleClickToggleMenu = () => {
    showModal(
      <div className="flex flex-col items-center justify-center">
        <div className="flex flex-col items-center justify-center w-full gap-0.5 bg-opacity-75 max-w-md">
          <div className="flex items-center justify-between p-3 w-full rounded-lg bg-white">
            <div className="flex items-center space-x-2">
              {user && (
                <img src={user.imageUrl} alt="Profile" className="w-8 h-8 rounded-full" />
              )}
              <div>
                <div className="font-bold">{user && user.username}</div>
                <div className="text-sm text-gray-600">{user && getFormattedDate(user.createdAt)}</div>
              </div>
            </div>
            <SignOutButton>
              <button onClick={handleSignOut} className="text-red-500">
                {LOGOUT}
              </button>
            </SignOutButton>
          </div>
          <Popover className="sm:relative w-full">
            {({ close }) => (
              <>
                <Popover.Button
                  className={`flex items-center justify-between w-full p-3 rounded-lg bg-white`}
                >
                  <span>{NOTIFICATIONS}</span>
                  <span className={`${unreadCount > 0 ? 'bg-red-500 text-white' : 'bg-white text-black'} text-sm rounded-full px-2`}>
                    {unreadCount > 0 ? `+${unreadCount}` : ''}
                  </span>
                </Popover.Button>
                <Popover.Panel className="absolute px-2 right-0 z-10 w-full sm:w-80 mt-3">
                  <div className="flex flex-col bg-white p-1 border-2 border-rose-400 rounded-xl shadow-xl max-h-80 overflow-x-scroll ">
                    <div className="text-slate-500 font-medium text-xs ml-2 my-1">
                      {NOTIFICATIONS}
                    </div>
                    {[...notifications].map(notif => {
                      const date = new Date(notif.created_at);
                      const timeAgo = formatDistanceToNowStrict(date, {
                        addSuffix: false,
                        locale: ru,
                      });
                      return (
                        <div
                          key={notif.id}
                          onClick={() => showEntryLoading(notif, close)}
                          className={` ${readStatus[notif.id] ? '' : ''} w-full hover:bg-slate-200 px-2 py-1 rounded-lg cursor-pointer`}
                        >
                          <div
                            className={` ${readStatus[notif.id] ? 'text-slate-400' : 'text-slate-700'} flex justify-between items-center text-sm`}
                          >
                            <div className="flex items-center">
                              {notif.type == 'new_match' ? (
                                <div className="">
                                  <div className="flex items-top">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className={` ${readStatus[notif.id] ? 'text-rose-300' : 'text-rose-500'} mr-1 w-5 h-5  mt-0.5 flex-none`}
                                      width="44"
                                      height="44"
                                      viewBox="0 0 24 24"
                                      strokeWidth="2"
                                      stroke="currentColor"
                                      fill="none"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    >
                                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                      <path d="M19 8.268a2 2 0 0 1 1 1.732v8a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2v-8a2 2 0 0 1 2 -2h3" />
                                      <path d="M5 15.734a2 2 0 0 1 -1 -1.734v-8a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-3" />
                                    </svg>
                                    <div
                                      className={` ${readStatus[notif.id] ? 'font-normal' : 'font-semibold'}`}
                                    >
                                      <span className="font-normal">У тебя </span> {notif.count}{' '}
                                      мэтчей <span className="font-normal"> с </span>
                                      {notif.original_entry_username}{' '}
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ''
                              )}
                              {notif.type == 'new_reply' ? (
                                <div className="flex items-top">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={2}
                                    stroke="currentColor"
                                    className={` ${readStatus[notif.id] ? 'text-rose-300' : 'text-rose-500'} mr-1 w-5 h-5  mt-0.5 flex-none`}
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 0 1-.825-.242m9.345-8.334a2.126 2.126 0 0 0-.476-.095 48.64 48.64 0 0 0-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0 0 11.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155"
                                    />
                                  </svg>
                                  <div
                                    className={` ${readStatus[notif.id] ? 'font-normal' : 'font-semibold'}`}
                                  >
                                    {notif.original_entry_username}{' '}
                                    <span className="font-normal">ответил на твой бим.</span>
                                  </div>
                                </div>
                              ) : (
                                ''
                              )}
                              {notif.type == 'new_chat_invite' ? (
                                <div className="flex items-top">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={2}
                                    stroke="currentColor"
                                    className={` ${readStatus[notif.id] ? 'text-rose-300' : 'text-rose-500'} mr-1 w-5 h-5  mt-0.5 flex-none`}
                                  >
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                  </svg>

                                  <div
                                    className={` ${readStatus[notif.id] ? 'font-normal' : 'font-semibold'}`}
                                  >
                                    <span className="font-normal">Новый запрос на чат.</span>
                                  </div>
                                </div>
                              ) : (
                                ''
                              )}
                              {notif.type == 'invite_accepted' ? (
                                <div className="flex items-top">
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={` ${readStatus[notif.id] ? 'text-rose-300' : 'text-rose-500'} mr-1 w-5 h-5  mt-0.5 flex-none`}                                    >
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                                  </svg>
                                  <div
                                    className={` ${readStatus[notif.id] ? 'font-normal' : 'font-semibold'}`}
                                  >
                                    <span className="font-normal">Твой запрос на чат был принят!</span>
                                  </div>
                                </div>
                              ) : (
                                ''
                              )}
                              {notif.type == 'beam_chat_mention' ? (
                                <div className="flex items-top">
                                  <svg xmlns="http://www.w3.org/2000/svg" className={` ${readStatus[notif.id] ? 'text-rose-300' : 'text-rose-500'} mr-1 w-5 h-5  mt-0.5 flex-none`} width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M12 12m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" />
                                    <path d="M16 12v1.5a2.5 2.5 0 0 0 5 0v-1.5a9 9 0 1 0 -5.5 8.28" />
                                  </svg>
                                  <div
                                    className={` ${readStatus[notif.id] ? 'font-normal' : 'font-semibold'}`}
                                  >
                                    <span className="font-normal">Кто-то упомянул твой бим в чате</span>
                                  </div>
                                </div>
                              ) : (
                                ''
                              )}
                              {notif.type == 'beam_chat_support' ? (
                                <div className="flex items-top">
                                  <svg xmlns="http://www.w3.org/2000/svg" className={` ${readStatus[notif.id] ? 'text-rose-300' : 'text-rose-500'} mr-1 w-5 h-5  mt-0.5 flex-none`} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M19.5 12.572l-7.5 7.428l-7.5 -7.428a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572" />
                                    <path d="M12 6l-3.293 3.293a1 1 0 0 0 0 1.414l.543 .543c.69 .69 1.81 .69 2.5 0l1 -1a3.182 3.182 0 0 1 4.5 0l2.25 2.25" />
                                    <path d="M12.5 15.5l2 2" />
                                    <path d="M15 13l2 2" />
                                  </svg>

                                  <div
                                    className={` ${readStatus[notif.id] ? 'font-normal' : 'font-semibold'}`}
                                  >
                                    <span className="font-normal">Кто-то шлет лучи поддержки на твой бим</span>
                                  </div>
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                            <div className="">
                              {isNotificationLoading[notif.id] ? (
                                <svg
                                  id={notif.id}
                                  aria-hidden="true"
                                  className="w-4 h-4  text-rose-500 animate-spin dark:text-gray-600 fill-slate-50"
                                  viewBox="0 0 100 101"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentFill"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  id={notif.id}
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-4 h-4"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                                  />
                                </svg>
                              )}
                            </div>
                          </div>
                          <div className="flex items-center justify-between">
                            <div className="text-xs ml-[1.6rem] text-slate-400">{timeAgo}</div>
                            {notif.type == 'new_match' && (
                              <div className=" text-xs ml-2 text-slate-400">
                                Высший: {notif.max_score}
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                    {notifications.length == 0 ? (
                      <div className="ml-2 text-slate-500">{NO_NOTIFICATIONS}</div>
                    ) : (
                      <div className="px-2 mt-2 text-xs text-slate-400">
                        Всего уведомлений: {notifications.length}
                      </div>
                    )}
                  </div>
                </Popover.Panel>
              </>
            )}
          </Popover>
          {/*<div className="flex items-center justify-between p-3 w-full rounded-lg bg-white">*/}
          {/*  <span>Настройки аккаунта</span>*/}
          {/*</div>*/}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`header max-w-screen-xl my-0 mx-auto flex justify-between items-center px-3 py-3 sticky top-0`}
    >
      {!isRecorderWidgetOpen && (
        <NavLink to={routes.very_main} className="">
          <LogoTypeIcon />
        </NavLink>
      )}
      {isRecorderWidgetOpen && (
        <Button
          onClick={goBack}
          Icon={BackIcon}
          variant={recorderWidgetState === RECORD_STATE.NEW_RECORD ? 'lightInverse' : 'secondaryInverse'}
        />
      )}
      <SignedOut>
        <SignInButton
          className="plausible-event-name=Login ml-auto cursor-pointer"
          style={{ pointerEvents: 'auto' }}
        >
          <Button variant="primary">{LOGIN}</Button>
        </SignInButton>
      </SignedOut>
      <SignedIn>
        <Button
          className="ml-auto cursor-pointer"
          onClick={handleClickToggleMenu}
          Icon={BurgerIcon}
          variant="lightInverse"
          {...(recorderWidgetState === RECORD_STATE.NEW_RECORD && { variant: 'lightInverse' })}
          {...(recorderWidgetState === RECORD_STATE.PREVIEW_RECORD && { variant: 'secondaryInverse' })}
        />
      </SignedIn>
    </div>
  );
}

export default Header;

import React, { useState, useRef, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth, useUser, SignUpButton } from '@clerk/clerk-react';
import { Transition } from '@headlessui/react';
import moment from 'moment-timezone';
import 'moment/locale/ru'; // Import Russian locale
import DOMPurify from 'dompurify';

import Nav from './components/nav';
import EntrySubmit from './components/input';
import OriginalBeam from './components/OriginalBeam';
import Beam from './components/Beam';
import ReplyBeam from './components/ReplyBeam';
import MatchBeam from './components/MatchBeam';
import FilteredRelatedBeam from './components/FilteredRelatedBeam';

const MatchDetails = () => {
  console.log('Render');
  const [response, setResponse] = useState(null);
  const { userId } = useAuth();
  const { user } = useUser();
  const [isResponseGenerated, setIsResponseGenerated] = useState(false);
  const { entryId } = useParams(); // Assuming your URL parameter is named entryId
  const navigate = useNavigate(); // Initialize useNavigate hook
  const topScrollRef = useRef(); // Div for scroll
  const entrySubmitRef = useRef(); // Add this line
  const entrySubmitRefReply = useRef(); // Add this line

  const [isEntryLoading, setIsEntryLoading] = useState(false, {});
  const [navigationHistory, setNavigationHistory] = useState([]);
  const [isLoadingBack, setIsLoadingBack] = useState(false);
  const [aiQuestion, setAiQuestion] = useState('');
  const [updatePrompt, setUpdatePrompt] = useState(1);
  const [updateAIPrompt, setUpdateAIPrompt] = useState(1);

  const [placeholderQuestion, setPlaceholderQuestion] = useState('');
  const [placeholderQuestionId, setPlaceholderQuestionId] = useState('');
  const [therapistFeedback, setTherapistFeedback] = useState('');
  const [isTherapistFeedbackLoading, setIsTherapistFeedbackLoading] = useState(false);
  const [isReplySectionVisible, setIsReplySectionVisible] = useState(false);
  const [isReflectSectionVisible, setIsReflectSectionVisible] = useState(true);

  const BROWSING_THOUGHTS = 'Изучаю коллективное сознание...'; //Browsing through collective consciousness...
  const HANG = 'Подожди немного'; // Hang on a little fren
  const BACK = 'Назад'; // Back
  const CLOSE = 'Закрыть'; // Close
  const GENERATING = 'Генерирую...'; //Generating...
  const REFLECT = 'Рефлексируй'; //Generating...
  const CONTINUE = 'Продолжи'; //Generating...
  const REPLYING_TO_BEAM = 'Продолжи мысль...'; // Replying to the beam...
  const SIGN_UP_TO_REPLY = 'Войди чтобы ответить'; // Sign up to reply
  const RELATED_BEAMS = 'Мэтчи'; //Related beams
  const RELATED_BEAMS_USER = 'Oстальные мэтчи'; //Related beams
  const GREAT_JOB = 'Отлично! Твой бим ожидает мэтчи.'; //Great job! People can now connect with your thoughts!
  const FIND_IN_MY_BEAMS = 'Ты получишь уведомление когда появятся похожие бимы.'; // Find this beam in  "My beams" tab later to see the matches.
  const POWERING_UP = 'Загружаю бимы...'; // Powering up...

  const fetchResponse = async (retryCount = 0, maxRetries = 3) => {
    try {
      const pathname = window.location.pathname;
      let url;
      if (pathname.startsWith('/pe/')) {
        url = `${process.env.REACT_APP_MAIN_URL}/pe/${entryId}`;
      } else {
        url = `${process.env.REACT_APP_MAIN_URL}/e/${entryId}`;
      }

      const response = await fetch(url);
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        setResponse(data);
        console.log(data.entry.ai_question);
        setAiQuestion(data.entry.ai_question);
        setIsLoadingBack(false);
        console.log(data.entry.ai_therapist_feedback);
        // const sanitizedHtmlString = DOMPurify.sanitize(data.entry.ai_therapist_feedback, {USE_PROFILES: {html: true}});
        // console.log(sanitizedHtmlString)
        setTherapistFeedback(data.entry.ai_therapist_feedback);
        // if (data.entry.ai_therapist_feedback) {}
      } else {
        console.error('Server error:', response.status);
        if (retryCount < maxRetries) {
          console.log(`Retrying... Attempt ${retryCount + 1}/${maxRetries}`);
          setTimeout(
            () => {
              fetchResponse(retryCount + 1, maxRetries);
            },
            1000 * (retryCount + 1)
          ); // Exponential back-off
        } else {
          console.error('Max retries reached. Giving up.');
        }
      }
    } catch (error) {
      console.error('Network error:', error);
      if (retryCount < maxRetries) {
        console.log(`Retrying... Attempt ${retryCount + 1}/${maxRetries}`);
        setTimeout(
          () => {
            fetchResponse(retryCount + 1, maxRetries);
          },
          1000 * (retryCount + 1)
        ); // Exponential back-off
      } else {
        console.error('Max retries reached. Giving up.');
      }
    }
  };

  const scrollToBeam = () => {
    const element = document.getElementById(response?.entry?.entry_id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    fetchResponse();
  }, [entryId]);

  useEffect(() => {
    if (topScrollRef?.current && response?.entry?.user_id !== userId) {
      topScrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [response, userId, topScrollRef]);

  useEffect(() => {
    if (response?.entry?.entry_id || therapistFeedback) {
      scrollToBeam();
    }
  }, [response?.entry?.entry_id, therapistFeedback]);

  useEffect(() => {
    setNavigationHistory(prevHistory => {
      // Check if the current entryId is already in history to avoid duplicates
      if (prevHistory.some(historyItem => historyItem.entry_id === entryId)) {
        return prevHistory;
      }
      return [...prevHistory, { entry_id: entryId }];
    });
  }, [entryId]);

  useEffect(() => {
    // Make sure to reset the loading state
    setIsEntryLoading(false, {});

    if (response && response.originalBeam) {
      console.log('Original beam present');
    }

    // Check if the necessary data is available
    if (response && response.othersMatches) {
      console.log('Other matches present');
    }

    // Process new entry's audio URL
    if (response && response.entry.audio_url) {
      console.log('Audio URL present');
    }

    // Check and set AI question details
  }, [response]);

  useEffect(() => {
    // Check and set AI question details
    if (response && response.entry.ai_question) {
      console.log('Processing AI question');

      setIsReplySectionVisible(false);

      // Update the placeholders and notification
      setPlaceholderQuestion(response.entry.ai_question);
      setPlaceholderQuestionId('AI');

      if (entrySubmitRef.current) {
        entrySubmitRef.current.updatePlaceholder(response.entry.ai_question);
        entrySubmitRef.current.updatePlaceholderId('AI');
        entrySubmitRef.current.setOriginalBeamId(response.entry.entry_id);
        entrySubmitRef.current.updateText();
        entrySubmitRef.current.isReflection(true);
        // entrySubmitRef.current.setOriginalBeamReplyNotification("Replying to AI question...");

        entrySubmitRefReply.current.setOriginalBeamId(null); // Reset to null or an empty string
        entrySubmitRefReply.current.setOriginalBeamReplyNotification(''); // Clear notification
        entrySubmitRefReply.current.updatePlaceholder(''); // Clear placeholder
        entrySubmitRefReply.current.updatePlaceholderId(''); // Clear placeholder ID
        entrySubmitRefReply.current.updateText();
      }
    }

    // Call the asynchronous handler inside the effect
  }, [aiQuestion, updateAIPrompt, response]);

  useEffect(() => {
    if (updatePrompt >= 2) {
      setIsReflectSectionVisible(false);
      setIsReplySectionVisible(true);

      setPlaceholderQuestionId(entryId);
      if (entrySubmitRef.current) {
        entrySubmitRefReply.current.updatePlaceholder();
        entrySubmitRefReply.current.updatePlaceholderId();
        entrySubmitRefReply.current.setOriginalBeamId(entryId);
        entrySubmitRefReply.current.setOriginalBeamReplyNotification(REPLYING_TO_BEAM);
        entrySubmitRefReply.current.updateText();

        entrySubmitRef.current.setOriginalBeamId(null); // Reset to null or an empty string
        entrySubmitRef.current.setOriginalBeamReplyNotification(''); // Clear notification
        entrySubmitRef.current.updatePlaceholder(''); // Clear placeholder
        entrySubmitRef.current.updatePlaceholderId(''); // Clear placeholder ID
        entrySubmitRef.current.updateText();
      }
    }
  }, [updatePrompt]);

  const selectAIQuestion = (beamId, question) => {
    if (question) {
      setIsReflectSectionVisible(true);
      setIsReplySectionVisible(false);
      setPlaceholderQuestion(question);
      setPlaceholderQuestionId('AI');
      console.log(placeholderQuestion);

      if (entrySubmitRef.current) {
        entrySubmitRef.current.updatePlaceholder(question);
        entrySubmitRef.current.updatePlaceholderId(placeholderQuestionId);
        entrySubmitRef.current.setOriginalBeamId(beamId);
        entrySubmitRef.current.setOriginalBeamReplyNotification();
        entrySubmitRef.current.updateText();
      }
      setUpdateAIPrompt(updateAIPrompt + 1);
    }
  };

  const replyToBeam = (beamId, beamQ, beamText) => {
    if (beamId) {
      setIsReflectSectionVisible(false);
      setIsReplySectionVisible(true);

      // Update placeholders and states only when the section becomes visible
      setPlaceholderQuestion(beamQ + beamText);
      setPlaceholderQuestionId(beamId);

      entrySubmitRefReply.current.updatePlaceholder();
      entrySubmitRefReply.current.updatePlaceholderId();
      entrySubmitRefReply.current.setOriginalBeamId(beamId);
      entrySubmitRefReply.current.setOriginalBeamReplyNotification('Твой ответ или комментарий...');
      entrySubmitRefReply.current.updateText();
      setUpdatePrompt(updatePrompt + 1);
    }
  };

  if (!response) {
    return (
      <div>
        <Nav />
        <div className="flex bg-slate-50 text-slate-700 items-center justify-center w-full p-3 h-[100svh]">
          <div className="text-center">
            <div className="text-center font-medium">{BROWSING_THOUGHTS}</div>
            <div className="flex items-center justify-center mt-2 font-light">
              <svg
                aria-hidden="true"
                className="w-5 h-5 mr-2 text-slate-500 animate-spin dark:text-gray-600 fill-slate-50"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              {HANG}
            </div>
          </div>
        </div>
      </div>
    );
  }

  //   const generateTherapistFeedback = async (entryId) => {
  //     setIsTherapistFeedbackLoading(true);
  //     try {
  //         const response = await fetch(process.env.REACT_APP_MAIN_URL + "/generate-therapist-feedback/" + entryId);
  //         const data = await response.json();
  //         if (response.ok) {
  //             setTherapistFeedback(data.ai_feedback_therapist);
  //         } else {
  //             console.error('Failed to fetch feedback:', data.message);
  //         }
  //     } catch (error) {
  //         console.error('Error fetching feedback:', error);
  //     }
  //     setIsTherapistFeedbackLoading(false);
  // };

  const generateTherapistFeedback = async (entryId, retryCount = 0, maxRetries = 5) => {
    setIsTherapistFeedbackLoading(true);

    try {
      const response = await fetch(
        process.env.REACT_APP_MAIN_URL + '/generate-therapist-feedback/' + entryId
      );
      const data = await response.json();

      if (response.ok) {
        const sanitizedHtmlString = DOMPurify.sanitize(data.ai_feedback_therapist);
        setTherapistFeedback(sanitizedHtmlString);
      } else {
        throw new Error(`Failed to fetch feedback: ${data.message}`);
      }
    } catch (error) {
      console.error('Error fetching feedback:', error);
      if (retryCount < maxRetries) {
        setTimeout(
          () => {
            generateTherapistFeedback(entryId, retryCount + 1, maxRetries);
          },
          2000 * (retryCount + 1)
        ); // Exponential back-off
      } else {
        console.error('Max retries reached. Giving up.');
      }
    }

    setIsTherapistFeedbackLoading(false);
  };

  const handleResponse = responseData => {
    // Process the response data here
    // For example, setting it to the state
    setIsReplySectionVisible(false);
    setIsReflectSectionVisible(true);
    setTherapistFeedback('');
    console.log(responseData);
    if (responseData && responseData.responseId) {
      navigate(`/e/${responseData.responseId}`, { replace: true });
    }
    setResponse(responseData);
  };

  const updateIsResponseLoadingInChild = loadingState => {
    // Call the function in EntrySubmit with the new state
    if (entrySubmitRef.current) {
      entrySubmitRef.current.updateIsResponseLoading(loadingState);
    }
  };
  const closeResponses = () => {
    updateIsResponseLoadingInChild(false);
    setResponse('');
    navigate(`/`, { replace: false });
  };

  const handleGeneration = status => {
    setIsResponseGenerated(status);
  };

  const handleQuestion = question => {
    if (entrySubmitRef.current && response) {
      entrySubmitRef.current.updatePlaceholder(question);
    }
  };

  const showEntryLoading = (id, entry_id) => {
    setIsEntryLoading(prevState => ({ ...prevState, [id]: true }));

    if (userId) {
      setNavigationHistory(prevHistory => [...prevHistory, { id, entry_id }]);
      navigate(`/e/${entry_id}`, { replace: true });
    } else {
      window.location.href = 'https://accounts.meetclique.com/sign-up';
    }
  };
  let filteredRelatedBeams = [];
  let currentUserMatches = []; // Array to store the current user's matches

  // Collect a set of IDs to exclude from the related beams
  const excludeIds = new Set();

  // Add the original beam ID if available
  if (response.originalBeam) {
    excludeIds.add(response.originalBeam.entry_id);

    // Add the IDs of previous beams in the original chain
    if (response.originalBeam.previous) {
      response.originalBeam.previous.forEach(beam => {
        excludeIds.add(beam.entry_id);
      });
    }
  }

  // Case 1: Check if both beamReplies and othersMatches exist
  if (response.beamReplies && response.othersMatches) {
    // Create a Set of IDs from the beamReplies
    const repliesIds = new Set(response.beamReplies.map(match => match.id));

    // Merge the replies IDs into the exclude set
    repliesIds.forEach(id => excludeIds.add(id));

    // Separate current user's matches from othersMatches
    response.othersMatches.forEach(match => {
      if (match.metadata.user_id === userId) {
        currentUserMatches.push(match); // Add to current user's matches
        excludeIds.add(match.id); // Exclude from filtered related beams
      }
    });

    // Filter out beams that should be excluded
    filteredRelatedBeams = response.othersMatches.filter(match => !excludeIds.has(match.id));
  }
  // Case 2: If only othersMatches exists (no filtering needed)
  else if (!response.beamReplies && response.othersMatches) {
    // Separate current user's matches from othersMatches
    response.othersMatches.forEach(match => {
      if (match.metadata.user_id === userId) {
        currentUserMatches.push(match); // Add to current user's matches
        excludeIds.add(match.id); // Exclude from filtered related beams
      }
    });

    // Filter out beams that should be excluded
    filteredRelatedBeams = response.othersMatches.filter(match => !excludeIds.has(match.id));
  }
  // Case 3: If only beamReplies exists (return an empty array because no othersMatches to filter)
  else if (response.beamReplies && !response.othersMatches) {
    filteredRelatedBeams = [];
    console.log('No other matches to filter.');
  }
  // Case 4: Neither beamReplies nor othersMatches exist
  else {
    filteredRelatedBeams = [];
    console.log('No beams found.');
  }

  // Log the results for debugging
  console.log('Filtered Related Beams:', filteredRelatedBeams);
  console.log('Current User Matches:', currentUserMatches);

  const renderBackButton = () => {
    if (navigationHistory.length <= 1) return null; // No back button if there's only one or no history items

    const lastHistoryItem = navigationHistory[navigationHistory.length - 2]; // Get the second last item

    return (
      <button
        onClick={() => handleBackClick(lastHistoryItem)}
        className="bg-white hover:bg-slate-300 text-slate-400 hover:text-slate-600 font-normal text-base py-1 pl-1 pr-3 rounded-full flex items-center gap-1 focus:outline-none focus:shadow-outline"
      >
        {isLoadingBack ? (
          <svg
            aria-hidden="true"
            className="w-5 h-5  text-rose-500 animate-spin dark:text-gray-600 fill-slate-50"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
          </svg>
        )}
        <span className="text-sm">{BACK}</span>
      </button>
    );
  };

  // const handleBackClick = (lastHistoryItem) => {
  //   setIsLoadingBack(true); // Set loading state to true
  //   navigate(`/e/${lastHistoryItem.entry_id}`, { replace: true });
  //   setNavigationHistory(prevHistory => prevHistory.slice(0, -1));
  // };

  const handleBackClick = () => {
    if (navigationHistory.length > 1) {
      setIsLoadingBack(true);

      const lastHistoryItem = navigationHistory[navigationHistory.length - 2];
      setNavigationHistory(prevHistory => prevHistory.slice(0, -1));
      navigate(`/e/${lastHistoryItem.entry_id}`, { replace: true });
      setIsReplySectionVisible(false);
      setIsReflectSectionVisible(true);
    }
  };

  const connectWithUser = async (
    user_id,
    entry_id,
    username,
    email,
    is_incognito,
    entry_prompt_title,
    entry_text,
    entry_date
  ) => {
    if (is_incognito) {
      alert(
        `Твой запрос на чат с пользователем был отправлен. Пользователь увидит твой юзернейм. Зайди во вкладку "Чаты" чтобы увидеть свой запрос.`
      ); // 1-1 chats are going live very soon. In the meantime send the email to ${username}:
    } else {
      alert(
        `Твой запрос на чат с ${username} был отправлен. ${username} увидит твой юзернейм. Зайди во вкладку "Чаты" чтобы увидеть свой запрос.`
      ); // 1-1 chats are going live very soon. In the meantime send the email to ${username}:
    }
    var invite_to_user_id = user_id;
    var requester_username = user.username;
    var requester_user_id = userId;
    var requester_email = user.primaryEmailAddress.emailAddress;

    try {
      const response = await fetch(process.env.REACT_APP_MAIN_URL + '/send-dm', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
          invite_to_user_id,
          entry_id,
          username,
          email,
          requester_user_id,
          requester_username,
          requester_email,
          entry_prompt_title,
          entry_text,
          entry_date,
        }),
      });
      const data = await response.json();
    } catch (error) {
      console.error('Error fetching feedback:', error);
    }
  };

  function toPercentage(decimalStr) {
    // Convert the string to a float
    const decimalNum = parseFloat(decimalStr);

    // Check if the number is greater than 1.0
    if (decimalNum >= 1.0) {
      return '100%';
    }

    // Use regex to ensure the string only contains a valid decimal number
    const regex = /^0\.\d+$/;

    if (regex.test(decimalStr)) {
      // Multiply by 100 for percentage and round to one decimal place
      const percentage = (decimalNum * 100).toFixed(1);
      return `${percentage}%`;
    } else {
      throw new Error('Invalid decimal input');
    }
  }

  const scrollToCurrentUserMatches = () => {
    const element = document.getElementById('current_user_matches');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const formatDateOrTimeAgo = date => {
    // Set moment to use the Russian locale
    moment.locale('ru');

    // Get the browser's timezone
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Convert the date to the browser's timezone
    const zonedDate = moment.utc(date).tz(timeZone);

    // Calculate the difference in hours between now and the date
    const now = moment();
    const hoursDifference = now.diff(zonedDate, 'hours');

    // If more than 24 hours, show date in the format "D MMMM YYYY [в] HH:mm"
    if (hoursDifference > 24) {
      return zonedDate.format('D MMMM YYYY [в] HH:mm');
    } else {
      // If less than or equal to 24 hours, show relative time
      return zonedDate.fromNow();
    }
  };

  return (
    <div ref={topScrollRef}>
      <Nav />
      <div className="bg-white h-full pt-24">
        <div className=" mx-2">
          <div className="w-full max-w-3xl mx-auto ">
            {response && (
              <Transition
                show={true}
                enter="transition-opacity duration-75"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="flex items-center justify-between mt-20 mb-2 px-5">
                  <div>{renderBackButton()}</div>
                  <button
                    onClick={closeResponses}
                    className="bg-white hover:bg-slate-300 text-slate-400 hover:text-slate-600 font-normal text-base py-1 pl-2 pr-3 rounded-full flex items-center gap-1 focus:outline-none focus:shadow-outline"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    <span className="text-sm">{CLOSE}</span>
                  </button>
                </div>
                {currentUserMatches.length > 0 && (
                  <div className="w-full flex justify-center px-3 py-3">
                    <button
                      onClick={scrollToCurrentUserMatches}
                      className="bg-white hover:bg-slate-300 text-slate-600 hover:text-slate-700 font-normal border-2 border-slate-200 text-base py-2 px-3 rounded-full flex items-center gap-1 focus:outline-none focus:shadow-outline"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-4 h-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15.75 17.25 12 21m0 0-3.75-3.75M12 21V3"
                        />
                      </svg>
                      Показать мои мэтчи
                    </button>
                  </div>
                )}
                {!response.entry.ai_question && (
                  <div className="flex flex-col items-center">
                    <div className="flex items-center justify-center font-light bg-slate-100 px-4 py-3 rounded-3xl">
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5 mr-2 text-rose-500 animate-spin dark:text-rose-600 fill-white"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      {POWERING_UP}
                    </div>
                    <div className="w-0.5 h-7 bg-slate-200"></div>
                  </div>
                )}

                {response.originalBeams && response.originalBeams && (
                  <div className="w-full flex flex-col z-0 ">
                    {response.originalBeams &&
                      response.originalBeams.map(match => (
                        <OriginalBeam
                          key={match.id}
                          onClick={() => showEntryLoading(match.id, match.metadata.entry_id)}
                          data={{ ...match.metadata, id: match.id }}
                          isEntryLoading={isEntryLoading}
                          aiQuestion={response.entry.ai_question}
                        />
                      ))}
                  </div>
                )}

                <div
                  className={`bg-white border-2 shadow-lg border-slate-200 rounded-3xl ${response.entry.user_id == userId ? 'mb-10' : ''} relative`}
                >
                  <div
                    id={response.entry.entry_id}
                    className="bg-black pb-20 absolute -top-24"
                  ></div>

                  <Beam
                    data={response.entry}
                    currentUserId={userId}
                    isReplySectionVisible={isReplySectionVisible}
                    onReply={replyToBeam}
                    onConnect={connectWithUser}
                    generateTherapistFeedback={generateTherapistFeedback}
                    isTherapistFeedbackLoading={isTherapistFeedbackLoading}
                    therapistFeedback={therapistFeedback}
                  />
                </div>

                <div className=" rounded-b-md mb-20 ">
                  <div className="flex w-full">
                    {/* <div className='flex-none w-0.5 bg-slate-200 h-auto mr-3 ml-5 -mt-[2.6rem] relative z-0'></div> */}

                    <div className="flex flex-col w-full">
                      <div className="flex w-full ">
                        {/* <div className='flex-none w-0.5 bg-slate-200 h-auto mr-3 ml-5 -mt-[2.6rem] -mb-4 relative z-0'></div> */}

                        <div className="flex flex-col w-full">
                          {userId ? (
                            <div
                              className={`w-full ${response.entry.user_id == userId ? '-mt-6' : ''} `}
                            >
                              {response.entry.user_id === userId && (
                                <div className="flex flex-col items-center -mt-4">
                                  <div className="w-0.5 h-5 bg-slate-200"></div>
                                  <div className="flex gap-1 items-center border-2 p-1 rounded-full">
                                    <>
                                      <div className="">
                                        <button
                                          onClick={() =>
                                            selectAIQuestion(
                                              response.entry.entry_id,
                                              response.entry.ai_question
                                            )
                                          }
                                          className={`${isReflectSectionVisible ? 'border-2 border-rose-600 bg-rose-300/10 text-rose-600 ' : ' border-2 border-transparent bg-slate-100/50 text-slate-700'}  rounded-full py-2 px-3 flex font-medium hover:bg-slate-200`}
                                        >
                                          <span className="">{REFLECT}</span>
                                        </button>
                                      </div>
                                      <div className="">
                                        <button
                                          onClick={() =>
                                            replyToBeam(
                                              response.entry.entry_id,
                                              response.entry.entry_prompt_title,
                                              response.entry.text
                                            )
                                          }
                                          className={`${isReplySectionVisible ? 'border-2 border-rose-600 bg-rose-300/10 text-rose-600' : ' border-2 border-transparent bg-slate-100/50 text-slate-700'}  rounded-full py-2 px-4 flex font-medium hover:bg-slate-200`}
                                        >
                                          {CONTINUE}
                                        </button>
                                      </div>
                                    </>
                                  </div>
                                </div>
                              )}
                              <div
                                className={`border-2 px-2  w-full shadow-sm mt-3 rounded-3xl ${isReplySectionVisible ? '' : 'hidden'}`}
                              >
                                <EntrySubmit
                                  onReceiveResponse={handleResponse}
                                  isGeneratingResponse={handleGeneration}
                                  ref={entrySubmitRefReply}
                                  updateIsResponseLoading={updateIsResponseLoadingInChild}
                                  setPlaceholderQuestion={handleQuestion}
                                />
                              </div>
                              {response.entry.user_id === userId && (
                                <div
                                  className={`border-2 px-1 mt-1 rounded-3xl ${isReflectSectionVisible ? '' : 'hidden'}`}
                                >
                                  <div className="flex w-full">
                                    <div className=" z-20 bg-gradient-to-tr from-[#cb2d3e] to-[#ef473a] mt-1 h-full rounded-2xl flex items-end w-full">
                                      <div
                                        className={`px-2 py-1 md:px-3 md:py-3 text-left  ${!response.entry.ai_question ? 'bg-white/30 cursor-auto' : ''}  h-full w-full  text-white font-bold rounded-2xl border-slate-200/50`}
                                      >
                                        {!response.entry.ai_question ? (
                                          <div
                                            role="status"
                                            className="flex justify-center items-center text-sm"
                                          >
                                            <svg
                                              aria-hidden="true"
                                              className="w-5 h-5  text-rose-500 animate-spin dark:text-gray-600 fill-slate-50 mr-1"
                                              viewBox="0 0 100 101"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                fill="currentColor"
                                              />
                                              <path
                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                fill="currentFill"
                                              />
                                            </svg>
                                            {GENERATING}
                                            <span className="sr-only">Loading...</span>
                                          </div>
                                        ) : (
                                          <div className="flex flex-col h-full justify-between">
                                            {response.entry.ai_question}
                                            {/*<div className=''>*/}
                                            {/*  <div*/}
                                            {/*    className="text-white mt-1 font-normal  py-1 border-slate-200 rounded-full flex items-center gap-1 focus:outline-none focus:shadow-outline"  */}
                                            {/*  >*/}
                                            {/*    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mr-1">*/}
                                            {/*      <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.076-4.076a1.526 1.526 0 011.037-.443 48.282 48.282 0 005.68-.494c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />*/}
                                            {/*    </svg>*/}
                                            {/*    <span className='text-xs'>Click to reply with prompt</span>*/}
                                            {/*  </div>*/}
                                            {/*</div>*/}
                                          </div>
                                        )}
                                        {/*<div className='flex justify-center gap-1'>*/}
                                        {/*  <div className='h-1.5 w-1.5 bg-white rounded-full border border-white'></div>*/}
                                        {/*  <div className='h-1.5 w-1.5  rounded-full border border-white'></div>*/}
                                        {/*  <div className='h-1.5 w-1.5  rounded-full border border-white'></div>*/}
                                        {/*</div>*/}
                                      </div>
                                    </div>

                                    {/*<div className='flex items-center justify-center h-auto mt-1 w-10 bg-rose-600/20 hover:bg-rose-500 rounded-2xl ml-1'>*/}
                                    {/*  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">*/}
                                    {/*    <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />*/}
                                    {/*  </svg>*/}
                                    {/*</div>*/}
                                  </div>

                                  <EntrySubmit
                                    onReceiveResponse={handleResponse}
                                    isGeneratingResponse={handleGeneration}
                                    ref={entrySubmitRef}
                                    updateIsResponseLoading={updateIsResponseLoadingInChild}
                                    setPlaceholderQuestion={handleQuestion}
                                  />
                                </div>
                              )}
                            </div>
                          ) : (
                            <div className="flex flex-col items-center w-full -mt-10">
                              <div className="w-0.5 h-7 bg-slate-200"></div>
                              <SignUpButton
                                className="plausible-event-name=Signup hover:bg-rose-200 text-slate-600 cursor-pointer hover:text-slate-600 font-normal text-base py-2 px-3 border-2 border-rose-600 rounded-full flex items-center gap-1 focus:outline-none focus:shadow-outline"
                                style={{ pointerEvents: 'auto' }}
                              >
                                <span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="icon icon-tabler icon-tabler-flare w-4 h-4 mr-2"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M12 3l3 6l6 3l-6 3l-3 6l-3 -6l-6 -3l6 -3z" />
                                  </svg>
                                  {SIGN_UP_TO_REPLY}
                                </span>
                              </SignUpButton>
                            </div>
                          )}
                        </div>
                      </div>

                      {response.beamReplies && response.beamReplies.length > 0 && (
                        <div className="w-full flex flex-col z-0">
                          <div className=" ">
                            <div className=" relative">
                              <div className="flex h-14 -mb-[0.1rem] items-center w-full z-0">
                                <div className="flex-none w-0.5 bg-slate-200 h-full mx-auto relative z-0"></div>
                              </div>

                              {response.beamReplies &&
                                response.beamReplies.map(match => (
                                  <ReplyBeam
                                    key={match.id}
                                    onClick={() =>
                                      showEntryLoading(match.id, match.metadata.entry_id)
                                    }
                                    data={{ ...match.metadata, id: match.id }}
                                    isEntryLoading={isEntryLoading}
                                    aiQuestion={response.entry.ai_question}
                                  />
                                ))}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div id="current_user_matches" className="flex w-full mb-32"></div>

                  {currentUserMatches.length > 0 && (
                    <>
                      <div className="-mt-10">
                        <div className="font-bold text-rose-600 ml-4 flex items-center flex-wrap gap-2">
                          Твои мэтчи с бимом{' '}
                          {response.entry.is_incognito
                            ? 'Инкогнито пользователь'
                            : response.entry.username}
                          <button
                            onClick={scrollToBeam}
                            className="bg-white hover:bg-slate-300 text-slate-600 hover:text-slate-700 font-normal border-2 border-slate-200 text-base py-1 pl-2 pr-1 rounded-full flex items-center gap-1 focus:outline-none focus:shadow-outline"
                          >
                            Показать бим
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="h-4 w-4"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M8.25 6.75 12 3m0 0 3.75 3.75M12 3v18"
                              />
                            </svg>
                          </button>
                        </div>
                        <div className=""></div>
                        <div className="mt-2 mb-10">
                          {currentUserMatches &&
                            currentUserMatches.map(match => (
                              <MatchBeam
                                key={match.id}
                                onClick={() => showEntryLoading(match.id, match.metadata.entry_id)}
                                data={{ ...match.metadata, id: match.id, score: match.score }}
                                isEntryLoading={isEntryLoading}
                              />
                            ))}
                        </div>
                      </div>
                    </>
                  )}

                  {currentUserMatches.length > 0 || response.entry.user_id === userId ? (
                    <>
                      {response.entry.user_id !== userId ? (
                        <b className="text-slate-600 ml-4">{RELATED_BEAMS_USER}</b>
                      ) : (
                        <b className="text-slate-600 ml-4">{RELATED_BEAMS}</b>
                      )}

                      <div className="mt-2">
                        {filteredRelatedBeams &&
                          filteredRelatedBeams.map(match => (
                            <FilteredRelatedBeam
                              key={match.id}
                              onClick={() => showEntryLoading(match.id, match.metadata.entry_id)}
                              data={{ ...match.metadata, id: match.id, score: match.score }}
                              isEntryLoading={isEntryLoading}
                            />
                          ))}
                        {filteredRelatedBeams.length === 0 &&
                          (response.entry.user_id !== userId ? (
                            <>
                              <div className="ml-4 pb-2 flex items-center gap-2 text-sm text-slate-400">
                                <div className="flex flex-col gap-1">
                                  <div className="">Тут еще нет мэтчей.</div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="ml-4 pb-2 flex items-center gap-2 text-sm text-slate-400">
                                <div className="flex flex-col gap-1">
                                  <div className="">
                                    {GREAT_JOB}
                                    {/*<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-seeding h-4 w-4 inline-flex ml-1 -mt-[5px]" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">*/}
                                    {/*  <path stroke="none" d="M0 0h24v24H0z" fill="none" />*/}
                                    {/*  <path d="M12 10a6 6 0 0 0 -6 -6h-3v2a6 6 0 0 0 6 6h3" />*/}
                                    {/*  <path d="M12 14a6 6 0 0 1 6 -6h3v1a6 6 0 0 1 -6 6h-3" />*/}
                                    {/*  <path d="M12 20l0 -10" />*/}
                                    {/*</svg>*/}
                                  </div>
                                  <div className="flex">{FIND_IN_MY_BEAMS}</div>
                                </div>
                              </div>
                            </>
                          ))}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="ml-4 pb-2 flex items-center gap-2 text-sm text-slate-400">
                        <div className="flex flex-col gap-1">
                          <div className="">У тебя нет мэтчей с этим бимом.</div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </Transition>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MatchDetails;

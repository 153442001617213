import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';
import moment from 'moment-timezone';
import { useAuth } from '@clerk/clerk-react';

import { supabase } from '../supabaseClient';
import { useAudioPlayer } from './AudioPlayer/AudioPlayerContext';

const Beam = ({
  data,
  isReplySectionVisible,
  onReply,
  onConnect,
  onClick,
  generateTherapistFeedback,
  isTherapistFeedbackLoading,
  therapistFeedback,
}) => {
  const { userId } = useAuth();
  const { isPlaying, play, pause, currentUrl } = useAudioPlayer();

  const INCOGNITO_USER = 'Инкогнито пользователь';
  const CONNECT_WITH = 'Поговорить с';
  const AI_THERAPIST_FEEDBACK = 'Фидбэк AI терапевта';
  const AI_THERAPIST_GOT_FEEDBACK = 'Фидбэк AI-терапевта получен';
  const GET_AI_THERAPIST_FEEDBACK = 'Получить фидбэк';
  const VISIBLE_TO_YOU = 'Виден только тебе';
  const GENERATING = 'Генерирую...';
  const REPLY = 'Ответь на бим';

  const isCurrentUser = data.user_id === userId;
  const isIncognito = data.is_incognito;
  const displayName = isIncognito ? INCOGNITO_USER : data.username;
  const [isChatLoading, setIsChatLoading] = useState(false);
  const [chatStatus, setChatStatus] = useState('');
  const [chatId, setChatId] = useState(null);
  const navigate = useNavigate(); // Initialize useNavigate hook

  const addClassesToElements = (htmlString, classMap) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');

    Object.keys(classMap).forEach(tagName => {
      const elements = doc.getElementsByTagName(tagName);
      for (let element of elements) {
        element.classList.add(...classMap[tagName]);
      }
    });

    return doc.body.innerHTML;
  };

  const fetchChatInvites = async (userId, beams_user_id) => {
    setIsChatLoading(true);
    // Fetch all notifications where invite_from_user_id equals the given userId
    const { data, error } = await supabase
      .from('chat_invites')
      .select('*')
      .or(
        `and(invite_to_user_id.eq.${beams_user_id},invite_from_user_id.eq.${userId}),and(invite_to_user_id.eq.${userId},invite_from_user_id.eq.${beams_user_id})`
      )
      .order('created_at', { ascending: false }); // Adjust this field as per your schema

    setIsChatLoading(false);

    if (error) {
      console.error('Error fetching notifications:', error.message);
      return [];
    }

    if (data && data.length > 0) {
      console.log('Fetched invite:', data);
      // Filter the data to find the invite where invite_to_user_id matches beams_user_id
      const filteredInvite = data.find(
        invite =>
          invite.invite_to_user_id === beams_user_id || invite.invite_from_user_id === beams_user_id
      );
      console.log('Filtered invite:', filteredInvite);

      if (filteredInvite) {
        setChatStatus(filteredInvite.invite_status);
      } else {
        setChatStatus('no');
      }
    } else {
      setChatStatus('no');
    }

    return data;
  };

  const fetchChats = async (user_id, beams_user_id) => {
    // Fetch the chat where either user is the chat owner or participant
    const { data, error } = await supabase
      .from('chats')
      .select('id') // Select only the chat_id field
      .or(
        `and(chat_owner_user_id.eq.${userId},chat_participant_user_id.eq.${beams_user_id}),and(chat_owner_user_id.eq.${beams_user_id},chat_participant_user_id.eq.${userId})`
      )
      .order('created_at', { ascending: false });

    if (error) {
      console.error('Error fetching chats:', error.message);
      return null;
    }

    if (data && data.length > 0) {
      console.log('Fetched chat data:', data);
      return data[0].id;
    }

    return null; // No chat found
  };

  const insertMessage = async (type, message, entry_data) => {
    const { data, error } = await supabase.from('chat_messages').insert([message]);

    if (error) {
      console.error('Error inserting message:', error.message);
    } else {
      console.log('Message inserted:', data);
      if (type == 'express-support') {
        // Create a new chat invite notification
        const newBeamSupport = {
          type: 'beam_chat_support',
          original_entry_id: entry_data.entry_id,
          original_entry_user_id: userId,
          match_user_id: entry_data.user_id,
        };
        // Insert the new chat invite notification into the notifications table
        const { notificationData, notificationError } = await supabase
          .from('notifications')
          .insert([newBeamSupport]);

        if (notificationError) {
          console.error('Error inserting notification:', notificationError.message);
        } else {
          console.log('Support sent:', notificationData);
          // Fetch the updated list of messages
          alert('Поддержка отправлена пользователю в ваш чат.');
        }
      } else {
        // Create a new chat invite notification
        const newChatInviteNotification = {
          type: 'beam_chat_mention',
          original_entry_id: entry_data.entry_id,
          original_entry_user_id: userId,
          match_user_id: entry_data.user_id,
        };

        // Insert the new chat invite notification into the notifications table
        const { notificationData, notificationError } = await supabase
          .from('notifications')
          .insert([newChatInviteNotification]);

        if (notificationError) {
          console.error('Error inserting notification:', notificationError.message);
        } else {
          console.log('Notification inserted:', notificationData);
          // Fetch the updated list of messages
          navigate(`/dm/${chatId}`, { replace: false });
        }
      }

      // // Fetch the updated list of messages
      // navigate(`/dm/${chatId}`, { replace: false });
    }
  };

  const openChat = async type => {
    let message;

    if (type === 'express-support') {
      message = {
        chat_id: chatId,
        sent_by: userId, // Assuming you have the userId available
        role: 'beam_chat_support',
        body: data.entry_id, // Assuming data.entry_id is the correct value
        status: 'sent',
      };
    } else {
      message = {
        chat_id: chatId,
        sent_by: userId, // Assuming you have the userId available
        role: 'assistant_entry_notification',
        body: data.entry_id, // Assuming data.entry_id is the correct value
        status: 'sent',
      };
    }

    await insertMessage(type, message, data);
  };

  useEffect(() => {
    fetchChatInvites(userId, data.user_id);
    const getChatId = async () => {
      const chatId = await fetchChats(userId, data.user_id);
      setChatId(chatId); // Store the chatId in state
    };

    getChatId();
  }, [data]);

  /* eslint-disable react/prop-types */
  const SanitizedHtmlContent = ({ htmlContent }) => {
    // Map of tag names to their respective classes
    const classMap = {
      p: ['mb-3'],
      b: ['text-slate-800'],
      ul: ['ml-1'],
      li: ['mb-2'],
      div: ['mt-2'],
    };

    // Add classes to specified tags
    const htmlWithClasses = addClassesToElements(htmlContent, classMap);

    // Sanitize the modified HTML string
    const sanitizedHtmlString = DOMPurify.sanitize(htmlWithClasses, {
      ADD_TAGS: ['p', 'b', 'ul', 'li', 'br', 'div'],
      ADD_ATTR: ['class'],
    });

    return (
      <div
        className="text-sm text-slate-700"
        dangerouslySetInnerHTML={{ __html: sanitizedHtmlString }}
      />
    );
  };

  const onConnect1 = (
    user_id1,
    entry_id1,
    username1,
    email1,
    is_incognito1,
    entry_prompt_title1,
    entry_text1,
    entry_date1
  ) => {
    onConnect(
      user_id1,
      entry_id1,
      username1,
      email1,
      is_incognito1,
      entry_prompt_title1,
      entry_text1,
      entry_date1
    );
    setChatStatus('pending');
  };

  const formatDateOrTimeAgo = date => {
    // Set moment to use the Russian locale
    moment.locale('ru');

    // Get the browser's timezone
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Convert the date to the browser's timezone
    const zonedDate = moment.utc(date).tz(timeZone);

    // Calculate the difference in hours between now and the date
    const now = moment();
    const hoursDifference = now.diff(zonedDate, 'hours');

    // If more than 24 hours, show date in the format "D MMMM YYYY [в] HH:mm"
    if (hoursDifference > 24) {
      return zonedDate.format('D MMMM YYYY [в] HH:mm');
    } else {
      // If less than or equal to 24 hours, show relative time
      return zonedDate.fromNow();
    }
  };

  const handleTogglePlayer = () => {
    isPlaying && currentUrl === data.audio_url ? pause() : play(data.audio_url);
  };

  return (
    <div className="px-4 py-4" {...(onClick && { onClick })}>
      <div className="flex justify-between">
        <div className="font-semibold mb-1 text-sm">
          {displayName} {isCurrentUser && '(ты)'}
        </div>
      </div>
      <div className="flex justify-between">
        {data.entry_prompt_title && (
          <div className="text-rose-600 text-base font-bold mb-3">{data.entry_prompt_title}</div>
        )}
      </div>
      {data.is_incognito}
      <div className="flex gap-3">
        {!isIncognito && data.audio_url && (
          <div className="-mt-0.5">
            <button
              onClick={handleTogglePlayer}
              className="p-1.5 bg-gradient-to-tr from-[#cb2d3e] to-[#ef473a] hover:to-[#cb2d3e] text-white flex items-center justify-center rounded-full"
            >
              {isPlaying && currentUrl === data.audio_url ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 5.25v13.5m-7.5-13.5v13.5"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="w-4 h-4 -mr-px "
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
                  />
                </svg>
              )}
            </button>
          </div>
        )}
        <div className="text-slate-700">
          {data.text.split('\n').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              {index < data.text.split('\n').length - 1 && <br />}
            </React.Fragment>
          ))}
        </div>
      </div>
      {!isCurrentUser && data.ai_therapist_feedback && (
        <div className="mt-2 text-slate-500 text-xs py-0.5 inline-flex  rounded-full items-center">
          {AI_THERAPIST_GOT_FEEDBACK}
        </div>
      )}
      {!isCurrentUser && (
        <div className="flex flex-wrap gap-2 mt-3">
          <button
            onClick={() => onReply(data.entry_id, data.entry_prompt_title, data.text)}
            className={`${isReplySectionVisible ? 'border-2 border-rose-600 bg-rose-300/10 text-rose-600' : ' border-2 border-slate-300  bg-slate-100/50 text-slate-700'} rounded-full py-1 px-3 flex items-center font-medium hover:bg-slate-200`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5 mr-1"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.076-4.076a1.526 1.526 0 011.037-.443 48.282 48.282 0 005.68-.494c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
              />
            </svg>
            {REPLY}
          </button>

          {isChatLoading ? (
            <div role="status" className="flex justify-center items-center text-sm">
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-rose-500 animate-spin dark:text-gray-600 fill-slate-50 mr-1"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <>
              {(() => {
                switch (chatStatus) {
                  case 'no':
                  case 'declined':
                    return (
                      <>
                        <button
                          onClick={() =>
                            onConnect1(
                              data.user_id,
                              data.entry_id,
                              data.username,
                              data.email,
                              data.is_incognito,
                              data.entry_prompt_title,
                              data.text,
                              data.entry_datetime
                            )
                          }
                          className="bg-gradient-to-tr from-[#cb2d3e] to-[#ef473a] hover:to-[#cb2d3e] text-white hover:text-white font-semibold text-base py-2 px-3 rounded-full flex items-center gap-1 focus:outline-none focus:shadow-outline"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={2}
                            stroke="currentColor"
                            className="w-5 h-5 mr-1"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 4.5v15m7.5-7.5h-15"
                            />
                          </svg>
                          <span className="text-sm">
                            {CONNECT_WITH} {data.is_incognito ? 'пользователем' : data.username}
                          </span>
                        </button>
                      </>
                    );
                  case 'pending':
                    return (
                      <div className=" text-slate-700 font-medium text-base py-2 px-3 rounded-full flex items-center gap-1 focus:outline-none focus:shadow-outline">
                        <span className="text-sm">
                          {data.is_incognito ? 'пользователем' : data.username} уже получил твой
                          запрос на чат
                        </span>
                      </div>
                    );
                  case 'accepted':
                    return (
                      <>
                        <button
                          onClick={() => openChat()}
                          className="bg-gradient-to-tr from-[#cb2d3e] to-[#ef473a] hover:to-[#cb2d3e] text-white hover:text-white font-semibold text-base py-2 px-3 rounded-full flex items-center gap-1 focus:outline-none focus:shadow-outline"
                        >
                          <span className="text-sm">
                            Открыть чат с {data.is_incognito ? 'пользователем' : data.username}
                          </span>
                        </button>
                        <button
                          onClick={() => openChat('express-support')}
                          className=" font-semibold text-base px-1 rounded-full flex items-center focus:outline-none focus:shadow-outline"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-tabler icon-tabler-heart-handshake h-6 w-6"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="#2c3e50"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M19.5 12.572l-7.5 7.428l-7.5 -7.428a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572" />
                            <path d="M12 6l-3.293 3.293a1 1 0 0 0 0 1.414l.543 .543c.69 .69 1.81 .69 2.5 0l1 -1a3.182 3.182 0 0 1 4.5 0l2.25 2.25" />
                            <path d="M12.5 15.5l2 2" />
                            <path d="M15 13l2 2" />
                          </svg>
                        </button>
                      </>
                    );
                  default:
                    return null;
                }
              })()}
            </>
          )}
        </div>
      )}
      {isCurrentUser && (
        <div className="mt-2 rounded-2xl">
          <div className="h-px flex bg-gradient-to-r from-slate-200 to-white rounded-full mb-4 mt-4"></div>
          <div className="font-semibold text-slate-500 relative text-sm flex items-center gap-2 mb-2">
            {AI_THERAPIST_FEEDBACK}
            <span className="text-slate-500 text-xs font-light">{VISIBLE_TO_YOU}</span>
          </div>
          {!therapistFeedback ? (
            <div className="font-semibold text-slate-500 relative flex items-center gap-2">
              <button
                className={`bg-slate-100 z-10 hover:bg-slate-200 text-slate-600 hover:text-slate-600 font-normal text-sm py-2 pl-3 pr-3 rounded-full  border border-slate-300 focus:outline-none focus:shadow-outline flex items-center`}
                onClick={() => generateTherapistFeedback(data.entry_id)}
                disabled={isTherapistFeedbackLoading}
              >
                {isTherapistFeedbackLoading && (
                  <svg
                    className="animate-spin mr-2 h-5 w-5 text-rose-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647zM7.078 19H12v4H7.078z"
                    ></path>
                  </svg>
                )}

                {isTherapistFeedbackLoading ? GENERATING : GET_AI_THERAPIST_FEEDBACK}
              </button>
            </div>
          ) : (
            <SanitizedHtmlContent htmlContent={therapistFeedback} />
          )}
        </div>
      )}
      <div className="text-sm flex mt-2 text-slate-500">
        {formatDateOrTimeAgo(data.entry_datetime)}
      </div>
    </div>
  );
};

export default Beam;

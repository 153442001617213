import React from 'react';

import { useModal } from './ModalContext';
import { ReactComponent as CloseIcon } from './assets/close.svg';
import './Modal.css';

const Modal = () => {
  const { isOpen, modalContent, hideModal } = useModal();

  if (!isOpen) return null;

  const handleContentClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div className="modal fixed inset-0 flex justify-center bg-[#000000E5] overflow-hidden">
      <div className="relative max-w-screen-xl my-0 mx-auto rounded-lg shadow-lg w-full max-h-screen overflow-y-auto" onClick={hideModal}>
        <button className="absolute top-3 right-3 text-2xl" onClick={hideModal}>
          <CloseIcon />
        </button>
        <div className="mt-20" onClick={handleContentClick}>{modalContent}</div>
      </div>
    </div>
  );
};

export default Modal;

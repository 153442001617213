const prefix = '/new';

export const routes = {
  very_main: `/`,
  main: `${prefix}`,
  beamById: id => `${prefix}/beams/${id}`,
  beams: `${prefix}/beams`,
  chats: `${prefix}/chats`,
  notifications: `${prefix}/notifications`,
};

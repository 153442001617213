import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as LoaderIcon } from '../icons/loader.svg';

export function Button({
  children,
  Icon,
  variant = 'light',
  className,
  isReversed,
  isFull,
  href,
  target,
  loading,
  disabled,
  ...props
}) {
  let variantClassNames;
  let variantIconStyles;

  switch (variant) {
    case 'light': {
      variantClassNames = 'bg-[#FFFFFF] color-[#292626] text-[#292626]';
      variantIconStyles = { color: '#292626' };
      break;
    }
    case 'lightInverse': {
      variantClassNames = 'bg-transparent color-[#FFFFFF] text-[#FFFFFF]';
      variantIconStyles = { color: '#FFFFFF' };
      break;
    }
    case 'primary': {
      variantClassNames = 'bg-[#EE473A] color-[#FFFFFF] text-[#FFFFFF]';
      variantIconStyles = { color: '#FFFFFF' };
      break;
    }
    case 'primaryInverse': {
      variantClassNames = 'bg-transparent color-[#EE473A] text-[#EE473A]';
      variantIconStyles = { color: '#EE473A' };
      break;
    }
    case 'secondary': {
      variantClassNames = 'bg-[#F1F3F4] color-[#292626] text-[#292626]';
      variantIconStyles = { color: '#292626' };
      break;
    }
    case 'secondaryInverse': {
      variantClassNames = 'bg-transparent color-[#F1F3F4] text-[#F1F3F4]';
      variantIconStyles = { color: '#F1F3F4' };
      break;
    }
    case 'transparent': {
      variantClassNames = 'bg-[#FFFFFF1A] color-[#FFFFFF] text-[#FFFFFF]';
      variantIconStyles = { color: '#FFFFFF1A' };
      break;
    }
    default: {
      variantClassNames = '';
      variantIconStyles = {};
      break;
    }
  }

  const component = (
    <button
      className={`button ${className ? className : ''} flex flex-row items-center justify-center gap-2 ${Icon && !children ? 'p-0' : 'py-2 px-5'} min-h-8 ${variantClassNames} ${isFull && 'w-full'} ${isReversed && 'flex-row-reverse'} rounded-full focus:outline-none focus:shadow-outline text-xs font-normal`}
      disabled={disabled || loading}
      {...props}
    >
      {(Icon && !loading) && <Icon className="icon" style={variantIconStyles} />}
      {loading && <LoaderIcon className="icon" style={variantIconStyles} />}
      {children && children}
    </button>
  );

  if (href) {
    return (
      <Link to={href} target={target} className="link">
        {component}
      </Link>
    );
  }

  return component;
}

import React, { useState, useEffect } from 'react';
import {
  SignedIn,
  SignedOut,
  SignInButton,
  UserButton,
  useAuth,
  useUser,
} from '@clerk/clerk-react';
import { Link } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { Popover } from '@headlessui/react';
import {
  formatDistanceToNow,
  formatDistanceToNowStrict,
  format,
  differenceInHours,
} from 'date-fns';
import { ru } from 'date-fns/locale';

const Nav = () => {
  const { user } = useUser();
  const navigate = useNavigate(); // Initialize useNavigate hook
  const { userId, sessionId, getToken } = useAuth();
  const TAGLINE = 'Дневник рефлексии'; // AI-powered journal
  const LOGIN = 'Войти'; // Log in
  const MY_BEAMS = 'Бимы'; // My beams
  const NOTIFICATIONS = 'Уведомления'; //Notifications
  const NO_NOTIFICATIONS = 'Нет новых уведомлений'; // No new notifications
  const [notifications, setNotifications] = useState([]);
  const [isNotificationLoading, setIsNotificationLoading] = useState(false, {});
  const location = useLocation();
  const [readStatus, setReadStatus] = useState({}); // New state for read status
  const [unreadCount, setUnreadCount] = useState(0);
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  const [messages, setMessages] = useState([])
  // Function to fetch notifications from Supabase
  const fetchNotifications = async userId => {
    // Fetch all notifications where match_user_id equals the given userId
    const { data, error } = await supabase
      .from('notifications')
      .select('*')
      .eq('match_user_id', userId)
      .order('created_at', { ascending: false }); // Adjust this field as per your schema

    if (error) {
      console.error('Error fetching notifications:', error.message);
      return [];
    }
    return data;
  };


  const fetchChatInvites = async userId => {
    // Fetch all notifications where match_user_id equals the given userId
    const { data, error } = await supabase
      .from('chat_invites')
      .select('*')
      .eq('match_user_id', userId)
      .order('created_at', { ascending: false }); // Adjust this field as per your schema

    if (error) {
      console.error('Error fetching notifications:', error.message);
      return [];
    }
    return data;
  };

  // .on(
  //   'postgres_changes',
  //   { event: 'DELETE', schema: 'public', table: 'notifications' },
  //   (payload) => {
  //     console.log('Deleted notification:', payload);
  //     removeNotification(payload.old.id); // Remove the deleted notification from the UI
  //   }
  // )


 


  // Function to listen for new notifications using Supabase Realtime
  const listenForNewNotifications = (userId, updateUI) => {
    // Make sure to use the correct notation to set up a channel subscription
    const channel = supabase
      .channel(`public:notifications`)
      .on(
        'postgres_changes',
        {
          event: 'INSERT',
          schema: 'public',
          table: 'notifications',
          filter: `match_user_id=eq.${userId}`,
        },
        payload => {
          // Check if the notification is not from the same user and handle different types
          switch (payload.new.type) {
            case 'new_match':
              if (payload.new.original_entry_user_id !== userId) {
                console.log('New notification:', payload.new);
                updateUI(payload.new); // Add the new notification to the UI
              }
              break;
            case 'new_reply':
              if (payload.new.original_entry_user_id !== userId) {
                console.log('New notification:', payload.new);
                updateUI(payload.new); // Add the new notification to the UI
              }
              break;
            case 'new_chat_invite':
              if (payload.new.original_entry_user_id !== userId) {
                console.log('New notification:', payload.new);
                updateUI(payload.new); // Add the new notification to the UI
              }
              break;
            case 'invite_accepted':
              if (payload.new.original_entry_user_id !== userId) {
                console.log('New notification:', payload.new);
                updateUI(payload.new); // Add the new notification to the UI
              }
              break;
            case 'beam_chat_mention':
              if (payload.new.original_entry_user_id !== userId) {
                console.log('New notification:', payload.new);
                updateUI(payload.new); // Add the new notification to the UI
              }
              break;
            case 'beam_chat_support':
              if (payload.new.original_entry_user_id !== userId) {
                console.log('New notification:', payload.new);
                updateUI(payload.new); // Add the new notification to the UI
              }
              break;
            default:
              console.log('Unhandled notification type:', payload.new.type);
          }
        }
      )
      .subscribe();
    return channel; // Return the channel to manage subscriptions if needed
  };

  const groupNotificationsByEntry = notifications => {
    return notifications.reduce((acc, notification) => {
      const { original_entry_id, match_score } = notification;
      if (!acc[original_entry_id]) {
        acc[original_entry_id] = {
          ...notification,
          count: 1,
          max_score: match_score, // Initialize max_score with the first notification's score
        };
      } else {
        // Increment count
        acc[original_entry_id].count += 1;
        // Update max_score if the current notification's score is higher
        if (match_score > acc[original_entry_id].max_score) {
          acc[original_entry_id].max_score = match_score;
        }
      }
      return acc;
    }, {});
  };

  // Remove a notification from the state
  const removeNotification = notificationId => {
    setNotifications(prev => prev.filter(n => n.id !== notificationId));
  };

  // Function to update the state with new notifications
  const updateUI = newNotification => {
    setNotifications(prev => {
      // Prepend the new notification to the list and then group
      const updatedList = [newNotification, ...prev];
      const grouped = groupNotificationsByEntry(updatedList);
      return Object.values(grouped); // Convert the grouped object back to an array
    });
  };

  useEffect(() => {
    const fetchInitialNotifications = async () => {
      const initialData = await fetchNotifications(userId);
      const groupedNotifications = groupNotificationsByEntry(initialData);
      setNotifications(Object.values(groupedNotifications));
  
      // Set read status for fetched notifications
      const readStatusMap = initialData.reduce((acc, notif) => {
        acc[notif.id] = notif.status === 'read';
        return acc;
      }, {});
      setReadStatus(readStatusMap);
    };
  
    fetchInitialNotifications();
  

  
    const notificationChannel = listenForNewNotifications(userId, updateUI);
  
    return () => {
      supabase.removeChannel(notificationChannel);
    };
  }, [userId]);












  // useEffect(() => {
  //   const fetchAndSubscribe = async () => {
  //     // Step 1: Fetch chat IDs
  //     // const chatIds = await fetchChatIds(userId);

  //     // if (chatIds.length === 0) {
  //     //   setUnreadMessageCount(0);
  //     //   return;
  //     // }

  //     // Step 2: Fetch messages for those chat IDs
  //     const allMessages = await fetchMessagesForChats(chatIds);

  //     // Step 3: Filter messages with status "sent" and sent by a different user
  //     const filteredMessages = filterMessages(allMessages, userId);

  //     // Set messages and unread message count
  //     setMessages(filteredMessages);
  //     setUnreadMessageCount(filteredMessages.length);

  //     // Step 4: Listen for new messages in those chats
  //     const channel = listenForNewMessages(chatIds, (newMessage) => {
  //       setMessages((prevMessages) => [...prevMessages, newMessage]);
  //       setUnreadMessageCount(messages.length);
  //     });

  //     return () => {
  //       supabase.removeChannel(channel);
  //     };
  //   };

  //   fetchAndSubscribe();
  // }, [userId]);

  

// const fetchMessagesForChats = async (chatIds) => {
//   const { data, error } = await supabase
//     .from('chat_messages')
//     .select('*')
//     .in('chat_id', chatIds)
//     .eq('status', 'sent');

//   if (error) {
//     console.error('Error fetching messages:', error.message);
//     return [];
//   }

//   return data;
// };

// const filterMessages = (messages, currentUserId) => {
//   return messages.filter(message => message.sent_by !== currentUserId);
// };

// const fetchChatIds = async (userId) => {
//   const { data, error } = await supabase
//     .from('chats')
//     .select('id')
//     .or(`chat_owner_user_id.eq.${userId},chat_participant_user_id.eq.${userId}`);

//   if (error) {
//     console.error('Error fetching chats:', error.message);
//     return [];
//   }

//   return data.map(chat => chat.id);
// };

// const listenForNewMessages = (chatIds, updateMessageUI) => {
//   if (chatIds.length === 0) return;

//   const chatIdsFilter = chatIds.join(',');

//   const channel = supabase
//     .channel('public:chat_messages')
//     .on(
//       'postgres_changes',
//       {
//         event: 'INSERT',
//         schema: 'public',
//         table: 'chat_messages',
//         filter: `status=eq.sent AND chat_id=in.(${chatIdsFilter})`,
//       },
//       payload => {
//         console.log("here!")
//         updateMessageUI(payload.new);
//       }
//     )
//     .subscribe();

//   return channel;
// };









  const redirectToSignUp = () => {
    window.location.href = 'https://smiling-dinosaur-45.accounts.dev/sign-up';
  };

  const handleRedirect = () => {
    navigate(`/`);
  };

  useEffect(() => {
    const count = notifications.filter(notif => !readStatus[notif.id]).length;
    setUnreadCount(count);
  }, [readStatus, notifications]);

  const deleteNotification = async notificationId => {
    try {
      // First, find the original_entry_id for the given notification
      const { data: notificationData, error: fetchError } = await supabase
        .from('notifications')
        .select('original_entry_id')
        .eq('id', notificationId)
        .single();

      if (fetchError) throw fetchError;

      // Delete all notifications with the same original_entry_id
      const { error: deleteError } = await supabase
        .from('notifications')
        .delete()
        .eq('original_entry_id', notificationData.original_entry_id);

      if (deleteError) throw deleteError;

      // Update state to remove all deleted notifications
      setNotifications(prev => {
        const remaining = prev.filter(
          n => n.original_entry_id !== notificationData.original_entry_id
        );
        return Object.values(groupNotificationsByEntry(remaining));
      });

      console.log('All related notifications deleted');
      return true; // Indicate successful deletion
    } catch (error) {
      console.error('Error deleting notifications:', error.message);
      return false; // Indicate deletion failure
    }
  };

  // Function to handle entry loading and notification deletion
  const showEntryLoading1 = async (notif, closePopover) => {
    setIsNotificationLoading(prevState => ({ ...prevState, [notif.id]: true }));
    // Navigate to the specified entry
    console.log(notif.original_entry_id);

    try {
      // Mark the notification as read
      const { error } = await supabase
        .from('notifications')
        .update({ status: 'read' })
        .eq('id', notif.id);

      if (error) {
        console.error('Error marking notification as read:', error.message);
        setIsNotificationLoading(prevState => ({ ...prevState, [notif.id]: false }));
        return;
      }

      // Update the read status state
      // setReadStatus(prevState => ({ ...prevState, [notif.id]: true }));
      // Update the read status state
      setReadStatus(prevState => {
        const newStatus = { ...prevState, [notif.id]: true };
        return newStatus;
      });
      // Handle navigation based on notification type
      switch (notif.type) {
        case 'new_match':
        case 'new_reply':
          navigate(`/e/${notif.original_entry_id}`, { replace: false });
          break;
        case 'new_chat_invite':
          navigate(`/dms/${userId}`, { replace: false });
          break;
        case 'invite_accepted':
          navigate(`/dms/${userId}`, { replace: false });
          break;
        case 'beam_chat_mention':
          navigate(`/dms/${userId}`, { replace: false });
          break;
        case 'beam_chat_support':
          navigate(`/dms/${userId}`, { replace: false });
          break;
        default:
          console.log('Unhandled notification type');
      }

      // Set the loading state to false after 3 seconds
      setTimeout(() => {
        setIsNotificationLoading(prevState => ({ ...prevState, [notif.id]: false }));
        window.scrollTo(0, 0);
        closePopover(); // Close the Popover
      }, 3000);
    } catch (error) {
      console.error('Error handling notification:', error);
      setIsNotificationLoading(prevState => ({ ...prevState, [notif.id]: false }));
    }
  };

  // useEffect(() => {
  //   // Check if the current location matches the expected path
  //   const matchPath = location.pathname.startsWith('/e/');
  //   if (matchPath) {
  //     // Extract the original_entry_id from the path
  //     const entryId = location.pathname.split('/e/')[1];
  //     setIsNotificationLoading(prevState => {
  //       const newState = { ...prevState };
  //       // Find the notification that matches the entryId and set it to false
  //       Object.keys(newState).forEach(key => {
  //         if (newState[key] === true && entryId) {
  //           newState[key] = false;
  //         }
  //       });
  //       return newState;
  //     });
  //   }
  // }, [location]);

  // const unreadCount = notifications.filter(notif => !readStatus[notif.id]).length;

  const handleYourEntries = () => {
    navigate(`/ye/` + userId);
  };

  const handleYourChats = () => {
    navigate(`/dms/` + userId);
  };

  return (
    <div className="fixed top-0 inset-x-0 z-40 flex justify-between items-center bg-white backdrop-blur-sm bg-opacity-40 px-3 py-2">
      <button
        onClick={handleRedirect}
        className="flex flex-col items-end font-bold relative flex-none"
      >
        <img
          className="h-[45px] md:h-[70px]"
          src="https://rnjyfufrpnxocfzxmeih.supabase.co/storage/v1/object/public/bot_assets/logo.png"
        />
        <div className=" absolute -bottom-1 left-12 md:left-[4.6rem] w-40 text-left text-[0.6rem] md:text-xs leading-3 -mr-2 md:-mr-1 md:-mt-3 text-rose-600">
          {TAGLINE}
        </div>
      </button>

      <div className="flex items-center">
        <SignedOut>
          <SignInButton
            className="plausible-event-name=Login text-slate-700 hover:text-slate-800 bg-slate-100 hover:bg-slate-200 font-bold py-3 px-5 rounded-2xl"
            style={{ pointerEvents: 'auto' }}
          >
            {LOGIN}
          </SignInButton>
        </SignedOut>
        <SignedIn>
          <div className="flex items-center gap-1">

          <button
              onClick={handleYourChats}
              className="hover:bg-slate-200/50 text-slate-800 border flex items-center font-medium py-2 px-2 md:px-4 rounded-full"
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-4 w-4">
                <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 0 1-.825-.242m9.345-8.334a2.126 2.126 0 0 0-.476-.095 48.64 48.64 0 0 0-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0 0 11.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155" />
              </svg>

              <span className="hidden md:block text-xs md:text-base ml-1">Чаты</span>
            </button>



            <Popover className="sm:relative">
              {({ open, close }) => (
                <>
                  <Popover.Button
                    className={`hover:bg-slate-200/50 text-slate-800 border flex items-center py-1 md:py-2 px-2 md:px-4 rounded-full relative outline-none`}
                  >
                    {unreadCount > 0 && (
                      <div className=" w-2 h-2 rounded-full bg-rose-600 animate-pulse mr-1"></div>
                    )}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-4 h-4 mr-1"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0"
                      />
                    </svg>
                    <span className="font-medium ">{unreadCount}</span>
                  </Popover.Button>
                  <Popover.Panel className="absolute px-2 right-0 z-10 w-full sm:w-80 mt-3">
                    <div className="flex flex-col bg-white p-1 border-2 border-rose-400 rounded-xl shadow-xl max-h-80 overflow-x-scroll ">
                      <div className="text-slate-500 font-medium text-xs ml-2 my-1">
                        {NOTIFICATIONS}
                      </div>
                      {[...notifications].map(notif => {
                        const date = new Date(notif.created_at);
                        const timeAgo = formatDistanceToNowStrict(date, {
                          addSuffix: false,
                          locale: ru,
                        });
                        return (
                          <div
                            key={notif.id}
                            onClick={() => showEntryLoading1(notif, close)}
                            className={` ${readStatus[notif.id] ? '' : ''} w-full hover:bg-slate-200 px-2 py-1 rounded-lg cursor-pointer`}
                          >
                            <div
                              className={` ${readStatus[notif.id] ? 'text-slate-400' : 'text-slate-700'} flex justify-between items-center text-sm`}
                            >
                              <div className="flex items-center">
                                {notif.type == 'new_match' ? (
                                  <div className="">
                                    <div className="flex items-top">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className={` ${readStatus[notif.id] ? 'text-rose-300' : 'text-rose-500'} mr-1 w-5 h-5  mt-0.5 flex-none`}
                                        width="44"
                                        height="44"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      >
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M19 8.268a2 2 0 0 1 1 1.732v8a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2v-8a2 2 0 0 1 2 -2h3" />
                                        <path d="M5 15.734a2 2 0 0 1 -1 -1.734v-8a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-3" />
                                      </svg>
                                      <div
                                        className={` ${readStatus[notif.id] ? 'font-normal' : 'font-semibold'}`}
                                      >
                                        <span className="font-normal">У тебя </span> {notif.count}{' '}
                                        мэтчей <span className="font-normal"> с </span>
                                        {notif.original_entry_username}{' '}
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  ''
                                )}
                                {notif.type == 'new_reply' ? (
                                  <div className="flex items-top">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth={2}
                                      stroke="currentColor"
                                      className={` ${readStatus[notif.id] ? 'text-rose-300' : 'text-rose-500'} mr-1 w-5 h-5  mt-0.5 flex-none`}
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 0 1-.825-.242m9.345-8.334a2.126 2.126 0 0 0-.476-.095 48.64 48.64 0 0 0-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0 0 11.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155"
                                      />
                                    </svg>
                                    <div
                                      className={` ${readStatus[notif.id] ? 'font-normal' : 'font-semibold'}`}
                                    >
                                      {notif.original_entry_username}{' '}
                                      <span className="font-normal">ответил на твой бим.</span>
                                    </div>
                                  </div>
                                ) : (
                                  ''
                                )}
                                {notif.type == 'new_chat_invite' ? (
                                  <div className="flex items-top">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth={2}
                                      stroke="currentColor"
                                      className={` ${readStatus[notif.id] ? 'text-rose-300' : 'text-rose-500'} mr-1 w-5 h-5  mt-0.5 flex-none`}
                                    >
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                    </svg>

                                    <div
                                      className={` ${readStatus[notif.id] ? 'font-normal' : 'font-semibold'}`}
                                    >
                                      <span className="font-normal">Новый запрос на чат.</span>
                                    </div>
                                  </div>
                                ) : (
                                  ''
                                )}
                                    {notif.type == 'invite_accepted' ? (
                                  <div className="flex items-top">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={` ${readStatus[notif.id] ? 'text-rose-300' : 'text-rose-500'} mr-1 w-5 h-5  mt-0.5 flex-none`}                                    >
                                      <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                                    </svg>
                                    <div
                                      className={` ${readStatus[notif.id] ? 'font-normal' : 'font-semibold'}`}
                                    >
                                      <span className="font-normal">Твой запрос на чат был принят!</span>
                                    </div>
                                  </div>
                                ) : (
                                  ''
                                )}
                                {notif.type == 'beam_chat_mention' ? (
                                  <div className="flex items-top">
                                    <svg xmlns="http://www.w3.org/2000/svg" className={` ${readStatus[notif.id] ? 'text-rose-300' : 'text-rose-500'} mr-1 w-5 h-5  mt-0.5 flex-none`} width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                      <path d="M12 12m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" />
                                      <path d="M16 12v1.5a2.5 2.5 0 0 0 5 0v-1.5a9 9 0 1 0 -5.5 8.28" />
                                    </svg>
                                    <div
                                      className={` ${readStatus[notif.id] ? 'font-normal' : 'font-semibold'}`}
                                    >
                                      <span className="font-normal">Кто-то упомянул твой бим в чате</span>
                                    </div>
                                  </div>
                                ) : (
                                  ''
                                )}
                                {notif.type == 'beam_chat_support' ? (
                                  <div className="flex items-top">
                                    <svg xmlns="http://www.w3.org/2000/svg" className={` ${readStatus[notif.id] ? 'text-rose-300' : 'text-rose-500'} mr-1 w-5 h-5  mt-0.5 flex-none`} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                      <path d="M19.5 12.572l-7.5 7.428l-7.5 -7.428a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572" />
                                      <path d="M12 6l-3.293 3.293a1 1 0 0 0 0 1.414l.543 .543c.69 .69 1.81 .69 2.5 0l1 -1a3.182 3.182 0 0 1 4.5 0l2.25 2.25" />
                                      <path d="M12.5 15.5l2 2" />
                                      <path d="M15 13l2 2" />
                                    </svg>

                                    <div
                                      className={` ${readStatus[notif.id] ? 'font-normal' : 'font-semibold'}`}
                                    >
                                      <span className="font-normal">Кто-то шлет лучи поддержки на твой бим</span>
                                    </div>
                                  </div>
                                ) : (
                                  ''
                                )}
                              </div>
                              <div className="">
                                {isNotificationLoading[notif.id] ? (
                                  <svg
                                    id={notif.id}
                                    aria-hidden="true"
                                    className="w-4 h-4  text-rose-500 animate-spin dark:text-gray-600 fill-slate-50"
                                    viewBox="0 0 100 101"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                      fill="currentFill"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    id={notif.id}
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-4 h-4"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                                    />
                                  </svg>
                                )}
                              </div>
                            </div>
                            <div className="flex items-center justify-between">
                              <div className="text-xs ml-[1.6rem] text-slate-400">{timeAgo}</div>
                              {notif.type == 'new_match' && (
                                <div className=" text-xs ml-2 text-slate-400">
                                  Высший: {notif.max_score}
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                      {notifications.length == 0 ? (
                        <div className="ml-2 text-slate-500">{NO_NOTIFICATIONS}</div>
                      ) : (
                        <div className="px-2 mt-2 text-xs text-slate-400">
                          Всего уведомлений: {notifications.length}
                        </div>
                      )}
                    </div>
                  </Popover.Panel>
                </>
              )}
            </Popover>

            <button
              onClick={handleYourEntries}
              className="hover:bg-slate-200/50 text-slate-800 border flex items-center font-medium py-2 px-2 md:px-4 rounded-full"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-flare w-4 h-4 mr-1"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M12 3l3 6l6 3l-6 3l-3 6l-3 -6l-6 -3l6 -3z" />
              </svg>
              <span className="text-xs md:text-base">{MY_BEAMS}</span>
            </button>
            <ul></ul>
          </div>
          <div className="flex justify-end">
            <div className="text-slate-300 antialiased flex items-center justify-center border-none border-slate-200/70 cursor-default rounded-full hover:bg-slate-200/50 px-2 py-2">
              <UserButton />
              <div className="hidden md:flex ml-2 text-left flex-col">
                <span className="font-semibold text-slate-700">{user ? user.username : ''}</span>
                {/* <span className='text-sm text-slate-500'>Welcome to Clique</span> */}
              </div>
            </div>
          </div>
        </SignedIn>
      </div>
    </div>
  );
};

export default Nav;

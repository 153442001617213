import React from 'react';
import { Link as ReactLink } from 'react-router-dom';
import { NavLink as ReactNavLink } from 'react-router-dom';

export function Link({
  className,
  activeClassName,
  isNav,
  isDisabled,
  onClick,
  children,
  ...props
}) {
  const classNames = `link ${className ? className : ''} ${isDisabled && 'cursor-not-allowed'}`;

  const handleClick = (e) => {
    if (isDisabled) {
      e.preventDefault();
    }
    onClick && onClick();
  };

  if (isNav) {
    return (
      <ReactNavLink
        className={({ isActive }) =>
          isActive ? `${classNames} ${activeClassName}` : classNames
        }
        onClick={handleClick}
        {...props}
      >
        {children}
      </ReactNavLink>
    )
  }

  return (
    <ReactLink className={classNames} onClick={handleClick} {...props}>
      {children}
    </ReactLink>
  );
}


import React, { useState, useRef, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    SignedIn,
    SignedOut,
    SignInButton,
    UserButton,
    useAuth,
    useUser,
  } from '@clerk/clerk-react';
import {
    formatDistanceToNow,
    formatDistanceToNowStrict,
    format,
    differenceInHours,
} from 'date-fns';
import { ru } from 'date-fns/locale';
import { supabase } from './supabaseClient';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import Nav from './components/nav';

import { Transition } from '@headlessui/react';


const AllChats = () => {
    const navigate = useNavigate(); // Initialize useNavigate hook
    const { userId } = useAuth();
    const { user } = useUser();

    const [chatInvites, setChatInvites] = useState([]);
    const [chats, setChats] = useState([]);

    const YOUR_CHATS = 'Твои чаты'; //Your beams
    const BROWSING_THOUGHTS = 'Изучаю коллективное сознание...'; //Browsing through collective consciousness...
    const HANG = 'Подожди немного'; // Hang on a little fren
    const CLOSE = 'Закрыть'; // Close

    const closeChats = () => {
        navigate(`/`, { replace: false });
    };


  const fetchChatInvites = async userId => {
    // Fetch all notifications where match_user_id equals the given userId
    const { data, error } = await supabase
        .from('chat_invites')
        .select('*')
        .or(`invite_to_user_id.eq.${userId},invite_from_user_id.eq.${userId}`)
        .neq('invite_status', 'accepted')
        .order('created_at', { ascending: false });
    console.log(data)
    if (error) {
      console.error('Error fetching notifications:', error.message);
      return [];
    }
    return data;
  };

  
  useEffect(() => {
    const fetchInitialNotifications = async () => {
      const initialData = await fetchChatInvites(userId);
      setChatInvites(initialData);
    };
    fetchInitialNotifications();
    fetchChats();
    const channel = listenForNewChatInvites(userId, updateUI);
    return () => {
      supabase.removeChannel(channel);
    };
  }, [userId]);

  const updateUI = newChatInvite => {
    setChatInvites(prev => {
      // Prepend the new notification to the list and then group
      const updatedList = [newChatInvite, ...prev];
      return Object.values(updatedList); // Convert the grouped object back to an array
    });
  };

    // Function to listen for new notifications using Supabase Realtime
    const listenForNewChatInvites = (userId, updateUI) => {
        // Make sure to use the correct notation to set up a channel subscription
        const channel = supabase
            .channel(`public:chat_intites`)
            .on(
            'postgres_changes',
            {
                event: 'INSERT',
                schema: 'public',
                table: 'chat_invites',
                filter: `invite_to_user_id=eq.${userId}`,
            },
            payload => {
                if (payload.invite_status !== "accepted") {
                    updateUI(payload.new); // Add the new notification to the UI
                }
                console.log(payload.new)
            }
            )
            .subscribe();
        return channel; // Return the channel to manage subscriptions if needed
    };
      

    const showEntryLoading = (id, entry_id) => {
        // setIsEntryLoading(prevState => ({ ...prevState, [id]: true }));
        if (userId) {
            // setNavigationHistory(prevHistory => [...prevHistory, { id, entry_id }]);
            navigate(`/e/${entry_id}`, { replace: false });
        } else {
            window.location.href = 'https://accounts.meetclique.com/sign-up';
        }
    };


    const fetchChats = async () => {
        // Fetch all notifications where match_user_id equals the given userId
        const { data, error } = await supabase
          .from('chats')
          .select('*')
          .or(`chat_owner_user_id.eq.${userId},chat_participant_user_id.eq.${userId}`)
          .order('created_at', { ascending: false }); // Adjust this field as per your schema
        setChats(data)
        if (error) {
          console.error('Error fetching notifications:', error.message);
          return [];
        }
        return data;
    };


    const openChat = async (chat_id) => {
        navigate(`/dm/${chat_id}`, { replace: false });

    }


    const acceptInvite = async (chat_id, invite_from_user_id, invite_from_username, invite_to_user_id) => {
        try {
            // Mark the notification as read
            const { error } = await supabase
              .from('chat_invites')
              .update({ invite_status: 'accepted' })
              .eq('id', chat_id);

           
            
            try {
                const newChat = {
                    chat_owner_user_id: userId,
                    chat_owner_username: user.username,
                    chat_participant_user_id: invite_from_user_id,
                    chat_participant_username: invite_from_username
                };
                // Insert the new chat into the chats table
                const { data, error: insertError } = await supabase
                    .from('chats')
                    .insert(newChat)
                    .select();


                const inviteAcceptNotification = {
                    type: 'invite_accepted',
                    match_user_id: invite_from_user_id,
                    original_entry_user_id: invite_to_user_id
                };
                // Insert the new chat into the chats table
                const { accept, error } = await supabase
                    .from('notifications')
                    .insert(inviteAcceptNotification)
                    .select();

                console.log("New Chat", data)
                if (insertError) {
                    console.error('Error inserting new chat:', insertError.message);
                    return;
                }
                if (data[0]) {
                // Navigate to the new chat page using the inserted chat's ID
                    navigate(`/dm/${data[0].id}`, { replace: false });
                }

            } catch (error) {
                console.error('Error handling notification:', error);
            }
           

        } catch (error) {
            console.error('Error handling notification:', error);
        }
      
    }

    const declineInvite = async (chat_id) => {
        try {
          const { error } = await supabase
            .from('chat_invites')
            .delete()
            .eq('id', chat_id);
    
          if (!error) {
            setChatInvites(prevInvites => prevInvites.filter(invite => invite.id !== chat_id));
          } else {
            console.error('Error handling notification:', error);
          }
        } catch (error) {
          console.error('Error handling notification:', error);
        }
      };


    return (
        <>
            <Nav />
            <div className="bg-white h-[100svh] pt-24 overflow-auto">

            <div className="flex flex-col items-center px-2">
          <div className="hidden bg-rose-300"></div>

          <div className="w-full max-w-3xl ">

                <Transition
                    show={true}
                    enter="transition-opacity duration-75"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className=" rounded-b-md relative">
                        <div className="sticky">
                            <div className="flex justify-between items-center">
                            <b className="text-gray-700 text-2xl">{YOUR_CHATS}</b>
                            <button
                                onClick={closeChats}
                                className="bg-slate-200 hover:bg-slate-400 text-slate-600 hover:text-slate-700 font-normal text-base py-1 pl-2 pr-3 rounded-full flex items-center gap-1 focus:outline-none focus:shadow-outline"
                            >
                                <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-5 h-5"
                                >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                                </svg>
                                <span className="text-sm">{CLOSE}</span>
                            </button>
                            </div>


                        </div>

                        <div className='mt-10'>
                            {chatInvites.length > 0 && (
                                <div className="ml-4 mb-4 text-lg text-slate-700 font-semibold">
                                    Запросы
                                </div>
                            )}
                            {[...chatInvites].map(chat_invite => {
                                const date = new Date(chat_invite.created_at);
                                const timeAgo = formatDistanceToNowStrict(date, {
                                    addSuffix: false,
                                    locale: ru,
                                });
                                return (
                                    <div key={chat_invite.id} className='bg-slate-100 p-3 border rounded-3xl mb-3'>
                                        <div className='px-1 flex items-center'>
                                            <div className="text-sm flex text-slate-500 px-1">
                                                {timeAgo}
                                            </div>
                                            {chat_invite.invite_from_user_id == userId ? (
                                                <>
                                                    Твой запрос на чат c <span className='font-semibold mx-1'> {chat_invite.to_username}</span> отправлен.
                                                </>
                                            ) : (
                                                <>
                                                    <span className='font-semibold mr-1'>{chat_invite.from_username}</span> хочет поговорить с тобой про твой бим:
                                                </>
                                            )}
                                        </div>
                                        <div
                                            onClick={() => showEntryLoading(chat_invite.id, chat_invite.invite_from_entry_id)}
                                            className='mt-3 p-2 bg-white border border-slate-200 rounded-2xl hover:border-slate-500 hover:bg-slate-50 cursor-pointer'>
                                            {chat_invite.entry_prompt_title && (
                                                <div className="text-rose-600 text-sm font-bold mb-1">{chat_invite.entry_prompt_title}</div>
                                            )}
                                            <div className="text-slate-700 text-sm">
                                                {chat_invite.entry_text ? chat_invite.entry_text : "Показать бим" }
                                            </div>
                                        </div>
                                        {chat_invite.invite_from_user_id != userId && (
                                        <div className='flex justify-end mt-2 gap-2'>
                                            <button onClick={() => declineInvite(chat_invite.id)} className={`border-2 border-slate-300  bg-slate-100/50 text-slate-700 rounded-full py-1 px-3 flex items-center font-medium hover:bg-slate-200`}>
                                                <span className="text-sm">
                                                    Отклонить
                                                </span>
                                            </button>
                                            <button
                                                className="bg-gradient-to-tr from-[#cb2d3e] to-[#ef473a] hover:to-[#cb2d3e] text-white hover:text-white font-semibold text-base py-2 px-3 rounded-full flex items-center gap-1 focus:outline-none focus:shadow-outline"
                                                onClick={() => acceptInvite(chat_invite.id, chat_invite.invite_from_user_id, chat_invite.from_username, chat_invite.invite_to_user_id)}
                                            >
                                                <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth={2}
                                                stroke="currentColor"
                                                className="w-5 h-5 mr-1"
                                                >
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                                </svg>
                                                <span className="text-sm">
                                                Принять
                                                </span>
                                            </button>
                                        </div>)}
                                    </div>      
                                );
                            })}
                            <div className="ml-4 mb-4 mt-10 text-lg text-slate-700 font-semibold">
                                Чаты
                            </div>
                            
                            {[...chats].map(chat => {
                                const date = new Date(chat.created_at);
                                const timeAgo = formatDistanceToNowStrict(date, {
                                    addSuffix: false,
                                    locale: ru,
                                });
                                const chatPartner = chat.chat_owner_user_id === userId ? chat.chat_participant_username : chat.chat_owner_username;

                                return (
                                    <div key={chat.id} className='bg-slate-100 p-3 border rounded-3xl mb-3'>

                                        <div className=' flex items-center justify-between'>
                                            <div className='flex items-center'>
                                                
                                                <div className='px-1'>
                                                    Чат с <span className='font-semibold'>{chatPartner}</span>
                                                </div>
                                            </div>
                                            <button
                                                className="bg-gradient-to-tr from-[#cb2d3e] to-[#ef473a] hover:to-[#cb2d3e] text-white hover:text-white font-semibold text-base py-2 px-3 rounded-full flex items-center gap-1 focus:outline-none focus:shadow-outline"
                                                onClick={() => openChat(chat.id)}
                                            >
                                                <span className="text-sm">
                                                    Открыть
                                                </span>
                                            </button>
                                        </div>
                                        

                                        <div className="text-sm flex text-slate-500 px-1">
                                                {timeAgo}
                                            </div>

                                    </div>      
                                );
                            })}
                        </div>
                    </div>
                </Transition>
                </div>
                </div>
            </div>
        </>
    )
}

export default AllChats;

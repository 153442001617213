import React, {forwardRef, useRef, useState, useEffect} from 'react';

const childrenOffset = 20;

export const Textarea = forwardRef(({ variant = 'transparent', children, value, ...props }, ref) => {
  let variantClassNames;
  const childrenWrapperRef = useRef();
  const [ childrenWrapperHeight, setChildrenWrapperHeight ] = useState();

  useEffect(() => {
    if (children && childrenWrapperRef?.current?.clientHeight) {
      setChildrenWrapperHeight(childrenWrapperRef?.current?.clientHeight);
    }
  }, [children, childrenWrapperRef?.current]);

  useEffect(() => {
    const element = ref.current;
    element.style.height = 'inherit';
    element.style.height = `${element.scrollHeight + 2}px`;
  }, [value]);

  switch (variant) {
    case 'transparent': {
      variantClassNames = 'bg-[#0000001A] placeholder-[#FFFFFF] placeholder-opacity-50 color-[#FFFFFF] text-[#FFFFFF]';
      break;
    }
    case 'light': {
      variantClassNames = 'bg-[#FFFFFF] placeholder-[#292626] placeholder-opacity-50 color-[#292626] text-[#292626]';
      break;
    }
    default: {
      variantClassNames = '';
      break;
    }
  }

  return (
    <div
      className={`${variantClassNames} placeholder-opacity-50 textarea-wrapper h-full w-full min-h-[170px] rounded-lg overflow-hidden p-3.5 cursor-text ${childrenWrapperHeight && 'relative'}`}
      style={childrenWrapperHeight ? { paddingBottom: `${childrenWrapperHeight + childrenOffset}px` } : {}}
      onClick={() => ref.current.focus()}
    >
      <textarea
        ref={ref}
        className={`${variantClassNames} bg-transparent textarea h-full w-full resize-none focus:outline-none`}
        rows={1}
        value={value}
        {...props}
      />
      {children && (
        <div ref={childrenWrapperRef} className="absolute bottom-4 left-3 right-3">{children}</div>
      )}
    </div>
  );
});

import React from 'react';
import ReactDOM from 'react-dom';
import { ClerkProvider } from '@clerk/clerk-react';
import { ruRU } from '@clerk/localizations';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { routes } from './const/routes';
import { AudioPlayerProvider } from './components/AudioPlayer/AudioPlayerContext';
import Main from './pages/Main';

import MyForm from './App';
import MatchDetails from './MatchDetails'; // Import the MatchDetails component
import Entries from './Entries'; // Import the MatchDetails component
import Form from './Form';
import AllChats from './AllChats';
import Chat from './components/Chat';
import './index.css';

const publishableKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

const App = () => {
  return (
    <AudioPlayerProvider>
      <BrowserRouter>
        <Routes>
          {/* New app */}
          <Route path={routes.main} element={<Main />} />

          {/* Old app */}
          <Route path="/" element={<MyForm />} />
          <Route path="/e/:entryId" element={<MatchDetails />} />
          <Route path="/pe/:entryId" element={<MatchDetails />} />
          <Route path="/ye/:user_id" element={<Entries />} />
          <Route path="/dms/:user_id" element={<AllChats />} />
          <Route path="/dm/:chatId" element={<Chat />} />
          <Route path="/form" element={<Form />} />
          {/* Add more Route components here as needed */}
        </Routes>
      </BrowserRouter>
    </AudioPlayerProvider>
  );
};

export default App;

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <ClerkProvider localization={ruRU} publishableKey={publishableKey}>
      <App />
    </ClerkProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

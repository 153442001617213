
import React, { useState, useRef, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    SignedIn,
    SignedOut,
    SignInButton,
    UserButton,
    useAuth,
    useUser,
  } from '@clerk/clerk-react';
import {
    formatDistanceToNow,
    formatDistanceToNowStrict,
    format,
    differenceInHours,
} from 'date-fns';
import { ru } from 'date-fns/locale';
import { supabase } from '../supabaseClient';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import Nav from './nav';
import moment from 'moment-timezone';


import { Transition } from '@headlessui/react';


const Chat = () => {
    const navigate = useNavigate(); // Initialize useNavigate hook
    const { userId } = useAuth();
    const { user } = useUser();

    const YOUR_CHATS = 'Твои чаты'; //Your beams
    const BROWSING_THOUGHTS = 'Изучаю коллективное сознание...'; //Browsing through collective consciousness...
    const HANG = 'Подожди немного'; // Hang on a little fren
    const CLOSE = 'Закрыть'; // Close
    const { chatId } = useParams(); // Assuming your URL parameter is named entryId
    const [chat, setChat] = useState(null);
    const [chatInvite, setChatInvite] = useState(null);
    const [isEntryLoading, setIsEntryLoading] = useState(false, {});

    const closeChats = () => {
        navigate(`/`, { replace: false });
    };






  const fetchChat = async chatId => {
    // Fetch all notifications where match_user_id equals the given userId
    const { data, error } = await supabase
      .from('chats')
      .select('*')
      .eq('id', chatId)
      .single(); // Adjust this field as per your schema
    console.log("SHOW ME:", data)
    fetchChatInvite(data.chat_owner_user_id, data.chat_participant_user_id)
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });

    if (error) {
      console.error('Error fetching notifications:', error.message);
      return [];
    }
    return data;
  };



  const fetchChatInvite = async (owner_id, participant_id) => {
    // Fetch the chat invites based on multiple conditions
    const { data, error } = await supabase
      .from('chat_invites')
      .select('*')
      .eq("invite_to_user_id",owner_id)
      .eq("invite_from_user_id",  participant_id)
      .order('created_at', { ascending: false });
  
    if (error) {
      console.error('Error fetching chat invites:', error.message);
      return [];
    }
    setChatInvite(data[0])
    console.log("INVITE", data);
    return data;
  };

  
  useEffect(() => {
    const fetchInitialChat = async () => {
      const initialData = await fetchChat(chatId);
      console.log("Here,", initialData)
      setChat(initialData);
    };
    fetchInitialChat()
    const channel = listenForChatUpdates(userId);
    return () => {
      supabase.removeChannel(channel);
    };
  }, [chatId]);




//   const updateUI = newChatMessage => {
//     setChatInvites(prev => {
//       // Prepend the new notification to the list and then group
//       const updatedList = [newChatInvite, ...prev];
//       return Object.values(updatedList); // Convert the grouped object back to an array
//     });
//   };



  
    // Function to listen for new notifications using Supabase Realtime
    const listenForChatUpdates = (userId, updateUI) => {
        // Make sure to use the correct notation to set up a channel subscription
        const channel = supabase
            .channel(`public:chat_intites`)
            .on(
            'postgres_changes',
            {
                event: 'INSERT',
                schema: 'public',
                table: 'chat_invites',
                filter: `invite_to_user_id=eq.${userId}`,
            },
            payload => {
                updateUI(payload.new); // Add the new notification to the UI
                console.log(payload.new)
            }
            )
            .subscribe();
        return channel; // Return the channel to manage subscriptions if needed
    };
      









  const [messages, setMessages] = useState([]);
  const [messageBody, setMessageBody] = useState('');
  const [loading, setLoading] = useState(false);
  const [usernames, setUsernames] = useState({});
  const [entries, setEntries] = useState([])
  const bottomRef = useRef(null);

  useEffect(() => {
    const fetchMessages = async () => {
      const { data, error } = await supabase
        .from('chat_messages')
        .select('*')
        .eq('chat_id', chatId)
        .order('created_at', { ascending: true });

      if (error) {
        console.error('Error fetching messages:', error.message);
      } else {
        setMessages(data);
      }
    };

    fetchMessages();

    // Set up real-time subscription
    const channel = supabase
      .channel(`public:chat_messages:chat_id=eq.${chatId}`)
      .on(
        'postgres_changes',
        {
          event: 'INSERT',
          schema: 'public',
          table: 'chat_messages',
          filter: `chat_id=eq.${chatId}`,
        },
        async payload => {
          const newMessage = payload.new;
          // Check if the message already exists in the state
          setMessages(prevMessages => {
            if (prevMessages.some(message => message.id === newMessage.id)) {
              return prevMessages;
            }
            return [...prevMessages, newMessage];
          });

         
          bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
      )
      .subscribe();

    const loadChatEntries = async () => {
      const entries = await fetchChatEntries(chatId);
      setEntries(entries); // Assuming you have a state to store entries
    };

    loadChatEntries();
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });

    // Clean up subscription on unmount
    return () => {
      supabase.removeChannel(channel);
    };
  }, [chatId]);



  const fetchNotificationMessages = async (chatId) => {
    const { data, error } = await supabase
      .from('chat_messages')
      .select('body')
      .eq('chat_id', chatId)
      .in('role', ['assistant_entry_notification', 'beam_chat_support'])
      .order('created_at', { ascending: true });
  
    if (error) {
      console.error('Error fetching messages:', error.message);
      return [];
    } else {
      return data.map(msg => msg.body);
    }
  };

  const fetchEntries = async (entryIds) => {
    const response = await fetch(`${process.env.REACT_APP_MAIN_URL}/get-chat-entries`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({ entry_ids: entryIds }),
    });
  
    if (!response.ok) {
      console.error('Error fetching entries:', response.statusText);
      return [];
    }
  
    const entries = await response.json();
    console.log("Entries:", entries)
    return entries;
  };

  const fetchChatEntries = async (chatId) => {
    // Step 1: Fetch notification messages
    const entryIds = await fetchNotificationMessages(chatId);
  
    if (entryIds.length === 0) {
      console.log('No entries found');
      return [];
    }
  
    // Step 2: Fetch entries based on entry IDs
    const entries = await fetchEntries(entryIds);
    return entries;
  };
  





  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!messageBody.trim()) return;

    setLoading(true);

    const newMessage = {
      chat_id: chatId,
      sent_by: userId,
      role: 'user',
      body: messageBody,
      status: 'sent',
    };

    const { data, error } = await supabase
      .from('chat_messages')
      .insert(newMessage)
      .select();

    if (error) {
      console.error('Error inserting message:', error.message);
    } else {
    //   setMessages((prevMessages) => [...prevMessages, ...data]);
      setMessageBody('');
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }

    setLoading(false);
  };


  const showEntryLoading = (entry_id) => {
    setIsEntryLoading(prevState => ({ ...prevState, [entry_id]: true }));
    if (userId) {
        // setNavigationHistory(prevHistory => [...prevHistory, { id, entry_id }]);
        navigate(`/e/${entry_id}`, { replace: false });
    } else {
        window.location.href = 'https://accounts.meetclique.com/sign-up';
    }
};





    if (!chat) {
        return (
            <div>
            <Nav />
            <div className="flex bg-slate-50 text-slate-700 items-center justify-center w-full p-3 h-[100svh]">
                <div className="text-center">
                <div className="text-center font-medium">{BROWSING_THOUGHTS}</div>
                <div className="flex items-center justify-center mt-2 font-light">
                    <svg
                    aria-hidden="true"
                    className="w-5 h-5 mr-2 text-slate-500 animate-spin dark:text-gray-600 fill-slate-50"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    >
                    <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                    />
                    <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                    />
                    </svg>
                    {HANG}
                </div>
                </div>
            </div>
            </div>
        );
    }
    const formatDateOrTimeAgo = date => {
        // Set moment to use the Russian locale
        moment.locale('ru');
    
        // Get the browser's timezone
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    
        // Convert the date to the browser's timezone
        const zonedDate = moment.utc(date).tz(timeZone);
    
        // Calculate the difference in hours between now and the date
        const now = moment();
        const hoursDifference = now.diff(zonedDate, 'hours');
    
        // If more than 24 hours, show date in the format "D MMMM YYYY [в] HH:mm"
        if (hoursDifference > 24) {
          return zonedDate.format('D MMMM YYYY [в] HH:mm');
        } else {
          // If less than or equal to 24 hours, show relative time
          return zonedDate.fromNow();
        }
      };


    const chatPartner = chat.chat_owner_user_id === userId ? chat.chat_participant_username : chat.chat_owner_username;
    const chatPartnerYou = chat.chat_owner_user_id === userId ? chat.chat_participant_username : "ты";

    const truncateText = (text, maxLength) => {
        if (text.length <= maxLength) {
          return text;
        }
        return text.slice(0, maxLength) + '...';
      };
    return (
        <>
            <Nav />
            <div className="bg-white h-[100svh] pt-24 overflow-auto">

            <div className="flex flex-col items-center px-2">
          <div className="hidden bg-rose-300"></div>

          <div className="w-full max-w-3xl ">

                <Transition
                    show={true}
                    enter="transition-opacity duration-75"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className=" rounded-b-md relative">
                        <div className="sticky pt-5 pb-5 -top-[2.05rem] z-30 bg-white backdrop-blur-sm bg-opacity-40">
                            <div className="flex justify-between items-center">
                            <b className="text-gray-700 text-2xl">Чат с {chatPartner}</b>

                            <button
                                onClick={closeChats}
                                className="bg-slate-200 hover:bg-slate-400 text-slate-600 hover:text-slate-700 font-normal text-base py-1 pl-2 pr-3 rounded-full flex items-center gap-1 focus:outline-none focus:shadow-outline"
                            >
                                <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-5 h-5"
                                >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                                </svg>
                                <span className="text-sm">{CLOSE}</span>
                            </button>
                            </div>
                            
                          
                        </div>

                        <div className='mt-10 '>
                            {chatInvite && (
                                <div className='py-2 mb-10'>
                                    {chat.chat_owner_user_id === userId ? (
                                       <div className='text-sm text-slate-500'>{chatPartnerYou} запросил чат с тобой на этом биме:</div>
                                    ) : (
                                        <div className='text-sm text-slate-500'>Ты отправил(-a) запрос на чат с {chatPartner} на этом биме:</div>
                                    )}
                                    <div
                                        onClick={() => showEntryLoading(chatInvite.invite_from_entry_id)}
                                        className='mt-3 p-2  border border-slate-200 rounded-2xl bg-slate-100 hover:border-slate-500 hover:bg-slate-50 cursor-pointer'>
                                            <div className='flex gap-1 justify-between items-center'>
                                                <div>
                                                    {chatInvite.entry_prompt_title && (
                                                        <div className="text-rose-600 text-sm font-bold mb-1">{chatInvite.entry_prompt_title}</div>
                                                    )}
                                                   <div className="text-slate-700 text-sm">
                                                        {chatInvite.entry_text ? 
                                                            (chatInvite.entry_text.length > 300 ? chatInvite.entry_text.slice(0, 300) + "..." : chatInvite.entry_text)
                                                        : "Показать бим"}
                                                    </div>
                                                </div>
                                                <div className=" text-slate-500">
                                                    <div className="">
                                                    {isEntryLoading[chatInvite.invite_from_entry_id] ? (
                                                        <svg
                                                            id={chatInvite.invite_from_entry_id}
                                                            aria-hidden="true"
                                                            className="w-4 h-4  text-rose-500 animate-spin dark:text-gray-600 fill-slate-50"
                                                            viewBox="0 0 100 101"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                            fill="currentColor"
                                                            />
                                                            <path
                                                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                            fill="currentFill"
                                                            />
                                                        </svg>
                                                        ) : (
                                                        <svg
                                                            id={chatInvite.invite_from_entry_id}
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth={1.5}
                                                            stroke="currentColor"
                                                            className="w-4 h-4"
                                                        >
                                                            <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                                                            />
                                                        </svg>
                                                        )}
                                                    </div>
                                                </div>

                                            </div>
                                        
                                    </div>
                                    
                                    <div className="h-px flex bg-gradient-to-l from-white via-slate-300 to-white rounded-full  mt-8"></div>

                                </div>
                               
                            )}
                            <div>

        
        <div className="messages-list pb-32">
            {messages.map((msg) => {
                switch (msg.role) {
                    case 'assistant_entry_notification':
                        if (entries.length > 0) {
                            const entry = entries.find(entry => entry.entry_id === msg.body);
                            if (entry) {
                                const truncatedText = truncateText(entry.text, 300);
                                return (
                                    <div key={entry.id}>
                                        {msg.sent_by === userId ? (
                                            <div className='text-sm text-slate-500 text-right mr-2 mb-1'>
                                                Ты хочешь поговорить об этом биме:
                                            </div>
                                        ) : (
                                            <div className='text-sm text-slate-500 ml-2 mb-1'>
                                                {chatPartner} хочет поговорить об этом биме:
                                            </div>
                                        )}
                                        <div
                                            onClick={() => showEntryLoading(entry.entry_id)}
                                            className={` ${msg.sent_by === userId ? 'ml-10 md:ml-24' : 'mr-10 md:mr-24'} mb-4 bg-white border-2 shadow-lg border-slate-200 rounded-3xl hover:bg-slate-100 cursor-pointer relative`}
                                        >
                                            <div className="px-4 py-4">
                                                <div className="flex justify-between"></div>
                                                <div className="flex justify-between">
                                                    {entry.entry_prompt_title && (
                                                        <div className="text-rose-600 text-base font-bold mb-3">
                                                            {entry.entry_prompt_title}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="flex gap-3 justify-between">
                                                    <div className="text-slate-700">
                                                        {truncatedText.split('\n').map((line, index) => (
                                                            <React.Fragment key={index}>
                                                                {line}
                                                                {index < truncatedText.split('\n').length - 1 && <br />}
                                                            </React.Fragment>
                                                        ))}
                                                    </div>
                                                    <div className="text-slate-500">
                                                        <div className="">
                                                            {isEntryLoading[entry.entry_id] ? (
                                                                <svg
                                                                    id={entry.entry_id}
                                                                    aria-hidden="true"
                                                                    className="w-4 h-4 text-rose-500 animate-spin dark:text-gray-600 fill-slate-50"
                                                                    viewBox="0 0 100 101"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                                        fill="currentColor"
                                                                    />
                                                                    <path
                                                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                                        fill="currentFill"
                                                                    />
                                                                </svg>
                                                            ) : (
                                                                <svg
                                                                    id={entry.entry_id}
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    fill="none"
                                                                    viewBox="0 0 24 24"
                                                                    strokeWidth={1.5}
                                                                    stroke="currentColor"
                                                                    className="w-4 h-4"
                                                                >
                                                                    <path
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                                                                    />
                                                                </svg>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="text-sm flex mt-2 text-slate-500">
                                                    {formatDateOrTimeAgo(entry.entry_datetime)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                        }
                        break;

                    case 'beam_chat_support':
                        if (entries.length > 0) {
                            const entry = entries.find(entry => entry.entry_id === msg.body);
                            if (entry) {
                                const truncatedText = truncateText(entry.text, 300);
                                return (
                                    <div key={entry.id}>
                                        {msg.sent_by === userId ? (
                                            <div className='flex justify-end items-center'>
                                                <div className='text-sm text-white rounded-xl font-medium p-2 text-right bg-gradient-to-tr from-[#cb2d3e] to-[#ef473a] mr-2 mb-1 flex justify-end items-center gap-2'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-heart-handshake h-6 w-6" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                        <path d="M19.5 12.572l-7.5 7.428l-7.5 -7.428a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572" />
                                                        <path d="M12 6l-3.293 3.293a1 1 0 0 0 0 1.414l.543 .543c.69 .69 1.81 .69 2.5 0l1 -1a3.182 3.182 0 0 1 4.5 0l2.25 2.25" />
                                                        <path d="M12.5 15.5l2 2" />
                                                        <path d="M15 13l2 2" />
                                                    </svg>
                                                    Ты выразил(-а) поддержку в этом биме {chatPartner}:
                                                </div>
                                            </div>
                                        ) : (
                                            <div className='flex justify-start items-center'>
                                                <div className='text-sm text-slate-600 rounded-xl font-medium p-2 text-right bg-gradient-to-bl from-slate-200 to-slate-300 ml-2 mb-1 flex justify-start items-center gap-2'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-heart-handshake h-6 w-6" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                        <path d="M19.5 12.572l-7.5 7.428l-7.5 -7.428a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572" />
                                                        <path d="M12 6l-3.293 3.293a1 1 0 0 0 0 1.414l.543 .543c.69 .69 1.81 .69 2.5 0l1 -1a3.182 3.182 0 0 1 4.5 0l2.25 2.25" />
                                                        <path d="M12.5 15.5l2 2" />
                                                        <path d="M15 13l2 2" />
                                                    </svg>{chatPartner} шлет лучи понимания по этому биму:
                                                </div>
                                            </div>
                                        )}
                                        <div
                                            onClick={() => showEntryLoading(entry.entry_id)}
                                            className={` ${msg.sent_by === userId ? 'ml-10 md:ml-24' : 'mr-10 md:mr-24'} mb-4 bg-white border-2 shadow-lg border-slate-200 rounded-3xl hover:bg-slate-100 cursor-pointer relative`}
                                        >
                                            <div className="px-4 py-4">
                                                <div className="flex justify-between">
                                                    {entry.entry_prompt_title && (
                                                        <div className="text-rose-600 text-base font-bold mb-3">
                                                            {entry.entry_prompt_title}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="flex gap-3 justify-between">
                                                    <div className="text-slate-700">
                                                        {truncatedText.split('\n').map((line, index) => (
                                                            <React.Fragment key={index}>
                                                                {line}
                                                                {index < truncatedText.split('\n').length - 1 && <br />}
                                                            </React.Fragment>
                                                        ))}
                                                    </div>
                                                    <div className="text-slate-500">
                                                        <div className="">
                                                            {isEntryLoading[entry.entry_id] ? (
                                                                <svg
                                                                    id={entry.entry_id}
                                                                    aria-hidden="true"
                                                                    className="w-4 h-4 text-rose-500 animate-spin dark:text-gray-600 fill-slate-50"
                                                                    viewBox="0 0 100 101"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                                        fill="currentColor"
                                                                    />
                                                                    <path
                                                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                                        fill="currentFill"
                                                                    />
                                                                </svg>
                                                            ) : (
                                                                <svg
                                                                    id={entry.entry_id}
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    fill="none"
                                                                    viewBox="0 0 24 24"
                                                                    strokeWidth={1.5}
                                                                    stroke="currentColor"
                                                                    className="w-4 h-4"
                                                                >
                                                                    <path
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                                                                    />
                                                                </svg>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="text-sm flex mt-2 text-slate-500">
                                                    {formatDateOrTimeAgo(entry.entry_datetime)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                        }
                        break;

                    default:
                        return (
                            <div key={msg.id} className={` ${msg.sent_by === userId ? 'text-right' : 'text-left'} pb-4 flex flex-col`}>
                                {msg.sent_by === userId ? (
                                    <div className='flex justify-end flex-col items-end'>
                                        <div className='text-sm text-slate-600 mr-2'>Ты</div>
                                        <div className='text-sm text-white rounded-xl font-medium p-2 text-right bg-gradient-to-tr from-[#cb2d3e] to-[#ef473a] mr-1 mb-1 '>
                                            {msg.body}
                                        </div>
                                    </div>
                                ) : (
                                    <div className='flex  flex-col justify-start items-start'>
                                        <div className='text-sm text-slate-600 ml-2'>{msg.sent_by === userId ? 'ты' : chatPartner}</div>
                                        <div className='text-sm text-slate-600 rounded-xl font-medium p-2 text-left bg-gradient-to-bl from-slate-200 to-slate-300 ml-1 mb-1 flex justify-start items-center gap-2'>
                                            {msg.body}
                                        </div>
                                    </div>
                                )}
                            </div>
                        );
                }
            })}
            <div ref={bottomRef} />
        </div>





                        
                                <form onSubmit={handleSubmit} className="message-form mb-5 fixed bottom-0 inset-x-0 flex gap-2 w-full max-w-3xl  mx-auto px-3">
                                    <input
                                    type="text"
                                    value={messageBody}
                                    onChange={(e) => setMessageBody(e.target.value)}
                                    placeholder="Сообщение"
                                    required
                                    className='p-2 bg-slate-50 border border-slate-500 rounded-lg shadow flex flex-grow'
                                    />
                                    <button type="submit" disabled={loading} className=' bg-gradient-to-tr from-[#cb2d3e] to-[#ef473a] hover:to-[#cb2d3e] text-white hover:text-white font-semibold text-base py-2 px-3 rounded-full flex items-center gap-1 focus:outline-none focus:shadow-outline'>
                                    {loading ? 'Отправка...' : 'Отправить'}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </Transition>
                </div>
                </div>
            </div>
        </>
    )
}

export default Chat;

import React, { useState, useRef, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  formatDistanceToNow,
  formatDistanceToNowStrict,
  format,
  differenceInHours,
} from 'date-fns';
import { useAuth, useUser } from '@clerk/clerk-react';
import { Transition } from '@headlessui/react';
import moment from 'moment-timezone';
import 'moment/locale/ru'; // Import Russian locale

import StreakBar from './components/StreakBar';
import Nav from './components/nav';
import PreviewBeam from './components/PreviewBeam';
// import EntrySubmit from './components/input';

const Entries = () => {
  const { user_id } = useParams(); // Assuming the path is /page/:id
  const [response, setResponse] = useState(null);
  const { userId, sessionId, getToken } = useAuth();
  const { isLoaded, isSignedIn, user } = useUser();
  const [isResponseGenerated, setIsResponseGenerated] = useState(false);
  const [playingState, setPlayingState] = useState({});
  const { entryId } = useParams(); // Assuming your URL parameter is named entryId
  const navigate = useNavigate(); // Initialize useNavigate hook
  const entrySubmitRef = useRef(); // Add this line
  const [isEntryLoading, setIsEntryLoading] = useState(false, {});
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedWeekday, setSelectedWeekday] = useState('');

  const YOUR_BEAMS = 'Твои бимы'; //Your beams
  const BROWSING_THOUGHTS = 'Изучаю коллективное сознание...'; //Browsing through collective consciousness...
  const HANG = 'Подожди немного'; // Hang on a little fren
  const CLOSE = 'Закрыть'; // Close
  const NO_BEAMS = 'Сегодня ты еще не создал ни одного бима.'; //No beams here, yet

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  console.log('User tiemzone: ', timeZone);
  moment.locale('ru');

  useEffect(() => {
    if (userId) {
      if (userId === user_id) {
        const fetchResponse = async () => {
          try {
            console.log(user_id);
            const response = await fetch(process.env.REACT_APP_MAIN_URL + '/ye/' + user_id);
            if (response.ok) {
              const data = await response.json();
              console.log(data);
              setResponse(data);
            } else {
              console.error('Server error:', response.status);
            }
          } catch (error) {
            console.error('Network error:', error);
          }
        };
        fetchResponse();
      } else {
        alert(
          "You can access only your beams. If you want to explore what others are beaming, write more beams on topics you're interested in. You'll now be redirected to the Home page."
        );
        navigate('/');
      }
    }
  }, [userId]);

  useEffect(() => {
    // Reset the loading state here
    setIsEntryLoading(false, {});
    if (response && response.userEntries) {
      const today = format(new Date(), 'yyyy-MM-dd');
      const entriesToday = response.userEntries.some(
        entry => format(new Date(entry.metadata.entry_datetime), 'yyyy-MM-dd') === today
      );

      if (entriesToday) {
        setSelectedDate(today);
        setSelectedWeekday(
          new Intl.DateTimeFormat('ru-RU', { weekday: 'long' }).format(new Date(today))
        );
      } else {
        setSelectedWeekday(
          new Intl.DateTimeFormat('ru-RU', { weekday: 'long' }).format(new Date(today))
        );
      }
    }
  }, [response]); // Add dependencies as necessary

  useEffect(() => {
    if (response && response.userEntries) {
      response.userEntries.forEach(match => {
        const audioPlayer = document.getElementById(`player-${match.id}`);
        if (audioPlayer) {
          const handleAudioEnd = () =>
            setPlayingState(prevState => ({ ...prevState, [match.id]: false }));
          audioPlayer.addEventListener('ended', handleAudioEnd);

          // Cleanup
          return () => {
            audioPlayer.removeEventListener('ended', handleAudioEnd);
          };
        }
      });
    }
  }, [response]);

  if (!response) {
    return (
      <div>
        <Nav />
        <div className="flex bg-slate-50 text-slate-700 items-center justify-center w-full p-3 h-[100svh]">
          <div className="text-center">
            <div className="text-center font-medium">{BROWSING_THOUGHTS}</div>
            <div className="flex items-center justify-center mt-2 font-light">
              <svg
                aria-hidden="true"
                className="w-5 h-5 mr-2 text-slate-500 animate-spin dark:text-gray-600 fill-slate-50"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              {HANG}
            </div>
          </div>
        </div>
      </div>
    );
  }

  const handleResponse = responseData => {
    // Process the response data here
    // For example, setting it to the state
    console.log(responseData);
    if (responseData && responseData.responseId) {
      navigate(`/e/${responseData.responseId}`, { replace: true });
    }
    setResponse(responseData);
  };

  const closeResponses = () => {
    updateIsResponseLoadingInChild(false);
    setResponse('');
    navigate(`/`, { replace: true });
  };

  const deleteEntry = async (entryId, isPrivate) => {
    console.log(isPrivate);
    if (
      window.confirm(
        "You're about to delete your entry. This action can't be undone. Are you sure?"
      )
    ) {
      try {
        const response = await fetch(`${process.env.REACT_APP_MAIN_URL}/delete-entry`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            // Include other headers as required, e.g., authorization tokens
          },
          body: JSON.stringify({ userId, entryId, isPrivate }),
        });

        if (response.ok) {
          // Remove the entry from the state
          setResponse(prevResponse => ({
            ...prevResponse,
            userEntries: prevResponse.userEntries.filter(entry => entry.id !== entryId),
          }));
        } else {
          console.error('Server error:', response.status);
        }
      } catch (error) {
        console.error('Network error:', error);
      }
    }
  };

  const updateIsResponseLoadingInChild = loadingState => {
    if (entrySubmitRef.current) {
      entrySubmitRef.current.updateIsResponseLoading(loadingState);
    }
  };

  const handleGeneration = status => {
    setIsResponseGenerated(status);
  };

  const playAudio = id => {
    const audioPlayer = document.getElementById(`player-${id}`);
    if (audioPlayer) {
      audioPlayer.play();
      setPlayingState(prevState => ({ ...prevState, [id]: true }));
    }
  };

  const pauseAudio = id => {
    const audioPlayer = document.getElementById(`player-${id}`);
    if (audioPlayer) {
      audioPlayer.pause();
      setPlayingState(prevState => ({ ...prevState, [id]: false }));
    }
  };

  const showEntryLoading = (id, entry_id, is_private) => {
    setIsEntryLoading(prevState => ({ ...prevState, [id]: true }));
    if (is_private == 'True') {
      navigate(`/pe/${entry_id}`, { replace: true });
    } else {
      navigate(`/e/${entry_id}`, { replace: true });
    }
  };

  const connectWithUser = (username, email) => {
    alert(
      `1-1 chats are going live very soon. In the meantime send the email to ${username}: ${email} `
    ); // Or handle this error as appropriate for your application
  };

  const formatDateOrTimeAgo = date => {
    const now = new Date();
    const hoursDifference = differenceInHours(now, date);

    if (hoursDifference > 24) {
      // If more than 24 hours, show date in the format "MMM dd, yyyy"
      return format(date, "MMM. dd, yy 'в' h:mm a");
    } else {
      // If less than or equal to 24 hours, show relative time
      return format(date, "MMM. dd, yy 'в' h:mm a");

      // return formatDistanceToNowStrict(date, { addSuffix: false });
    }
  };

  // Group entries by date
  const entriesByDate = response.userEntries
    ? response.userEntries.reduce((acc, entry) => {
        const entryDate = format(new Date(entry.metadata.entry_datetime), 'yyyy-MM-dd');
        if (!acc[entryDate]) {
          acc[entryDate] = [];
        }
        acc[entryDate].push(entry);
        return acc;
      }, {})
    : {};

  // Get a sorted list of dates
  const sortedDates = Object.keys(entriesByDate).sort((a, b) => new Date(b) - new Date(a));

  // Function to scroll and highlight
  const scrollToAndHighlight = targetId => {
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      targetElement.classList.add('bg-rose-300');

      // Remove the highlight after a delay
      setTimeout(() => {
        targetElement.classList.remove('bg-rose-300');
      }, 1500);
    } else {
      navigate(`/e/${targetId}`, { replace: true });
    }
  };
  const repliesMap = {};
  if (response.userEntries) {
    response.userEntries.forEach(entry => {
      const parentBeamId = entry.metadata.original_beam_id;
      if (parentBeamId) {
        if (!repliesMap[parentBeamId]) {
          repliesMap[parentBeamId] = [];
        }
        repliesMap[parentBeamId].push(entry);
      }
    });
  }

  const handleDateClick = date => {
    setSelectedDate(date);
    setSelectedWeekday(
      new Intl.DateTimeFormat('ru-RU', { weekday: 'long' }).format(new Date(date))
    );
  };

  return (
    <>
      <Nav />
      <div className="bg-white h-[100svh] pt-24 overflow-auto">
        <div className="flex flex-col items-center px-2">
          <div className="hidden bg-rose-300"></div>

          <div className="w-full max-w-3xl ">
            {/*<EntrySubmit */}
            {/*  onReceiveResponse={handleResponse}*/}
            {/*  isGeneratingResponse={handleGeneration} */}
            {/*/>*/}
            {response.userEntries && (
              <Transition
                show={!isResponseGenerated}
                enter="transition-opacity duration-75"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className=" rounded-b-md relative">
                  <div className="sticky">
                    <div className="flex justify-between items-center">
                      <b className="text-gray-700 text-2xl">{YOUR_BEAMS}</b>
                      <button
                        onClick={closeResponses}
                        className="bg-slate-200 hover:bg-slate-400 text-slate-600 hover:text-slate-700 font-normal text-base py-1 pl-2 pr-3 rounded-full flex items-center gap-1 focus:outline-none focus:shadow-outline"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-5 h-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                        <span className="text-sm">{CLOSE}</span>
                      </button>
                    </div>
                    {/* Horizontal Scroll for Dates */}
                    <StreakBar entries={response.userEntries} />

                    <div className="relative mb-8">
                      <div className="overflow-x-auto whitespace-nowrap py-4">
                        {sortedDates.map(date => {
                          // Convert the date to the user's local timezone
                          const localDate = moment.tz(date, timeZone).toDate();
                          const isToday =
                            format(localDate, 'yyyy-MM-dd') === format(new Date(), 'yyyy-MM-dd');

                          return (
                            <button
                              key={date}
                              onClick={() => handleDateClick(date)}
                              className={`inline-block px-4 py-2 m-2 text-sm rounded-xl ${date === selectedDate ? 'bg-rose-600 text-white' : 'bg-slate-200 text-slate-700'}`}
                            >
                              <div className="flex flex-col items-center">
                                {isToday ? (
                                  <>
                                    <span className="text-xl font-semibold">Сегодня</span>
                                    <span className="">
                                      {new Intl.DateTimeFormat('ru-RU', { day: 'numeric' }).format(
                                        localDate
                                      )}{' '}
                                      {new Intl.DateTimeFormat('ru-RU', { month: 'short' }).format(
                                        localDate
                                      )}
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <span className="text-xl font-semibold">
                                      {new Intl.DateTimeFormat('ru-RU', { day: 'numeric' }).format(
                                        localDate
                                      )}
                                    </span>
                                    <span>
                                      {new Intl.DateTimeFormat('ru-RU', { month: 'short' }).format(
                                        localDate
                                      )}
                                    </span>
                                  </>
                                )}
                              </div>
                            </button>
                          );
                        })}
                      </div>
                      <div className="w-10 absolute top-0 bottom-0 right-0 bg-gradient-to-l from-white to-white/0 mt-3 mb-3 pr-1"></div>
                    </div>
                  </div>
                  <div className="ml-4 mb-4 text-lg text-slate-700 font-semibold">
                    {selectedWeekday.charAt(0).toUpperCase() + selectedWeekday.slice(1)}
                  </div>
                  <div className="mt-4">
                    {!entriesByDate[selectedDate] || entriesByDate[selectedDate].length === 0 ? (
                      <div className="py-2 flex items-center gap-2 text-sm text-slate-500">
                        <div className="flex flex-col gap-1 px-4">
                          <div>{NO_BEAMS}</div>
                        </div>
                      </div>
                    ) : (
                      entriesByDate[selectedDate] &&
                      entriesByDate[selectedDate]
                        .sort(
                          (a, b) =>
                            new Date(a.metadata.entry_datetime) -
                            new Date(b.metadata.entry_datetime)
                        ) // Sorting in descending order
                        .map(match => (
                          <PreviewBeam
                            key={match.id}
                            currentId={match.id}
                            metadata={match.metadata}
                            onScrollToAndHighlight={scrollToAndHighlight}
                            onPlayAudio={playAudio}
                            onPauseAudio={pauseAudio}
                            onShowEntryLoading={showEntryLoading}
                            onDeleteEntry={deleteEntry}
                            playingState={playingState}
                            isEntryLoading={isEntryLoading}
                            repliesMap={repliesMap}
                          />
                        ))
                    )}
                  </div>
                </div>
              </Transition>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Entries;

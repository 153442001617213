import React from 'react';
import { SignedIn, SignedOut } from '@clerk/clerk-react';

import { RecorderWidgetProvider } from '../components/RecorderWidget/RecorderWidgetContext';
import { ModalProvider } from '../components/Modal/ModalContext';
import Modal from '../components/Modal/Modal';
import Header from '../components/Header/Header';
import {Typography} from "../ui/Typography";

const HEADLINE = 'Рефлексируй и находи людей, кто думает о том же';
const SUB_HEAD = 'Выражай свои чувства, делись проблемами и взглядами, чтобы найти тех, кто находится на одной волне с тобой. Clique AI поможет тебе рефлексировать и исследовать общие мысли в сообществе, где твой голос услышат только те, кто тебя понимает.';

function BaseLayout({ children }) {
  return (
    <RecorderWidgetProvider>
      <ModalProvider>
        <div className="base-layout relative min-h-screen bg-[#F2F2F2]">
          <Header />
          <div className="max-w-screen-sm mt-16 my-0 mx-auto px-3 sm:px-0">
            <SignedOut>
              <div className="mx-auto lg:mx-0 text-center lg:flex-auto flex flex-col items-center justify-center">
                <Typography component="h1" className="mb-6">
                  {HEADLINE}
                </Typography>
                <Typography component="b">
                  {SUB_HEAD}
                </Typography>
              </div>
            </SignedOut>
            <SignedIn>
              {children}
            </SignedIn>
          </div>
        </div>
        <Modal />
      </ModalProvider>
    </RecorderWidgetProvider>
  );
}

export default BaseLayout;

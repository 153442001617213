import React from 'react';

export function Typography({ className, component, variant, children }) {
  let Component;
  let classNames;

  switch (component) {
    case 'h1': {
      Component = 'h1';
      classNames = 'text-3xl';

      break;
    }
    case 'h2': {
      Component = 'h2';
      classNames = 'text-2xl';
      break;
    }
    case 'h3': {
      Component = 'h3';
      classNames = 'text-xl';
      break;
    }
    case 'b': {
      Component = 'p';
      classNames = 'text-base';
      break;
    }
    case 'bb': {
      Component = 'p';
      classNames = 'text-sm';
      break;
    }
    default: {
      Component = 'p';
      classNames = 'text-base';
      break;
    }
  }

  switch (variant) {
    case 'dark': {
      classNames += ' text-[#292626]';

      break;
    }
    case 'light': {
      classNames += ' text-white';

      break;
    }
    default: {
      classNames += ' text-[#292626]';
      break;
    }
  }

  return <Component className={`${className ? className : ''} typography ${classNames}`}>{children}</Component>;
}

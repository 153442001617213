import React, { useState, useEffect, useRef } from 'react';
import { SignedIn, SignedOut, SignUpButton } from '@clerk/clerk-react';
import { formatDistanceToNow } from 'date-fns';
import Nav from './components/nav';
import EntrySubmit from './components/input';
import { Link, useNavigate } from 'react-router-dom';
import { Transition } from '@headlessui/react';
import { Helmet } from 'react-helmet';

import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { useAudioPlayer } from './components/AudioPlayer/AudioPlayerContext';

const MyForm = () => {
  const { isPlaying, play, pause, currentUrl } = useAudioPlayer();
  const [response, setResponse] = useState(null);
  const [placeholderQuestion, setPlaceholderQuestion] = useState('');
  // const [updatePrompt, setUpdatePrompt] = useState(1)
  const [isResponseGenerated, setIsResponseGenerated] = useState(false);
  const entrySubmitRef = useRef(); // Add this line
  const [isEntryLoading, setIsEntryLoading] = useState(false, {});
  const navigate = useNavigate(); // Initialize useNavigate hook
  const HEADLINE = 'Рефлексируй и находи людей, кто думает о том же'; //AI-powered journal that connects you with like-minded people through your thoughts
  const SUB_HEAD =
    'Выражай свои чувства, делись проблемами и взглядами, чтобы найти тех, кто находится на одной волне с тобой. Clique AI поможет тебе рефлексировать и исследовать общие мысли в сообществе, где твой голос услышат только те, кто тебя понимает.'; //                     Express your feelings, share challenges, and voice ideas to discover only individuals who resonate with them. Clique AI will help you reflect and explore shared thoughts in a community where <span className='font-bold'>your voice is heard by only the ones who understand.</span>
  const RECORD_FIRST_BEAM = 'Создай первый бим'; //Record your first beam

  const FAQ = 'Эф Эй Кью'; //FAQ
  const SUB_FAQ = 'Все ответы есть у тебя внутри. Клик поможет задать правильные вопросы.'; //Reflect. Connect. Accelerate.

  const redirectToSignUp = () => {
    window.location.href = 'https://smiling-dinosaur-45.accounts.dev/sign-up';
  };

  // useEffect(() => {
  //   fetch(process.env.REACT_APP_MAIN_URL + '/random-question')
  //     .then(response => response.json())
  //     .then(data => {
  //       setPlaceholderQuestion(data.question.question)

  //     })
  //     .catch(error => console.error('Error fetching question:', error));
  // }, [updatePrompt]);

  const handleResponse = responseData => {
    console.log('response data:', responseData);
    setResponse(responseData);
  };
  const handleGeneration = status => {
    setIsResponseGenerated(status);
  };

  const updateIsResponseLoadingInChild = loadingState => {
    // Call the function in EntrySubmit with the new state
    if (entrySubmitRef.current) {
      entrySubmitRef.current.updateIsResponseLoading(loadingState);
    }
  };

  const closeResponses = () => {
    updateIsResponseLoadingInChild(false);
    setResponse('');
  };

  const adjustHeight = element => {
    element.style.height = 'inherit';
    element.style.height = `${element.scrollHeight + 2}px`;
  };

  const showEntryLoading = (id, entry_id) => {
    setIsEntryLoading(prevState => ({ ...prevState, [id]: true }));
    navigate(`/e/${entry_id}`, { replace: true });
  };

  const handleTogglePlayer = (e, audioUrl) => {
    e.stopPropagation();
    isPlaying && currentUrl === audioUrl ? pause() : play(audioUrl);
  };

  return (
    <>
      <Helmet>
        <title>Clique - Дневник рефлексии</title>
        <meta name="description" content="Добро пожаловать в Клик." />

        {/* Open Graph Metadata */}
        <meta property="og:title" content="Clique - Дневник рефлексии" />
        <meta property="og:description" content="Clique - Дневник рефлексии" />
        <meta property="og:url" content="https://meetclique.com/" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://rnjyfufrpnxocfzxmeih.supabase.co/storage/v1/object/public/bot_assets/clique_logo.svg"
        />

        {/* Twitter Metadata */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Clique - Дневник рефлексии" />
        <meta name="twitter:description" content="Clique - Дневник рефлексии" />
        <meta
          name="twitter:image"
          content="https://rnjyfufrpnxocfzxmeih.supabase.co/storage/v1/object/public/bot_assets/clique_logo.svg"
        />

        {/* Favicon and Icons */}
        <link
          rel="icon"
          type="image/x-icon"
          href="https://rnjyfufrpnxocfzxmeih.supabase.co/storage/v1/object/public/bot_assets/favicon.ico"
        />
        <link
          rel="icon"
          type="image/x-icon"
          href="https://rnjyfufrpnxocfzxmeih.supabase.co/storage/v1/object/public/bot_assets/favicon.ico"
          sizes="32x32"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="https://rnjyfufrpnxocfzxmeih.supabase.co/storage/v1/object/public/bot_assets/favicon.png"
        />

        {/* Manifest */}
        {/* <link rel="manifest" href="/site.webmanifest" /> */}

        {/* Preconnect */}
        <link rel="preconnect" href="https://rsms.me/" />

        {/* Stylesheet */}
        <link rel="stylesheet" href="https://rsms.me/inter/inter.css" />

        {/* Scripts */}
        <script src="https://cdnjs.cloudflare.com/ajax/libs/lamejs/1.2.0/lame.min.js" />
        <script
          defer
          data-domain="meetclique.com"
          src="https://plausible.io/js/script.tagged-events.js"
        />

        {/* Theme Color */}
        <meta name="theme-color" content="#ef473a" />
      </Helmet>

      <div className="bg-slate-50/0 h-[100svh]  overflow-auto relative">
        <Nav />
        {/*<div className="absolute inset-0 h-full w-full bg-[radial-gradient(#00000030_1px,transparent_1px)] lab-bg [background-size:20px_16px]">*/}
        {/*</div>*/}

        {/*<div className="fixed z-0 bottom-0 h-1/2 w-full overflow-hidden [perspective:150px]">*/}
        {/*  <div className="fixed inset-0 [transform:rotateX(45deg)]">*/}
        {/*    <div className="animate-grid [background-repeat:repeat] [background-size:60px_60px] [height:100svh] [inset:0%_0px] [margin-left:-50%] [transform-origin:100%_0_0] [width:200vw] [background-image:linear-gradient(to_right,rgba(0,0,0,0.2)_1px,transparent_0),linear-gradient(to_bottom,rgba(0,0,0,0.1)_1px,transparent_0)] dark:[background-image:linear-gradient(to_right,rgba(255,255,255,0.2)_1px,transparent_0),linear-gradient(to_bottom,rgba(255,255,255,0.2)_1px,transparent_0)]">*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <div className="fixed z-0 inset-0 bg-gradient-to-b from-slate-50 to-transparent to-100% dark:from-black">*/}
        {/*  </div>*/}
        {/*</div>*/}

        <SignedOut>
          <div className="absolute  inset-0 z-10 flex flex-col h-[100svh] items-center px-3">
            <div className="w-full h-[100svh] max-w-[50rem]">
              <div className="relative isolate overflow-hidden pt-32 md:pt-60 px-3 sm:rounded-xl   lg:flex lg:px-10 ">
                <div className="mx-auto lg:mx-0 text-center lg:flex-auto flex  flex-col items-center justify-center">
                  <h2 className="text-3xl font-bold tracking-tight text-slate-700 md:text-4xl md:leading-[3rem]">
                    {HEADLINE}
                  </h2>
                  <p className="mt-6 text-slate-600 text-sm md:text-base md:leading-7 antialiased pb-32">
                    {SUB_HEAD}
                    <br />
                    <Link
                      to="https://docs.google.com/forms/d/e/1FAIpQLSeWUeHlV0A5Vfot47d_02fGCQuCGmEFiP65GUDaBsW5Gpag7g/viewform?usp=sf_link"
                      className="plausible-event-name=Signup bg-gradient-to-tr from-[#cb2d3e] to-[#ef473a]  hover:to-[#cb2d3e] text-slate-50 font-bold py-3 px-5 mt-4 rounded-2xl inline-flex items-center cursor-pointer"
                      style={{ pointerEvents: 'auto' }}
                    >
                      <span className="flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-flare w-4 h-4 mr-2"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M12 3l3 6l6 3l-6 3l-3 6l-3 -6l-6 -3l6 -3z" />
                        </svg>
                        {RECORD_FIRST_BEAM}
                      </span>
                    </Link>
                  </p>
                  {/*<div className='font-semibold'>{FAQ}</div>*/}
                  {/*<div className='text-sm font-medium text-rose-500 mb-5'>*/}
                  {/*  {SUB_FAQ}*/}
                  {/*</div>*/}
                  {/*<div className="w-full pb-14">*/}
                  {/*      <div className="mx-auto w-full max-w-2xl rounded-2xl">*/}
                  {/*        <Disclosure as="div" className="mt-2">*/}
                  {/*          {({ open }) => (*/}
                  {/*            <>*/}
                  {/*              <Disclosure.Button className="flex w-full justify-between rounded-lg bg-slate-100 px-4 py-2 text-left text-sm font-medium text-rose-600 hover:bg-rose-200 focus:outline-none focus-visible:ring focus-visible:ring-rose-500/75">*/}
                  {/*                <span>How can Clique AI help me reflect?</span>*/}
                  {/*                <ChevronDownIcon*/}
                  {/*                  className={`${*/}
                  {/*                    open ? 'rotate-180 transform' : ''*/}
                  {/*                  } h-5 w-5 text-rose-500`}*/}
                  {/*                />*/}
                  {/*              </Disclosure.Button>*/}
                  {/*              <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-slate-500">*/}
                  {/*                You can start by using one of many reflection prompts available in 1 click. As you express yourself more, Clique will start to understand you and will ask you questions tailored to your thoughts.*/}
                  {/*              </Disclosure.Panel>*/}
                  {/*            </>*/}
                  {/*          )}*/}
                  {/*        </Disclosure>*/}
                  {/*        <Disclosure as="div" className="mt-2">*/}
                  {/*          {({ open }) => (*/}
                  {/*            <>*/}
                  {/*              <Disclosure.Button className="flex w-full justify-between rounded-lg bg-slate-100 px-4 py-2 text-left text-sm font-medium text-rose-600 hover:bg-rose-200 focus:outline-none focus-visible:ring focus-visible:ring-rose-500/75">*/}
                  {/*                <span>What is a "Beam"?</span>*/}
                  {/*                <ChevronDownIcon*/}
                  {/*                  className={`${*/}
                  {/*                    open ? 'rotate-180 transform' : ''*/}
                  {/*                  } h-5 w-5 text-rose-500`}*/}
                  {/*                />*/}
                  {/*              </Disclosure.Button>*/}
                  {/*              <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-slate-500">*/}
                  {/*                Beam is like a tweet, an entry, or a post. Beam (as a ray of light) is a text completed with your voice to transport your expression most fully into the collective consciousness. On X you post, on Clique you beam.*/}
                  {/*              </Disclosure.Panel>*/}
                  {/*            </>*/}
                  {/*          )}*/}
                  {/*        </Disclosure>*/}
                  {/*        <Disclosure as="div" className="mt-2">*/}
                  {/*          {({ open }) => (*/}
                  {/*            <>*/}
                  {/*              <Disclosure.Button className="flex w-full justify-between rounded-lg bg-slate-100 px-4 py-2 text-left text-sm font-medium text-rose-600 hover:bg-rose-200 focus:outline-none focus-visible:ring focus-visible:ring-rose-500/75">*/}
                  {/*                <span>Can I use my voice?</span>*/}
                  {/*                <ChevronDownIcon*/}
                  {/*                  className={`${*/}
                  {/*                    open ? 'rotate-180 transform' : ''*/}
                  {/*                  } h-5 w-5 text-rose-500`}*/}
                  {/*                />*/}
                  {/*              </Disclosure.Button>*/}
                  {/*              <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-slate-500">*/}
                  {/*                Yes. Our voice is the best medium to express our thoughts. On Clique, you can create a beam with your voice, and your voice recording will be saved as well.*/}
                  {/*              </Disclosure.Panel>*/}
                  {/*            </>*/}
                  {/*          )}*/}
                  {/*        </Disclosure>*/}
                  {/*        <Disclosure as="div" className="mt-2">*/}
                  {/*          {({ open }) => (*/}
                  {/*            <>*/}
                  {/*              <Disclosure.Button className="flex w-full justify-between rounded-lg bg-slate-100 px-4 py-2 text-left text-sm font-medium text-rose-600 hover:bg-rose-200 focus:outline-none focus-visible:ring focus-visible:ring-rose-500/75">*/}
                  {/*                <span>What about privacy?</span>*/}
                  {/*                <ChevronDownIcon*/}
                  {/*                  className={`${*/}
                  {/*                    open ? 'rotate-180 transform' : ''*/}
                  {/*                  } h-5 w-5 text-rose-500`}*/}
                  {/*                />*/}
                  {/*              </Disclosure.Button>*/}
                  {/*              <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm  text-slate-500">*/}
                  {/*                Your entries are never used for model training purposes. Only the people who are having related thoughts will be able to see your entry, the related one, but not all of your entries.*/}
                  {/*                <br/>*/}
                  {/*                We don't ask for your real name or any other personal information, only username and email. If you want 100% privacy you can use an obscure email.*/}
                  {/*              </Disclosure.Panel>*/}
                  {/*            </>*/}
                  {/*          )}*/}
                  {/*        </Disclosure>*/}
                  {/*        <Disclosure as="div" className="mt-2">*/}
                  {/*          {({ open }) => (*/}
                  {/*            <>*/}
                  {/*              <Disclosure.Button className="flex w-full justify-between rounded-lg bg-slate-100 px-4 py-2 text-left text-sm font-medium text-rose-600 hover:bg-rose-200 focus:outline-none focus-visible:ring focus-visible:ring-rose-500/75">*/}
                  {/*                <span>Is Clique free?</span>*/}
                  {/*                <ChevronDownIcon*/}
                  {/*                  className={`${*/}
                  {/*                    open ? 'rotate-180 transform' : ''*/}
                  {/*                  } h-5 w-5 text-rose-500`}*/}
                  {/*                />*/}
                  {/*              </Disclosure.Button>*/}
                  {/*              <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-slate-500">*/}
                  {/*                Yes. The core functionality will always be free. */}
                  {/*                <br/><br/>*/}
                  {/*                We will never run ads or sell your data. We trust Clique too. */}
                  {/*                <br/>*/}
                  {/*                We plan to launch premium features like AI-therapy, a matchmaking service, and many others to support Clique, but expressing your thoughts and connecting with someone who shares them will always be free. We believe the internet can be a lonely place. We aim to create a space for meaningful human connection.*/}
                  {/*              </Disclosure.Panel>*/}
                  {/*            </>*/}
                  {/*          )}*/}
                  {/*        </Disclosure>*/}
                  {/*      </div>*/}
                  {/*    </div>*/}

                  {/*<div className='fixed md:hidden inset-x-0 bottom-0 bg-gradient-to-b from-transparent flex items-center justify-center pt-3 pb-14 via-slate-50 to-slate-50' style={{ pointerEvents: 'none' }}>*/}
                  {/*  <SignUpButton  className="plausible-event-name=Signup  bg-rose-600 hover:bg-rose-500 text-slate-900 font-bold py-3 px-5 mt-10 rounded-md" style={{ pointerEvents: 'auto' }}>*/}
                  {/*    Sign up to Clique*/}
                  {/*  </SignUpButton>*/}
                  {/*</div>*/}
                </div>
              </div>
            </div>
          </div>
        </SignedOut>
        <SignedIn>
          <div className="absolute pt-[83px] inset-0 z-10 flex flex-col h-[100svh] items-center px-3">
            <div className="w-full  h-[100svh] max-w-3xl">
              {/*<div className="relative isolate overflow-hidden px-1 md:px-6 lg:flex lg:px-10">*/}
              {/*  <div className="mx-auto text-center lg:mx-0 lg:flex-auto lg:py-10">*/}
              {/*    <h2 className="text-xl font-bold tracking-tight text-slate-700 sm:text-4xl">Where people and ideas connect.</h2>*/}
              {/*    <div className="mt-2 md:mt-3 px-3 md:px-0 text-sm md:text-base leading-7 text-slate-600 antialiased">With your thoughts and with others.</div>*/}
              {/*    <p className="mt-2 md:mt-6 px-3 md:px-0 text-sm md:text-base leading-7 text-slate-600 antialiased">Say a thought or reply to a question. Connect with people who have throght about this too.</p>*/}
              {/*  </div>*/}
              {/*</div>*/}
              <div className="mt-24">
                <EntrySubmit
                  onReceiveResponse={handleResponse}
                  isGeneratingResponse={handleGeneration}
                  ref={entrySubmitRef}
                  updateIsResponseLoading={updateIsResponseLoadingInChild} // Passing the function as a prop
                />
              </div>
              {response && (
                <Transition
                  show={!isResponseGenerated}
                  enter="transition-opacity duration-75"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity duration-150"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="flex items-center justify-end mt-20 mb-2 px-5">
                    <button
                      onClick={closeResponses}
                      className="bg-white hover:bg-slate-300 text-slate-400 hover:text-slate-600 font-normal text-base py-1 pl-2 pr-3 rounded-full flex items-center gap-1 focus:outline-none focus:shadow-outline"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                      <span className="text-sm">Close</span>
                    </button>
                  </div>
                  <div className="bg-white border-2 rounded-3xl mb-10">
                    <div className=" px-4  py-4 ">
                      <div className="flex justify-between items-center mb-3">
                        {response.entry.entry_prompt_title ? (
                          <div className="text-slate-700  font-bold ">
                            {response.entry.entry_prompt_title}
                          </div>
                        ) : (
                          <div className="text-slate-500 text-sm font-bold">You wrote...</div>
                        )}
                      </div>
                      <div className="text-slate-700 mt-4 font-medium">
                        {response.responseText.split('\n').map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            {index < response.responseText.split('\n').length - 1 && <br />}
                          </React.Fragment>
                        ))}
                        {response.entry.audio_url && (
                          <div className="mt-2">
                            <button
                              onClick={e => handleTogglePlayer(e, response.entry.audio_url)}
                              className="p-1 border-[1.5px] border-slate-500 text-slate-500 flex items-center justify-center rounded-full"
                            >
                              {isPlaying && currentUrl === response.entry.audio_url ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={2}
                                  stroke="currentColor"
                                  className="w-4 h-4"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M15.75 5.25v13.5m-7.5-13.5v13.5"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={2}
                                  stroke="currentColor"
                                  className="w-4 h-4 -mr-px "
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
                                  />
                                </svg>
                              )}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className=" rounded-b-md">
                    <b className="text-slate-500">Related beams app</b>
                    <div className="mt-2">
                      {response.othersMatches &&
                        response.othersMatches.map(match => {
                          const date = new Date(match.metadata.entry_datetime);
                          // Now get the relative time
                          const timeAgo = formatDistanceToNow(date, { addSuffix: false });
                          return (
                            <div
                              onClick={() => showEntryLoading(match.id, match.metadata.entry_id)}
                              key={match.id}
                              className=""
                            >
                              <div className="rounded-3xl cursor-default px-4 py-3 bg-slate-100/60 border-2 border-slate-400/20 mb-2 transition ease-in-out duration-100 hover:bg-slate-200">
                                <div className="font-semibold mb-1 text-sm">
                                  {match.metadata.username}
                                </div>
                                <div className="flex justify-between items-center mb-2 text-slate-800">
                                  <div className="flex gap-3">
                                    {match.metadata.audio_url && (
                                      <div>
                                        <button
                                          onClick={e =>
                                            handleTogglePlayer(e, match.metadata.audio_url)
                                          }
                                          className="p-1 border-[1.5px] border-slate-500 hover:border-slate-700 text-slate-500 hover:text-slate-700 flex items-center justify-center rounded-full"
                                        >
                                          {isPlaying && currentUrl === match.metadata.audio_url ? (
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              fill="none"
                                              viewBox="0 0 24 24"
                                              strokeWidth={2}
                                              stroke="currentColor"
                                              className="w-4 h-4"
                                            >
                                              <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M15.75 5.25v13.5m-7.5-13.5v13.5"
                                              />
                                            </svg>
                                          ) : (
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              fill="none"
                                              viewBox="0 0 24 24"
                                              strokeWidth={2}
                                              stroke="currentColor"
                                              className="w-4 h-4 -mr-px "
                                            >
                                              <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
                                              />
                                            </svg>
                                          )}
                                        </button>
                                      </div>
                                    )}
                                    {match.metadata.text.split('\n').map((line, index) => (
                                      <React.Fragment key={index}>
                                        {line}
                                        {index < match.metadata.text.split('\n').length - 1 && (
                                          <br />
                                        )}
                                      </React.Fragment>
                                    ))}
                                  </div>
                                  <div className=" text-slate-500">
                                    <div className="">
                                      {isEntryLoading[match.id] ? (
                                        <svg
                                          id={match.id}
                                          aria-hidden="true"
                                          className="w-4 h-4  text-rose-500 animate-spin dark:text-slate-600 fill-slate-50"
                                          viewBox="0 0 100 101"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                            fill="currentColor"
                                          />
                                          <path
                                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                            fill="currentFill"
                                          />
                                        </svg>
                                      ) : (
                                        <svg
                                          id={match.id}
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          strokeWidth={1.5}
                                          stroke="currentColor"
                                          className="w-4 h-4"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                                          />
                                        </svg>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="text-sm flex justify-between text-slate-500">
                                  {timeAgo} ago
                                  <div className="text-slate-400 text-sm ml-2">{match.score}</div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      {response.othersMatches.length === 0 && (
                        <>
                          <div className="py-2 flex items-center gap-2 text-sm text-slate-500">
                            <div className="flex flex-col gap-1">
                              <div>Great job!</div>
                              <div className="">
                                Now people can connect with your beam!
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="icon icon-tabler icon-tabler-seeding h-4 w-4 inline-flex ml-1 -mt-[5px]"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                  <path d="M12 10a6 6 0 0 0 -6 -6h-3v2a6 6 0 0 0 6 6h3" />
                                  <path d="M12 14a6 6 0 0 1 6 -6h3v1a6 6 0 0 1 -6 6h-3" />
                                  <path d="M12 20l0 -10" />
                                </svg>
                              </div>
                              <div className="flex items-center">
                                Check back later in &quot;
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="icon icon-tabler icon-tabler-flare w-3 h-3 mr-0.5"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  strokeWidth="2"
                                  stroke="currentColor"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                  <path d="M12 3l3 6l6 3l-6 3l-3 6l-3 -6l-6 -3l6 -3z" />
                                </svg>
                                Your beams&quot; tab.
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    {response.yourMatches && response.yourMatches.length !== 0 && (
                      <div className="mt-10">
                        <b className="text-slate-500">Your related beams</b>
                        <div className="mt-2">
                          {response.yourMatches &&
                            response.yourMatches.map(match => {
                              const date = new Date(match.metadata.entry_datetime);
                              // Now get the relative time
                              const timeAgo = formatDistanceToNow(date, { addSuffix: false });
                              return (
                                <div
                                  onClick={() =>
                                    showEntryLoading(match.id, match.metadata.entry_id)
                                  }
                                  key={match.id}
                                  className=""
                                >
                                  <div
                                    key={match.id}
                                    className="rounded-3xl cursor-default p-4 bg-slate-100/20 border-2 border-slate-400/20 mb-2 transition ease-in-out duration-100 hover:bg-slate-200"
                                  >
                                    <div className="flex justify-between items-center mb-2 text-slate-900">
                                      <div className="flex gap-3">
                                        {match.metadata.audio_url && (
                                          <div>
                                            <button
                                              onClick={e =>
                                                handleTogglePlayer(e, match.metadata.audio_url)
                                              }
                                              className={
                                                isPlaying
                                                  ? 'focus:outline-none shadow-sm focus:shadow-outline flex items-center bg-slate-100 rounded-full'
                                                  : 'p-1 border-[1.5px] border-slate-500 text-slate-500 flex items-center justify-center rounded-full'
                                              }
                                            >
                                              {isPlaying &&
                                              currentUrl === match.metadata.audio_url ? (
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  fill="none"
                                                  viewBox="0 0 24 24"
                                                  strokeWidth={2}
                                                  stroke="currentColor"
                                                  className="w-4 h-4"
                                                >
                                                  <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M15.75 5.25v13.5m-7.5-13.5v13.5"
                                                  />
                                                </svg>
                                              ) : (
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  fill="none"
                                                  viewBox="0 0 24 24"
                                                  strokeWidth={2}
                                                  stroke="currentColor"
                                                  className="w-4 h-4 -mr-px "
                                                >
                                                  <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
                                                  />
                                                </svg>
                                              )}
                                            </button>
                                          </div>
                                        )}
                                        {match.metadata.text.split('\n').map((line, index) => (
                                          <React.Fragment key={index}>
                                            {line}
                                            {index < match.metadata.text.split('\n').length - 1 && (
                                              <br />
                                            )}
                                          </React.Fragment>
                                        ))}
                                      </div>
                                      <div className="text-slate-500">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          strokeWidth={1.5}
                                          stroke="currentColor"
                                          className="w-4 h-4"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                                          />
                                        </svg>
                                      </div>
                                    </div>
                                    <div className="text-sm flex justify-between text-slate-500">
                                      {timeAgo} ago
                                      <div className="text-slate-400 text-sm ml-2">
                                        {match.score}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    )}
                  </div>
                </Transition>
              )}
            </div>
          </div>
        </SignedIn>
      </div>
    </>
  );
};

export default MyForm;

import React from 'react';

import { routes } from '../../const/routes';
import { Link } from '../../ui';
import { ReactComponent as ArticleIcon } from './assets/article.svg';
import { ReactComponent as AsteriskIcon } from './assets/asterisk.svg';
import { ReactComponent as ChatIcon } from './assets/chat.svg';
import { ReactComponent as NotificationIcon } from './assets/notification.svg';
import './Menu.css';

function Menu({ className, isSticky }) {
  const menuItems = [
    {
      id: 'main',
      path: routes.main,
      Icon: ArticleIcon,
    },
    {
      id: 'beams',
      path: routes.beams,
      Icon: AsteriskIcon,
      isDisabled: true,
    },
    {
      id: 'chats',
      path: routes.chats,
      Icon: ChatIcon,
      isDisabled: true,
    },
    {
      id: 'notifications',
      path: routes.notifications,
      Icon: NotificationIcon,
      isDisabled: true,
    },
  ];

  return (
    <div
      className={`${className} menu flex w-fit gap-3 py-3 px-3.5 rounded-[50px] bg-[#FFFFFFCC] ${isSticky && 'sticky'}`}
    >
      {menuItems.map(({ id, path, Icon, isDisabled = false }) => {
        const baseNavLinkClassName = 'menu-item px-2';

        return (
          <Link
            isNav
            key={id}
            to={path}
            exact={false}
            className={baseNavLinkClassName}
            activeClassName="menu-item-active"
            isDisabled={isDisabled}
          >
            <Icon />
          </Link>
        );
      })}
    </div>
  );
}

export default Menu;

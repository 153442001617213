import React, { useEffect, useRef } from 'react';
import {
  format,
  startOfYear,
  endOfYear,
  eachDayOfInterval,
  isSameDay,
  getDay,
  startOfWeek,
} from 'date-fns';

const generateDaysOfYear = year => {
  const start = startOfYear(new Date(year, 0, 1));
  const end = endOfYear(new Date(year, 11, 31));
  const days = eachDayOfInterval({ start, end });

  // Ensure today is the last square
  const today = new Date();
  if (!days.some(day => isSameDay(day, today))) {
    days.push(today);
  }

  return days;
};

const mapEntriesToDays = entries => {
  return entries.reduce((acc, entry) => {
    const date = format(new Date(entry.metadata.entry_datetime), 'yyyy-MM-dd');
    acc[date] = (acc[date] || 0) + 1;
    return acc;
  }, {});
};

const getColorForDay = count => {
  if (count >= 5) return 'bg-rose-700';
  if (count >= 3) return 'bg-rose-500';
  if (count >= 1) return 'bg-rose-300';
  return 'bg-slate-100';
};

/* eslint-disable react/prop-types */
const StreakBar = ({ entries }) => {
  const year = new Date().getFullYear();
  const daysOfYear = generateDaysOfYear(year);
  const entriesByDate = mapEntriesToDays(entries);

  // Create an array for each week starting on Monday
  const weeks = [];
  let currentWeek = [];
  let currentWeekIndex = 0;
  let today = new Date();

  daysOfYear.forEach((day, index) => {
    if (getDay(day) === 1 || currentWeek.length === 0) {
      // Start a new week on Monday
      if (currentWeek.length > 0) {
        weeks.push(currentWeek);
        if (currentWeek.some(d => isSameDay(d, today))) {
          currentWeekIndex = weeks.length - 1;
        }
      }
      currentWeek = new Array(7).fill(null); // Ensure each week has 7 days
    }
    currentWeek[getDay(day) === 0 ? 6 : getDay(day) - 1] = day;
  });

  if (currentWeek.length > 0) {
    weeks.push(currentWeek);
    if (currentWeek.some(d => isSameDay(d, today))) {
      currentWeekIndex = weeks.length - 1;
    }
  }

  const weekRefs = useRef([]);

  useEffect(() => {
    if (weekRefs.current[currentWeekIndex]) {
      weekRefs.current[currentWeekIndex].scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [currentWeekIndex]);

  return (
    <div className="overflow-x-auto pt-3 md:pt-8 pb-2">
      <div className="flex justify-between px-10 w-[760px]">
        <div className="text-xs text-right text-slate-400 font-light h-3 mb-1">Янв</div>
        <div className="text-xs text-right text-slate-400 font-light h-3 mb-1">Фев</div>
        <div className="text-xs text-right text-slate-400 font-light h-3 mb-1">Мар</div>
        <div className="text-xs text-right text-slate-400 font-light h-3 mb-1">Апр</div>
        <div className="text-xs text-right text-slate-400 font-light h-3 mb-1">Май</div>
        <div className="text-xs text-right text-slate-400 font-light h-3 mb-1">Июн</div>
        <div className="text-xs text-right text-slate-400 font-light h-3 mb-1">Июл</div>
        <div className="text-xs text-right text-slate-400 font-light h-3 mb-1">Авг</div>
        <div className="text-xs text-right text-slate-400 font-light h-3 mb-1">Сен</div>
        <div className="text-xs text-right text-slate-400 font-light h-3 mb-1">Окт</div>
        <div className="text-xs text-right text-slate-400 font-light h-3 mb-1">Ноя</div>
        <div className="text-xs text-right text-slate-400 font-light h-3 mb-1">Дек</div>
      </div>
      <div className="flex ">
        <div className="">
          <div className="text-xs text-right text-slate-400 font-light h-3 -mt-0.5">Пон</div>
          <div className="text-xs text-right text-slate-400 font-light h-3 mt-[0.9rem]">Ср</div>
          <div className="text-xs text-right text-slate-400 font-light h-3 mt-[1rem]">Пят</div>
          <div className="text-xs text-right text-slate-400 font-light h-3 mt-[1rem]">Вос</div>
        </div>

        {weeks.map((week, weekIndex) => (
          <div
            key={weekIndex}
            className="flex flex-col"
            ref={el => (weekRefs.current[weekIndex] = el)}
          >
            {week.map((day, dayIndex) => {
              const isToday = day && isSameDay(day, today);
              return (
                <div
                  key={dayIndex}
                  className={`w-[0.65rem] h-[0.65rem] rounded-sm m-[0.1rem] ${isToday ? 'border-2 border-rose-700' : ''} ${day ? getColorForDay(entriesByDate[format(day, 'yyyy-MM-dd')] || 0) : 'bg-white'}`}
                  title={day ? format(day, 'MMM dd, yyyy') : ''}
                ></div>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
};

export default StreakBar;

import React from 'react';

import BaseLayout from '../layout/BaseLayout';
import RecorderWidget from '../components/RecorderWidget/RecorderWidget';
import Menu from '../components/Menu/Menu';

function Main() {
  return (
    <BaseLayout>
      <RecorderWidget />
      <Menu className="mx-auto top-3" isSticky />
    </BaseLayout>
  );
}

export default Main;

import React from 'react';
import { useAuth } from '@clerk/clerk-react';
import { formatDistanceToNow } from 'date-fns';
import { ru } from 'date-fns/locale';

import { useAudioPlayer } from './AudioPlayer/AudioPlayerContext';

const FilteredRelatedBeam = ({ data, onClick, isEntryLoading }) => {
  const { userId } = useAuth();
  const { isPlaying, play, pause, currentUrl } = useAudioPlayer();

  const INCOGNITO_USER = 'Инкогнито пользователь';
  const MATCH = 'Мэтч';

  const isCurrentUser = data.user_id === userId;
  const isIncognito = data.is_incognito;
  const displayName = isIncognito ? INCOGNITO_USER : data.username;

  const date = new Date(data.entry_datetime);
  // Now get the relative time
  const timeAgo = formatDistanceToNow(date, { addSuffix: false, locale: ru });
  function toPercentage(decimalStr) {
    // Convert the string to a float
    const decimalNum = parseFloat(decimalStr);

    // Check if the number is greater than 1.0
    if (decimalNum >= 1.0) {
      return '100%';
    }

    // Use regex to ensure the string only contains a valid decimal number
    const regex = /^0\.\d+$/;

    if (regex.test(decimalStr)) {
      // Multiply by 100 for percentage and round to one decimal place
      const percentage = (decimalNum * 100).toFixed(1);
      return `${percentage}%`;
    } else {
      throw new Error('Invalid decimal input');
    }
  }

  const handleTogglePlayer = event => {
    event.stopPropagation();
    isPlaying && currentUrl === data.audio_url ? pause() : play(data.audio_url);
  };

  return (
    <div onClick={onClick}>
      <div className="rounded-3xl cursor-default px-4 text-slate-500 py-3 bg-slate-100/60 border-2 border-slate-400/20 mb-2 transition ease-in-out duration-100 hover:bg-slate-200">
        <div className="font-semibold mb-1 text-sm">
          {isIncognito ? INCOGNITO_USER : displayName}{' '}
          {isCurrentUser && <span className="text-rose-600">(ты)</span>}
        </div>

        <div className="flex justify-between items-center mb-2 text-slate-800">
          <div className="flex gap-3">
            <div className="flex flex-col">
              <div className="font-semibold mb-3 text-sm">{data.entry_prompt_title}</div>

              <div className="flex gap-3">
                {!data.is_incognito && data.audio_url && (
                  <div className="-mt-0.5">
                    <button
                      onClick={handleTogglePlayer}
                      className="p-1.5 bg-gradient-to-tr from-[#cb2d3e] to-[#ef473a] hover:to-[#cb2d3e] text-white flex items-center justify-center rounded-full"
                    >
                      {isPlaying && currentUrl === data.audio_url ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={2}
                          stroke="currentColor"
                          className="w-4 h-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 5.25v13.5m-7.5-13.5v13.5"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={2}
                          stroke="currentColor"
                          className="w-4 h-4 -mr-px "
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
                          />
                        </svg>
                      )}
                    </button>
                  </div>
                )}

                {data.text.split('\n').map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    {index < data.text.split('\n').length - 1 && <br />}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
          <div className=" text-slate-500">
            <div className="">
              {isEntryLoading[data.id] ? (
                <svg
                  id={data.id}
                  aria-hidden="true"
                  className="w-4 h-4  text-rose-500 animate-spin dark:text-gray-600 fill-slate-50"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              ) : (
                <svg
                  id={data.id}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                  />
                </svg>
              )}
            </div>
          </div>
        </div>
        <div className="text-sm flex justify-between text-slate-500">
          {timeAgo}
          {data.score && (
            <div className="text-slate-400 text-sm ml-2">
              {MATCH} {toPercentage(data.score)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FilteredRelatedBeam;

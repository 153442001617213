import React, { Fragment, useState, useRef, useEffect, useImperativeHandle } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth, useUser, SignUpButton } from '@clerk/clerk-react';
import { Dialog, Transition } from '@headlessui/react';

import { useAudioPlayer } from './AudioPlayer/AudioPlayerContext';
import { supabase } from '../supabaseClient';

const IS_INCOGNITO = 'isIncognito';
export const PLACEHOLDER_QUESTION_TITLE = 'placeholderQuestionTitle';
export const PLACEHOLDER_QUESTION_ID = 'placeholderQuestionId';

/* eslint-disable react/display-name */
const EntrySubmit = React.forwardRef((props, ref) => {
  const { isPlaying, play, pause, stop, currentUrl } = useAudioPlayer();
  const [text, setText] = useState('');
  const [response, setResponse] = useState(null);
  const [placeholderQuestion, setPlaceholderQuestion] = useState('');
  const [aiQuestion, setAiQuestion] = useState('');
  const [placeholderQuestionId, setPlaceholderQuestionId] = useState('');
  const { userId } = useAuth();
  const { user } = useUser();
  const [audioUrl, setAudioUrl] = useState('');
  const [updatePrompt, setUpdatePrompt] = useState(1);
  const [isResponseLoading, setIsResponseLoading] = useState(false);
  const [isAudioTranscribing, setIsAudioTranscribing] = useState(false);
  const textareaRef = useRef(null); // Create a ref for the textarea
  const audioRecorderRef = useRef(null);
  const [categories, setCategories] = useState([]);
  const [userQuestions, setUserQuestions] = useState([]);
  const [updateCategories, setUpdateCategories] = useState(1);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState({});
  const [isRootPath, setIsRootPath] = useState(false);
  const location = useLocation();
  const navigate = useNavigate(); // Initialize useNavigate hook
  const [originalBeamId, setOriginalBeamId] = useState('');
  const [beamReplyNotification, setBeamReplyNotification] = useState('');
  const [isReflection, setIsReflection] = useState(false);
  const [tags, setTags] = useState([]);
  const [isPrivate, setIsPrivate] = useState(false);
  const [isIncognito, setIsIncognito] = useState(localStorage.getItem(IS_INCOGNITO) === 'true');
  const [isHowItWorksOpen, setIsHowItWorksOpen] = useState(false);

  const CLICK_TO_EDIT = 'Нажми чтобы редактировать'; // Click on text to edit
  const VOICE = 'Записать'; //Voice
  const STOP = 'Стоп'; //Stop
  const CONTINUE = 'Продолжить'; //Continue
  const PAUSE = 'Пауза'; //Pause
  const DELETE_TEXT = 'Удалить текст'; //Delete text
  const DELETE_VOICE = 'Удалить голос'; //Delete voice
  const BEAM_IT = 'Создать бим'; //Beam it
  const TRANSCRIBING = 'Обработка...'; // Transcribing...
  const INPUT_PLACEHOLDER = 'О чем думаешь?'; // What's on your mind?
  const LISTENING = 'Слушаю...'; //Listening...
  const TRANSCRIBING_PLACEHOLDER = 'Расшифровываю запись...'; //Listening...
  const RESUMED_LISTENING = 'Продолжаю слушать...'; // Resumed listening...
  const PAUSED_LISTENING = 'Запись на паузе'; // Paused listening.
  const MAIN_EXPLAINER_TEXT =
    'Запиши свои мысли или нажми на один из вопросов ниже чтобы начать рефлексию.'; // Say how your day is going or use questions below to reflect and discover like-minded people.
  const MAIN_EXPLAINER_SUBTEXT_1 = 'Нажми на вопрос чтобы ответить.'; // Tap on a question to set a prompt.
  const MAIN_EXPLAINER_SUBTEXT_2 = 'Отвечай на все вопросы чтобы разблокировать награды.'; // Complete topics to unlock achievements.
  const NEW_QUESTION_EVERY_DAY = 'Новый вопрос каждый день'; // New question every day
  const LEFT = 'осталось'; // left
  const POWERING_UP = 'Загружаю...'; // Powering up...
  const AI_PROMPT = 'Твой персональный AI-промпт'; // Your personal AI prompts
  const AI_PROMPT_DESCRIPTION = 'Clique AI сгенерирует вопрос на основе твоих бимов'; // Clique AI will generate a question based on your beams
  const GENERATING = 'Генерирую...'; //Generating...
  const REFLECT_ON_YOUR_BEAMS = 'Рефлексия на твои бимы'; //Reflect on your beams
  const BEAMING_THOUGHTS = 'Загружаю бим в коллективное сознание...'; //Beaming your thoughts into collective consciousness...
  const HEADLINE = 'Рефлексируй и находи людей, кто думает о том же'; //"AI-powered journal that connects you with like-minded people through your thoughts" //AI-powered journal that connects you with like-minded people through your thoughts

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  let [categories1] = useState({
    Обычный: [
      {
        id: 1,
        title: 'Стандартный бим с голосом, юзернемом, ответами, мэтчингом и чатом.',
        date: '5h ago',
        commentCount: 5,
        shareCount: 2,
      },
    ],
    Инкогнито: [
      {
        id: 1,
        title: 'Бим без голоса и юзернейма. Но с ответами, мэтчингом и чатом.',
        date: 'Jan 7',
        commentCount: 29,
        shareCount: 16,
      },
    ],
    Приватный: [
      {
        id: 1,
        title: 'Бим без мэтчинга, ответов, чата, но с голосом.',
        date: '2d ago',
        commentCount: 9,
        shareCount: 5,
      },
    ],
  });

  useEffect(() => {
    // Check if the current path is the root path
    if (location.pathname === '/') {
      setIsRootPath(true);
    } else {
      setIsRootPath(false);
    }
  }, [location]); // This effect runs when the location (URL path) changes

  useImperativeHandle(ref, () => ({
    updateIsResponseLoading: loadingState => {
      console.log('Loading state: ', loadingState);
      setIsResponseLoading(loadingState);
      setResponse('');
      textareaRef.current.focus();
      window.scrollTo(0, 0);
    },
    updatePlaceholder: question => {
      setPlaceholderQuestion(question);
      textareaRef.current.focus();
      adjustHeight(textareaRef.current);
    },
    updateText: () => {
      textareaRef.current.placeholder = INPUT_PLACEHOLDER; // Set placeholder to " "
      textareaRef.current.focus();
      setText('');
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.stop();
        mediaRecorderRef.current = null; // Discard the MediaRecorder instance
        setRecordingStatus('canceled');
      }
      setIsRecording(false);
      if (audioRecorderRef.current) {
        audioRecorderRef.current.clearRecorder();
      }
      adjustHeight(textareaRef.current);
    },
    isReflection: status => {
      setIsReflection(status);
    },
    updatePlaceholderId: id => {
      setPlaceholderQuestionId(id);
      console.log('Entry Prompt ID:', id);
      textareaRef.current.focus();
      adjustHeight(textareaRef.current);
    },
    setOriginalBeamId: beamId => {
      setOriginalBeamId(beamId);
      console.log('Original beam ID:', beamId);
      textareaRef.current.focus();
      adjustHeight(textareaRef.current);
    },
    setOriginalBeamReplyNotification: notification => {
      setBeamReplyNotification(notification);
      textareaRef.current.focus();
      adjustHeight(textareaRef.current);
    },
  }));

  useEffect(() => {
    fetch(process.env.REACT_APP_MAIN_URL + `/?userId=${userId}`)
      .then(response => response.json())
      .then(
        data => setUserQuestions(data.answeredQuestions || []) // Assuming the backend returns an object with answeredQuestions
      )
      .catch(error => console.error('Error:', error));
    console.log(userQuestions);
  }, [userId]);

  useEffect(() => {
    if (textareaRef.current) {
      adjustHeight(textareaRef.current);
    }
  }, [text]);

  useEffect(() => {
    const fetchCategories = async () => {
      const { data, error } = await supabase.from('categories').select(`
        *,
        prompts (id, title, description, link)
      `);

      if (error) console.log('Error fetching data:', error);
      else {
        console.log(data);
        setCategories(data);
      }
    };

    fetchCategories();
  }, [updateCategories]);

  const mediaRecorderRef = useRef(null);
  const recordingTimeoutRef = useRef(null); // For stopping the recording

  const wakeLockRef = useRef(null);
  const MAX_RETRIES = 3;
  const RETRY_DELAY = 3000;
  const [isRecording, setIsRecording] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAiQuestionLoading, setIsAiQuestionLoading] = useState(false);
  const [isImproveEntryTextLoading, setIsImproveEntryTextLoading] = useState(false);
  const [improvedTextDone, setImprovedTextDone] = useState(false);
  const [numberOfEntries, setNumberOfEntries] = useState(0);
  const [retryCount, setRetryCount] = useState(0);
  const [aBlob, setABlob] = useState('');
  const [recordingStatus, setRecordingStatus] = useState('');
  const [intervalId, setIntervalId] = useState(null);
  const [timer, setTimer] = useState(null);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [statusText, setStatusText] = useState('');
  const UNLOCK_AI_QUESTIONS = `Создай еще ${3 - numberOfEntries} бима чтобы открыть`; // Powering up...

  useEffect(() => {
    if (recordingStatus === 'recording') {
      // Start or resume the timer
      const id = setInterval(() => {
        setTimer(prevTimer => prevTimer + 1);
      }, 1000);
      setIntervalId(id);
    } else if (recordingStatus === 'paused' || recordingStatus === 'stopped') {
      // Pause the timer
      if (intervalId) {
        clearInterval(intervalId);
        setIntervalId(null);
      }
    } else {
      // Stop and reset the timer
      clearInterval(intervalId);
      setIntervalId(null);
      setTimer(0);
    }

    return () => {
      // Clean up interval on component unmount
      clearInterval(intervalId);
    };
  }, [recordingStatus]);

  // Helper function to format the timer into mm:ss format
  const formatTime = time => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const improveEntryText = async (text, retryCount = 0, maxRetries = 5) => {
    setIsImproveEntryTextLoading(true);

    try {
      const response = await fetch(process.env.REACT_APP_MAIN_URL + '/improve-text', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({ text, userId }),
      });
      const data = await response.json();

      if (response.ok) {
        setText(data.improved_text);
        setImprovedTextDone(true);
      } else {
        throw new Error(`Failed to fetch feedback: ${data.message}`);
      }
    } catch (error) {
      console.error('Error fetching feedback:', error);
      if (retryCount < maxRetries) {
        setTimeout(
          () => {
            improveEntryText(text, retryCount + 1, maxRetries);
          },
          2000 * (retryCount + 1)
        ); // Exponential back-off
      } else {
        console.error('Max retries reached. Giving up.');
      }
    }

    setIsImproveEntryTextLoading(false);
  };

  // DISABLED FOR SAVING CREDITS
  // useEffect(() => {
  //     setIsAiQuestionLoading(true)
  //     if (userId) {
  //         if (isRootPath) {
  //             fetch(process.env.REACT_APP_MAIN_URL + '/random-question', {
  //                 method: 'POST',
  //                 headers: {
  //                 'Content-Type': 'application/json',
  //                 'Accept': 'application/json',
  //                 },
  //                 body: JSON.stringify({
  //                     user_id: userId,
  //                 })
  //             })
  //             .then(response => response.json())
  //             .then(data => {
  //             //   console.log(data.question.question)
  //             //   console.log(data.num_of_entries)
  //               setAiQuestion(data.question)
  //               setNumberOfEntries(data.num_of_entries)
  //               setIsAiQuestionLoading(false)
  //             })
  //             .catch(error => console.error('Error fetching question:', error));
  //         }
  //     }

  // }, [updatePrompt, isRootPath]);

  useEffect(() => {
    const updateOnlineStatus = () => {
      setIsOnline(navigator.onLine);
      setStatusText(navigator.onLine ? 'Online' : 'No internet connection');
    };

    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, []);

  useEffect(() => {
    // Clear the timeouts when the component is unmounted to avoid memory leaks
    return () => {
      if (recordingTimeoutRef.current) clearTimeout(recordingTimeoutRef.current);
      if (wakeLockRef.current) {
        wakeLockRef.current.release();
        wakeLockRef.current = null;
      }
    };
  }, []);

  const startRecording = async () => {
    try {
      // Request audio recording permission and get the audio stream
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });

      // Initialize the media recorder
      mediaRecorderRef.current = new MediaRecorder(stream);
      const audioChunks = [];

      // Event triggered when recording stops
      mediaRecorderRef.current.onstop = async () => {
        // Check if any audio data was recorded
        if (audioChunks.length === 0) {
          console.error('No audio chunks available');
          setStatusText('No audio data was recorded.');
          return;
        }

        // Create an audio blob from the collected chunks
        const audioBlob = new Blob(audioChunks, { type: 'audio/mp4' });
        console.log(`Audio Blob Size: ${audioBlob.size} bytes`);

        if (audioBlob.size === 0) {
          console.error('Audio Blob is empty.');
          setStatusText('The audio recording is empty. Please try again.');
          return;
        }

        // Generate an object URL for previewing the audio
        const url = URL.createObjectURL(audioBlob);
        setABlob(url);

        // Send the audio blob for transcription
        await sendAudioForTranscription(audioBlob);
      };

      // Collect audio data chunks as they become available
      mediaRecorderRef.current.ondataavailable = event => {
        if (event.data && event.data.size > 0) {
          console.log('Audio chunk captured:', event.data);
          audioChunks.push(event.data);
        } else {
          console.warn('Empty or missing audio data in this chunk.');
        }
      };

      // Start the media recorder
      mediaRecorderRef.current.start();
      setRecordingStatus('recording');
      handleRecordComplete(LISTENING);
      setStatusText('');
      setImprovedTextDone(false);

      // Set up a timer for the recording limit (7 minutes)
      recordingTimeoutRef.current = window.setTimeout(() => {
        stopRecording();
      }, 420000);

      // Request a wake lock if supported
      try {
        if ('wakeLock' in navigator && navigator.wakeLock.request) {
          // @ts-ignore
          wakeLockRef.current = await navigator.wakeLock.request('screen');
        }
      } catch (err) {
        console.error(`Could not obtain wake lock: ${err.message}`);
      }

      setIsRecording(true);
      document.addEventListener('visibilitychange', handleVisibilityChange);
    } catch (err) {
      // Handle possible permission issues or other errors
      console.error(`Error starting recording: ${err.message}`);
      setStatusText('Recording could not start. Please check your device settings or permissions.');
    }
  };

  // Handle visibility change (e.g., control center or background)
  function handleVisibilityChange() {
    if (
      document.visibilityState === 'hidden' &&
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state === 'recording'
    ) {
      pauseRecording();
      console.log('Recording stopped due to app going to the background');
    }
  }

  const cancelRecording = () => {
    stop();
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current = null; // Discard the MediaRecorder instance
      setRecordingStatus('canceled');
    }
    setIsRecording(false);
    setAudioUrl('');
    // Clear any data you've stored from the recording
  };

  const pauseRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.pause();
      setRecordingStatus('paused');
      handleRecordComplete(PAUSED_LISTENING);
    }
  };

  const resumeRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.resume();
      setRecordingStatus('recording');
      handleRecordComplete(RESUMED_LISTENING);
    }
    // Clear any data you've stored from the recording
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      setRecordingStatus('stopped');
      handleRecordComplete(TRANSCRIBING_PLACEHOLDER);

      // console.log(mediaRecorderRef.current.mimeType)
      // Clear the timeouts as we manually stopped the recording
      if (recordingTimeoutRef.current) clearTimeout(recordingTimeoutRef.current);
      console.log('stopping tracks');
      mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
    }
    if (wakeLockRef.current) {
      wakeLockRef.current.release();
      wakeLockRef.current = null;
      console.log('Wake Lock has been released');
    }
  };

  const retryStopRecording = () => {
    setIsRecording(false);
    setRecordingStatus('stopped');
    handleRecordComplete(TRANSCRIBING_PLACEHOLDER);

    // console.log(mediaRecorderRef.current.mimeType)
    // Clear the timeouts as we manually stopped the recording
    if (recordingTimeoutRef.current) clearTimeout(recordingTimeoutRef.current);

    mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());

    // if (wakeLockRef.current) {
    //   wakeLockRef.current.release()
    //   wakeLockRef.current = null
    //   console.log("Wake Lock has been released")
    // }
  };

  const appendPost = [];
  const blobToBase64 = blob => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  // const sendAudioForTranscription = async (audioBlob, retryCount = 0, maxRetries = 5) => {
  //     if (!isOnline) {
  //         setStatusText("Waiting for internet connection...");
  //         return;
  //     }
  //     try {
  //         const formData = new FormData();
  //         const base64Audio = await blobToBase64(audioBlob);
  //         formData.append("audio", base64Audio); // Ensure the correct formatting of the data being sent

  //         const response = await fetch(process.env.REACT_APP_MAIN_URL + "/transcribe", {
  //             method: 'POST',
  //             body: formData
  //         });
  //         if (!response.ok) {
  //             throw new Error("Network response was not ok: " + response.statusText);
  //         }
  //         const data = await response.json();
  //         console.log(data);
  //         setIsLoading(false);
  //         handleRecordCompleteText(data);
  //         setRetryCount(0); // Reset the retry count after a successful submission
  //         setStatusText("Transcription successful!");

  //     } catch (error) {
  //         console.error("Error in transcription:", error);
  //         if (retryCount < maxRetries) {
  //             setText(`Retrying to transcribe... Attempt ${retryCount + 1}/${maxRetries}`)
  //             setTimeout(() => {
  //                 sendAudioForTranscription(audioBlob, retryCount + 1, maxRetries);
  //             }, 2000 * (retryCount + 1)); // Exponential back-off
  //         } else {
  //             console.error("Max retries reached. Giving up.");
  //             setText("Max retries reached. Please check your connection and wait/retry.");
  //         }
  //     }
  // }
  const isValidBase64 = base64String => {
    // Regular expression to validate base64
    const base64Regex = /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;

    // Check if it contains the data URL prefix
    if (!base64String.startsWith('data:audio')) {
      return false;
    }

    // Extract the actual base64 data (after the comma)
    const base64Data = base64String.split(',')[1];

    // Validate using the regular expression
    return base64Regex.test(base64Data);
  };

  function fetchWithTimeout(url, options = {}, timeout = 8000) {
    // Create an AbortController instance
    const controller = new AbortController();
    const { signal } = controller;

    // Set a timeout to abort the request
    const timeoutId = setTimeout(() => controller.abort(), timeout);

    // Add the signal to the options
    options.signal = signal;

    return fetch(url, options)
      .then(response => {
        // Clear the timeout if the request completes
        clearTimeout(timeoutId);
        return response;
      })
      .catch(error => {
        // Check if the request was aborted
        if (error.name === 'AbortError') {
          throw new Error('Request timed out');
        }
        throw error;
      });
  }

  const sendAudioForTranscription = async (audioBlob, retryCount = 0, maxRetries = 5) => {
    // if (!navigator.onLine) {
    //     console.warn("Offline, waiting for an internet connection...")
    //     setStatusText("Waiting for internet connection...")
    //     // return;
    // }

    try {
      // Check the blob size for validation/logging
      // console.log(`Audio blob size: ${audioBlob.size} bytes`)

      // Convert audio blob to a base64 data URL
      const base64Audio = await blobToBase64(audioBlob);
      // console.log(`Base64 Audio Length: ${base64Audio.length}`)

      // // Validate the base64 audio data before sending it to the server
      // if (!isValidBase64(base64Audio)) {
      //     console.error("Invalid base64 audio data")
      //     setStatusText("Invalid audio data format. Please try again.")
      //     // setText(`Invalid base64 audio data. Base64 Audio Length: ${base64Audio.length}. Audio blob size: ${audioBlob.size} bytes. Base64: ${base64Audio}`)
      //     // return;
      // }

      // Append the base64 audio data to FormData
      const formData = new FormData();
      formData.append('audio', base64Audio);

      // Make a POST request to the backend
      const response = await fetchWithTimeout(
        process.env.REACT_APP_MAIN_URL + '/transcribe',
        {
          method: 'POST',
          body: formData,
        },
        60000
      );
      // Позже добавить корреляцию с размером блоба

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      // Parse and handle the response
      const data = await response.json();
      console.log('Transcription Data:', data);
      setIsLoading(false);
      handleRecordCompleteText(data);
      setRetryCount(0); // Reset retry count on success
      setStatusText('Transcription successful!');
    } catch (error) {
      console.error('Error in transcription:', error);
      if (retryCount < maxRetries) {
        setText(`Продолжаю транскрипцию... Попытка ${retryCount + 1}/${maxRetries}`);
        setTimeout(
          () => {
            sendAudioForTranscription(audioBlob, retryCount + 1, maxRetries);
          },
          2500 * (retryCount + 1)
        ); // Exponential back-off
      } else {
        console.error('Max retries reached. Giving up.');
        setText('Max retries reached. Please check your connection and retry.');
      }
    }
  };

  //   if (retries > 0) {
  //     // If there are retries left, try again after a delay
  //     setTimeout(() => {
  //       console.log(`Retrying... (${MAX_RETRIES - retries + 1} of ${MAX_RETRIES})`)
  //       setRetryCount((prevRetryCount) => prevRetryCount + 1)
  //       sendAudioForTranscription(audioBlob, retries - 1)
  //     }, RETRY_DELAY)
  //   } else {
  //     // No retries left, handle the final error state
  //     setRetryCount(0) // Reset the retry count
  //   }
  //     } finally {
  //       if (retries <= 0) {
  //         setIsLoading(false) // End loading only if there are no retries left
  //       }
  //     }
  //   }

  // const [isConnected, setIsConnected] = useState(socket.connect)
  // Connect to the Socket.IO server
  const redirectToSignUp = () => {
    window.location.href = 'https://smiling-dinosaur-45.accounts.dev/sign-up';
  };

  const handleKeyDown = event => {
    if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
      submitForm(event);
    }
  };

  const submitForm = async (e, retryCount = 0, maxRetries = 3) => {
    e.preventDefault();
    setIsResponseLoading(true);
    setText(BEAMING_THOUGHTS);
    /* eslint-disable react/prop-types */
    props.isGeneratingResponse(true);
    setPlaceholderQuestion();
    clearBeamReplyNotification();
    setStatusText('');
    setImprovedTextDone(false);

    if (!text.trim()) {
      setIsResponseLoading(false);
      setText('');
      alert('Please write something before beaming it into collective consciousness.'); // Or handle this error as appropriate for your application
      return; // Exit the function early
    }

    // if (mediaRecorderRef.current) {
    //     mediaRecorderRef.current = null // Discard the MediaRecorder instance
    // }

    if (audioRecorderRef.current) {
      audioRecorderRef.current.clearRecorder();
    }

    if (isIncognito) {
      cancelRecording();
    }
    try {
      const response = await fetch(process.env.REACT_APP_MAIN_URL + '/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
          username: user.username ? user.username : '',
          user_id: userId,
          text: text,
          email: user.primaryEmailAddress.emailAddress,
          audio_url: audioUrl,
          entry_prompt_title: placeholderQuestion ? placeholderQuestion : '',
          entry_prompt_id: placeholderQuestionId ? placeholderQuestionId : '',
          original_beam_id: originalBeamId ? originalBeamId : '',
          is_private: isPrivate,
          is_incognito: isIncognito,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        setResponse(data);
        props.isGeneratingResponse(false);
        props.onReceiveResponse(data); // Call the parent's callback function
        setIsResponseLoading(false);
        setText('');
        setAudioUrl('');
        textareaRef.current.placeholder = INPUT_PLACEHOLDER;
        if (mediaRecorderRef.current) {
          mediaRecorderRef.current = null;
          setRecordingStatus('canceled');
          setIsRecording(false);
        }
        console.log(data.entry.entry_id);
        if (isPrivate) {
          navigate(`/pe/${data.entry.entry_id}`, { replace: true });
        } else {
          navigate(`/e/${data.entry.entry_id}`, { replace: true });
        }
        console.log('DATA: ', data);
      } else {
        throw new Error(`Server error: ${response.status}`);
      }
    } catch (error) {
      console.error('Error during form submission:', error);
      if (retryCount < maxRetries) {
        const retryDelay = 2000 * (retryCount + 1);
        setText(`Повторяю загрузку... Попытка ${retryCount + 1}/${maxRetries}`);
        console.log(`Повторяю загрузку... Попытка ${retryCount + 1}/${maxRetries}`);
        setTimeout(() => submitForm(e, retryCount + 1, maxRetries), retryDelay); // Exponential back-off
      } else {
        console.error('Max retries reached. Giving up on submission.');
        setText('Failed to beam your thoughts. Please check your connection and try again.');
        props.isGeneratingResponse(false);
        setIsResponseLoading(false);
      }
    }
  };

  // const submitForm = async (e) => {
  //     e.preventDefault();
  //     setIsResponseLoading(true)
  //     setText("Beaming your thoughts into collective consciousness...")
  //     props.isGeneratingResponse(true)
  //     setPlaceholderQuestion()
  //     if (!text.trim()) {
  //         setIsResponseLoading(false)
  //         setText("")
  //         alert("Please write something before beaming it into collective consciousness."); // Or handle this error as appropriate for your application
  //         return; // Exit the function early
  //     }
  //     if (audioRecorderRef.current) {
  //         audioRecorderRef.current.clearRecorder();
  //     }

  //     try {
  //     const response = await fetch(process.env.REACT_APP_MAIN_URL + '/', {
  //         method: 'POST',
  //         headers: {
  //         'Content-Type': 'application/json',
  //         'Accept': 'application/json',
  //         },
  //         body: JSON.stringify({
  //         username: user.username ? user.username : "",
  //         user_id: userId,
  //         text: text,
  //         email: user.primaryEmailAddress.emailAddress,
  //         audio_url: audioUrl,
  //         entry_prompt_title: placeholderQuestion ? placeholderQuestion : "",
  //         entry_prompt_id: placeholderQuestionId ? placeholderQuestionId : ""
  //         }),
  //     });

  //     if (response.ok) {
  //         const data = await response.json();
  //         setResponse(data);
  //         props.isGeneratingResponse(false)
  //         props.onReceiveResponse(data); // Call the parent's callback function
  //         // setUpdatePrompt(2)
  //         setIsResponseLoading(false)
  //         setText("")
  //         textareaRef.current.placeholder = INPUT_PLACEHOLDER; // Set placeholder to " "
  //         if (mediaRecorderRef.current) {
  //             mediaRecorderRef.current = null
  //             setRecordingStatus("canceled")
  //             setIsRecording(false)
  //         }

  //         navigate(`/e/${data.entry.entry_id}`, { replace: true });

  //         console.log("DATA: ", data)
  //     } else {
  //         console.error('Server error:', response.status);
  //     }
  //     } catch (error) {
  //     console.error('Network error:', error);
  //     }
  // };
  const adjustHeight = element => {
    element.style.height = 'inherit';
    element.style.height = `${element.scrollHeight + 2}px`;
  };

  const selectCategory = categoryId => {
    const category = categories.find(c => c.id === categoryId);
    if (category && category.prompts && category.prompts.length > 0) {
      let index = currentQuestionIndex[categoryId] || 0;
      const prompt = category.prompts[index];

      setPlaceholderQuestion(prompt.title);
      setPlaceholderQuestionId(prompt.id);

      // Increment the index for next time
      index = (index + 1) % category.prompts.length;
      setCurrentQuestionIndex({
        ...currentQuestionIndex,
        [categoryId]: index,
      });

      if (textareaRef.current) {
        textareaRef.current.placeholder = ' '; // Set placeholder to " "
        textareaRef.current.focus(); // Focus on the textarea
      }
    } else {
      setPlaceholderQuestion('No questions available for this category.');
    }
  };

  const selectPrompt = (categoryId, promptId) => {
    // Find the prompt directly from a flat array of all prompts, not nested under categories
    const category = categories.find(c => c.id === categoryId);
    const prompt = category.prompts.find(p => p.id === promptId);
    if (prompt) {
      setPlaceholderQuestion(prompt.title);
      setPlaceholderQuestionId(prompt.id);
      localStorage.setItem(PLACEHOLDER_QUESTION_TITLE, prompt.title);
      localStorage.setItem(PLACEHOLDER_QUESTION_ID, prompt.id);

      if (textareaRef.current) {
        textareaRef.current.placeholder = ''; // Directly set the placeholder to the prompt title
        textareaRef.current.focus(); // Focus on the textarea
      }
    } else {
      setPlaceholderQuestion('No questions available for this prompt.');
    }
  };

  const selectQuestion = (categoryId, questionId) => {
    const category = categories.find(c => c.id === categoryId);
    if (category && category.prompts && category.prompts.length > 0) {
      // Find the prompt by questionId
      const prompt = category.prompts.find(p => p.id === questionId);

      if (prompt) {
        setPlaceholderQuestion(prompt.title);
        setPlaceholderQuestionId(prompt.id);

        if (textareaRef.current) {
          textareaRef.current.placeholder = ' '; // Set placeholder to " "
          textareaRef.current.focus(); // Focus on the textarea
        }
      } else {
        setPlaceholderQuestion('No questions available for this category.');
      }
    } else {
      setPlaceholderQuestion('No questions available for this category.');
    }
  };

  const selectAIQuestion = question => {
    if (question) {
      setPlaceholderQuestion(question);
      setPlaceholderQuestionId('AI');
      if (textareaRef.current) {
        textareaRef.current.placeholder = ' '; // Set placeholder to " "
        textareaRef.current.focus(); // Focus on the textarea
      }
      setUpdatePrompt(updatePrompt + 1);
    } else {
      setPlaceholderQuestion('No questions available at the moment.');
    }
  };

  const clearPlaceholderQuestion = () => {
    setPlaceholderQuestion('');
    setPlaceholderQuestionId('');
    textareaRef.current.placeholder = INPUT_PLACEHOLDER; // Set placeholder to " "
  };

  const clearBeamReplyNotification = () => {
    setBeamReplyNotification('');
    setOriginalBeamId('');
    textareaRef.current.placeholder = INPUT_PLACEHOLDER; // Set placeholder to " "
  };

  const handleRecordComplete = async status => {
    setIsAudioTranscribing(true);
    setText(status);
  };

  const handleRecordCompleteText = async responseText => {
    setIsAudioTranscribing(false);
    setAudioUrl(responseText.audio_url);
    setText(responseText.transcription);
    console.log('TAGS:', responseText.tags.tags);
    // setTags(responseText.tags.tags)
  };

  // Handle checkbox change
  const handleCheckboxChange = e => {
    setIsPrivate(e.target.checked);
  };

  // Handle checkbox change
  const handleCheckboxChangeIncognito = e => {
    const checked = e.target.checked;
    setIsIncognito(checked);
    localStorage.setItem(IS_INCOGNITO, `${checked}`);
  };

  const clearInputText = () => {
    setText('');
    setStatusText('');
  };

  function closeModal() {
    setIsHowItWorksOpen(false);
  }

  function openModal() {
    setIsHowItWorksOpen(true);
  }

  const handleNewVersion = () => {
    navigate(`/new`);
  };

  const handleTogglePlayer = () => {
    isPlaying && currentUrl === audioUrl ? pause() : play(audioUrl);
  };

  return (
    <div className=" flex flex-col justify-end shadow-none md:shadow-none border-none md:border-2 bg-white mb-2 mt-2 rounded-none md:rounded-3xl focus:shadow-outline">
      {userId ? (
        <>
          <div className={`sticky top-[64px] md:top-[84px] pt-2 bg-white`}>
            {placeholderQuestion && !isReflection && (
              <div
                className=" bg-slate-100 rounded-2xl text-slate-700  font-semibold py-1 mb-2 pl-4 pr-1 -ml-1 -mr-1 -mt-1 "
                htmlFor="text"
              >
                <div className="flex justify-between items-center">
                  {placeholderQuestion}
                  <div className="flex items-center">
                    <button
                      onClick={clearPlaceholderQuestion}
                      className="bg-slate-100 hover:bg-slate-300 text-slate-400 hover:text-slate-700 font-normal text-base p-1 rounded-full focus:outline-none focus:shadow-outline"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                {/*<button */}
                {/*  onClick={handleNewAiQuestion} */}
                {/*  className="bg-slate-200 hover:bg-slate-400 text-slate-600 hover:text-slate-700 font-normal text-base py-1 pl-2 pr-3 rounded-full flex items-center gap-1 focus:outline-none focus:shadow-outline"  */}
                {/*>*/}
                {/*    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">*/}
                {/*      <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />*/}
                {/*    </svg>*/}
                {/*    <span className='text-sm'>Refresh prompt</span>*/}
                {/*</button>*/}
              </div>
            )}

            {beamReplyNotification && (
              <div
                className=" bg-slate-100 rounded-2xl text-slate-700  font-semibold py-1 mb-3 pl-4 pr-1 -ml-1 -mr-1 -mt-1 "
                htmlFor="text"
              >
                <div className="flex justify-between items-center">
                  {beamReplyNotification}
                  {/*<div className='flex items-center'>*/}
                  {/*  <button */}
                  {/*      onClick={clearBeamReplyNotification} */}
                  {/*      className="bg-slate-100 hover:bg-slate-300 text-slate-400 hover:text-slate-700 font-normal text-base p-1 rounded-full focus:outline-none focus:shadow-outline"  */}
                  {/*  >*/}
                  {/*      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">*/}
                  {/*          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />*/}
                  {/*      </svg>*/}
                  {/*  </button>*/}
                  {/*</div>*/}
                </div>
                {/*<button*/}
                {/*  onClick={handleNewAiQuestion}*/}
                {/*  className="bg-slate-200 hover:bg-slate-400 text-slate-600 hover:text-slate-700 font-normal text-base py-1 pl-2 pr-3 rounded-full flex items-center gap-1 focus:outline-none focus:shadow-outline"*/}
                {/*>*/}
                {/*  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}*/}
                {/*       stroke="currentColor" className="w-4 h-4">*/}
                {/*    <path strokeLinecap="round" strokeLinejoin="round"*/}
                {/*          d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"/>*/}
                {/*  </svg>*/}
                {/*  <span className='text-sm'>Refresh prompt</span>*/}
                {/*</button>*/}
              </div>
            )}

            <form className="" onSubmit={submitForm}>
              <div className="relative">
                <textarea
                  ref={textareaRef} // Attach the ref here
                  className="appearance-none  resize-none border-none hover:border bg-transparent transition duration-150 ease-in-out w-full text-lg pt-5 px-3 text-slate-700 leading-tight focus:outline-none"
                  id="entry"
                  name="text"
                  rows={1}
                  disabled={isResponseLoading || isAudioTranscribing}
                  placeholder={INPUT_PLACEHOLDER}
                  value={text}
                  onChange={e => {
                    setText(e.target.value);
                  }}
                  onInput={e => adjustHeight(e.target)}
                  onKeyDown={handleKeyDown} // Attach the event handler here
                ></textarea>
                {statusText === 'Transcription successful!' && (
                  <div>
                    <div className="absolute top-0 text-xs text-slate-400  ml-3">
                      {CLICK_TO_EDIT}
                    </div>
                    {!improvedTextDone ? (
                      <button
                        className={`bg-slate-100 z-10 ml-3 mb-2 hover:bg-slate-200 text-slate-600 hover:text-slate-600 font-normal text-sm py-2 pl-3 pr-3 rounded-full focus:outline-none focus:shadow-outline flex items-center relative`}
                        onClick={() => improveEntryText(text)}
                        disabled={isImproveEntryTextLoading}
                      >
                        {isImproveEntryTextLoading ? (
                          <svg
                            className="animate-spin mr-2 h-5 w-5 text-rose-600"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647zM7.078 19H12v4H7.078z"
                            ></path>
                          </svg>
                        ) : (
                          <>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="mr-1 h-4 w-4"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z"
                              />
                            </svg>
                          </>
                        )}

                        {isImproveEntryTextLoading ? GENERATING : 'Улучшить текст'}

                        {/*<div className="text-xs px-2 py-0.5 inline-flex  border-2 border-slate-900 text-slate-900 font-semibold rounded-full ml-3">*/}
                        {/*  Pro*/}
                        {/*</div>*/}
                      </button>
                    ) : (
                      <div className="ml-3 text-slate-500 text-xs px-2 py-0.5 inline-flex bg-slate-100 rounded-full mb-1 items-center">
                        Текст улучшен
                      </div>
                    )}

                    {/*{tags && (*/}
                    {/*  <div className="ml-4 flex pt-2 mb-2">*/}
                    {/*    <div className="text-xs text-slate-500 mt-[0.1rem] mr-1 inline-flex">*/}
                    {/*      Теги:*/}
                    {/*    </div>*/}
                    {/*    <div className="inline-flex gap-1 flex-wrap">*/}
                    {/*      {tags.map((tag, index) => (*/}
                    {/*        <div key={index} className="bg-slate-100 rounded-full px-2 text-slate-600 py-0.5 text-xs">*/}
                    {/*          <span className="text-slate-600 mr-[0.05rem]">#</span>{tag}*/}
                    {/*        </div>*/}
                    {/*      ))}*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*)}*/}
                  </div>
                )}
              </div>

              <div className="flex justify-between items-end h-[54px] mb-3">
                <div className="ml-3 flex items-center">
                  <div className="">
                    {recordingStatus !== 'recording' &&
                      recordingStatus !== 'stopped' &&
                      recordingStatus !== 'paused' && (
                        <div>
                          {/* <p>Start recording {recordingStatus}</p> */}
                          <button
                            type="button"
                            className=" focus:outline-none shadow-sm focus:shadow-outline flex items-center bg-gradient-to-tr from-[#cb2d3e] to-[#ef473a]  hover:to-[#cb2d3e] text-white rounded-full "
                            onClick={e => {
                              e.stopPropagation();
                              startRecording();
                            }}
                          >
                            <div className=" font-bold py-2 pl-2 pr-2 rounded-full">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-5 h-5 "
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M12 18.75a6 6 0 006-6v-1.5m-6 7.5a6 6 0 01-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 01-3-3V4.5a3 3 0 116 0v8.25a3 3 0 01-3 3z"
                                />
                              </svg>
                            </div>
                            {!text &&
                              recordingStatus !== 'recording' &&
                              recordingStatus !== 'stopped' &&
                              recordingStatus !== 'paused' &&
                              !isResponseLoading && (
                                <div className="font-semibold  text-sm -ml-1 pr-3">{VOICE}</div>
                              )}
                          </button>
                        </div>
                      )}
                    {recordingStatus === 'recording' && (
                      <div className="flex gap-2 items-center">
                        <button
                          type="button"
                          className="bg-slate-800 hover:bg-slate-600 text-white font-bold py-2 pl-2 pr-3 rounded-full text-sm focus:outline-none focus:shadow-outline flex items-center"
                          onClick={e => {
                            e.stopPropagation();
                            stopRecording();
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5 mr-1"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M9 9.563C9 9.252 9.252 9 9.563 9h4.874c.311 0 .563.252.563.563v4.874c0 .311-.252.563-.563.563H9.564A.562.562 0 019 14.437V9.564z"
                            />
                          </svg>
                          {STOP}
                        </button>
                        <button
                          type="button"
                          className="bg-slate-600 hover:bg-slate-600 text-white font-bold text-sm  py-2 pl-2 pr-3 rounded-full focus:outline-none focus:shadow-outline flex items-center"
                          onClick={e => {
                            e.stopPropagation();
                            pauseRecording();
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5 mr-1"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M21 7.5V18M15 7.5V18M3 16.811V8.69c0-.864.933-1.406 1.683-.977l7.108 4.061a1.125 1.125 0 010 1.954l-7.108 4.061A1.125 1.125 0 013 16.811z"
                            />
                          </svg>
                          {PAUSE}
                        </button>
                        <p className="text-slate-600">
                          {formatTime(timer)}
                          <span className="text-slate-400 ml-1">/7:00</span>
                        </p>
                      </div>
                    )}
                    {recordingStatus === 'paused' && (
                      <div className="flex gap-2 items-center">
                        <button
                          type="button"
                          className="bg-slate-600 hover:bg-slate-600 text-white font-bold text-sm  py-2 pl-2 pr-3 rounded-full focus:outline-none focus:shadow-outline flex items-center"
                          onClick={e => {
                            e.stopPropagation();
                            stopRecording();
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5 mr-1"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M9 9.563C9 9.252 9.252 9 9.563 9h4.874c.311 0 .563.252.563.563v4.874c0 .311-.252.563-.563.563H9.564A.562.562 0 019 14.437V9.564z"
                            />
                          </svg>
                          {STOP}
                        </button>
                        <button
                          type="button"
                          className="bg-slate-800 hover:bg-slate-600 text-white font-bold text-sm  py-2 pl-2 pr-3 rounded-full focus:outline-none focus:shadow-outline flex items-center"
                          onClick={e => {
                            e.stopPropagation();
                            resumeRecording();
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5 mr-1"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M21 7.5V18M15 7.5V18M3 16.811V8.69c0-.864.933-1.406 1.683-.977l7.108 4.061a1.125 1.125 0 010 1.954l-7.108 4.061A1.125 1.125 0 013 16.811z"
                            />
                          </svg>
                          {CONTINUE}
                        </button>
                        <p className="text-slate-600">
                          {formatTime(timer)}
                          <span className="text-slate-400 ml-1">/7:00</span>
                        </p>
                      </div>
                    )}
                    {recordingStatus === 'stopped' && audioUrl && (
                      <div className="flex gap-2 items-center ml-0">
                        <div className="focus:outline-none shadow-sm focus:shadow-outline flex items-center bg-slate-100 rounded-full pr-3 z-20">
                          <button
                            type="button"
                            onClick={handleTogglePlayer}
                            className="p-2.5 flex items-center justify-center rounded-full bg-gradient-to-tr from-[#cb2d3e] to-[#ef473a] hover:to-[#cb2d3e] text-white"
                          >
                            {isPlaying && currentUrl === audioUrl ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={2}
                                stroke="currentColor"
                                className="w-4 h-4"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M15.75 5.25v13.5m-7.5-13.5v13.5"
                                />
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={2}
                                stroke="currentColor"
                                className="w-4 h-4 -mr-px "
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
                                />
                              </svg>
                            )}
                          </button>
                          <div className=" text-sm ml-1">
                            <p className="text-slate-600">{formatTime(timer)}</p>
                          </div>
                        </div>
                        {!isResponseLoading && (
                          <button
                            type="button"
                            className="bg-white z-10 hover:bg-slate-200 text-slate-400 hover:text-slate-600 font-normal text-sm  py-2 -ml-7 pl-8 pr-3 rounded-r-full focus:outline-none focus:shadow-outline flex items-center"
                            onClick={e => {
                              e.stopPropagation();
                              cancelRecording();
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-5 h-5 mr-1"
                              width="44"
                              height="44"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                              <path d="M3 3l18 18" />
                              <path d="M9 5a3 3 0 0 1 6 0v5a3 3 0 0 1 -.13 .874m-2 2a3 3 0 0 1 -3.87 -2.872v-1" />
                              <path d="M5 10a7 7 0 0 0 10.846 5.85m2 -2a6.967 6.967 0 0 0 1.152 -3.85" />
                              <path d="M8 21l8 0" />
                              <path d="M12 17l0 4" />
                            </svg>
                            {DELETE_VOICE}
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                  {text &&
                    recordingStatus !== 'recording' &&
                    recordingStatus !== 'stopped' &&
                    recordingStatus !== 'paused' &&
                    !isResponseLoading && (
                      <button
                        onClick={e => {
                          e.stopPropagation();
                          clearInputText();
                        }}
                        className="bg-white ml-2 hover:bg-slate-300 text-slate-400 hover:text-slate-600 font-normal text-base py-1 px-1 h-9 rounded-full flex items-center  focus:outline-none focus:shadow-outline"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-4 h-4 mr-1"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                          />
                        </svg>

                        {/*<svg*/}
                        {/*  xmlns="http://www.w3.org/2000/svg"*/}
                        {/*  className=""*/}
                        {/*  width="44"*/}
                        {/*  height="44"*/}
                        {/*  viewBox="0 0 24 24"*/}
                        {/*  stroke-width="1.5"*/}
                        {/*  stroke="currentColor"*/}
                        {/*  fill="none"*/}
                        {/*  stroke-linecap="round"*/}
                        {/*  stroke-linejoin="round"*/}
                        {/*>*/}
                        {/*  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>*/}
                        {/*  <path*/}
                        {/*    d="M19 20h-10.5l-4.21 -4.3a1 1 0 0 1 0 -1.41l10 -10a1 1 0 0 1 1.41 0l5 5a1 1 0 0 1 0 1.41l-9.2 9.3"/>*/}
                        {/*  <path d="M18 13.3l-6.3 -6.3"/>*/}
                        {/*</svg>*/}

                        <span className="text-sm">{DELETE_TEXT}</span>
                      </button>
                    )}
                </div>
                {recordingStatus !== 'recording' && recordingStatus !== 'paused' && text !== '' && (
                  <div className="flex items-center justify-end submit-button-wrapper mr-3">
                    <span className="hidden md:block">
                      <div className="cmd-return-hint text-xs mr-3 text-slate-400">⌘ + ⏎</div>
                    </span>
                    <button
                      className="plausible-event-name=Beam_created bg-gradient-to-tr from-[#cb2d3e] to-[#ef473a] hover:to-[#cb2d3e] text-white font-bold py-2 px-4 text-sm rounded-full focus:outline-none focus:shadow-outline rotate-gradient "
                      disabled={
                        isResponseLoading ||
                        isAudioTranscribing ||
                        recordingStatus === 'recording' ||
                        recordingStatus === 'paused'
                      }
                      type="submit"
                    >
                      {isResponseLoading ? (
                        <div role="status">
                          <svg
                            aria-hidden="true"
                            className="w-5 h-5 -mr-2 -ml-2 text-rose-500 animate-spin dark:text-slate-600 fill-slate-50"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                          <span className="sr-only">Processing...</span>
                        </div>
                      ) : isAudioTranscribing ? (
                        <div role="status" className="flex items-center">
                          <svg
                            className="animate-spin -ml-2 mr-1 h-5 w-5 text-slate-50"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647zM7.078 19H12v4H7.078z"
                            ></path>
                          </svg>
                          {TRANSCRIBING}
                        </div>
                      ) : (
                        <div className="flex items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-tabler icon-tabler-flare w-4 h-4 mr-1"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M12 3l3 6l6 3l-6 3l-3 6l-3 -6l-6 -3l6 -3z" />
                          </svg>
                          {BEAM_IT}
                        </div>
                      )}
                    </button>
                  </div>
                )}
              </div>
              {(statusText === 'Transcription successful!' ||
                (text &&
                  recordingStatus !== 'recording' &&
                  recordingStatus !== 'stopped' &&
                  recordingStatus !== 'paused' &&
                  !isResponseLoading)) && (
                <div className="px-2 mt-2 flex justify-end items-top gap-2">
                  {isIncognito && (
                    <div className="text-xs w-60 text-slate-500 text-right">
                      Инкогнито бимы не раскрывают твой юзернейм и голос.
                    </div>
                  )}
                  <div className="flex-none">
                    <label
                      htmlFor="is_incognito"
                      className="items-center gap-2 px-3 py-2 bg-slate-100 hover:bg-slate-200 inline-flex rounded-full cursor-pointer text-sm flex-none"
                    >
                      <input
                        id="is_incognito"
                        name="is_incognito"
                        type="checkbox"
                        checked={isIncognito}
                        onChange={handleCheckboxChangeIncognito}
                        className="h-4 w-4 rounded border-slate-300 text-rose-600 focus:ring-rose-600"
                      />
                      Инкогнито бим
                    </label>
                  </div>
                </div>
              )}
            </form>
            {text === '' && isRootPath && (
              <div className="h-px flex bg-gradient-to-l from-white via-slate-300 to-white rounded-full mt-8"></div>
            )}
          </div>
          {!isResponseLoading && !response && isRootPath && (
            <Transition
              show={!isResponseLoading && !response && text === ''}
              enter="transition-opacity duration-75"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-150"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              className="pb-6"
            >
              <div className="p-3 text-slate-600 text-sm flex items-center flex-wrap gap-2">
              <button
              onClick={handleNewVersion}
              className="hover:bg-slate-200/50 text-slate-800 border border-rose-500 flex items-center font-medium py-2 px-2 rounded-full"
            >
             
              <span className='bg-rose-600 text-white text-xs px-1 rounded-full'> New</span>
              <span className=" text-xs ml-1 text-rose-600">Побробовать новую версию</span>
            </button>
                {MAIN_EXPLAINER_TEXT}
                <button
                  type="button"
                  onClick={openModal}
                  className="text-slate-400 hover:underline cursor-pointer hover:text-slate-800"
                >
                  Как пользоваться Кликом?
                </button>

              

              </div>
              <div className="h-px flex bg-gradient-to-l from-white via-slate-300 to-white rounded-full "></div>
              <Transition appear show={isHowItWorksOpen} as={Fragment}>
                <Dialog as="div" className="relative z-50" onClose={closeModal}>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="fixed inset-0 bg-slate-500/25" />
                  </Transition.Child>
                  <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                      >
                        <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-3xl bg-white p-8 text-left align-middle shadow-xl transition-all">
                          <Dialog.Title
                            as="h3"
                            className="text-lg font-medium leading-6 mb-3 text-slate-800"
                          >
                            Добро пожаловать в Clique!
                          </Dialog.Title>
                          <div className="mt-2 flex flex-col gap-3">
                            <p className="text-sm text-slate-500">
                              Clique (Клик) - это твой дневник рефлексии который помогает понять
                              себя лучше и встретить людей кто тоже тебя поймет.
                            </p>
                            <p className="text-sm text-slate-700">
                              Скоро расскажем тут о очевидном и не очень.
                            </p>
                          </div>
                          <div className="mt-4">
                            <button
                              type="button"
                              className="float-right inline-flex justify-center rounded-full border border-transparent bg-gradient-to-tr from-[#cb2d3e] to-[#ef473a] hover:to-[#cb2d3e] text-white font-bold px-4 py-2 text-sm hover:bg-rose-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-rose-500 focus-visible:ring-offset-2"
                              onClick={closeModal}
                            >
                              Понятно!
                            </button>
                          </div>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
              </Transition>

              {/*<div className='mt-6'>*/}
              {/*  <div className='font-semibold text-slate-600 text-base mb-2 ml-3'>*/}
              {/*    <span className='mr-2 inline-flex'>    */}
              {/*        Денис Кукояка*/}
              {/*    </span>*/}
              {/*    <span className='text-slate-500 text-xs font-light'>@denis</span>*/}
              {/*  </div>*/}
              {/*</div>*/}

              {/*<div className='px-3'>*/}
              {/*  {categories.filter(category => category.created_by === "denis").map(category => (*/}
              {/*    <div className='bg-slate-100/30 border border-slate-100 mb-3 p-3 rounded-lg'>*/}
              {/*      <div className='font-semibold text-slate-600 text-sm mb-2 ml-2'>          */}
              {/*        <span className='mr-2 inline-flex'>    */}
              {/*          {category.name}*/}
              {/*        </span>*/}
              {/*      </div>*/}
              {/*        <div className='flex w-full flex-col sm:flex-row gap-4 mt-3'>*/}
              {/*        {category.prompts.map(prompt => (*/}
              {/*          <div*/}
              {/*            key={category.id}*/}
              {/*            onClick={() => selectQuestion(category.id, prompt.id)}*/}
              {/*            className='px-1 py-1 hover:bg-rose-200/50 text-sm rounded-lg border-slate-200/50 cursor-pointer flex gap-4 xs:w-full md:w-1/2 lg:w-1/3'*/}
              {/*          >*/}
              {/*            <div className='flex-col inline-flex'>*/}
              {/*              <div>*/}
              {/*                <button type="button" className="focus:outline-none shadow-sm focus:shadow-outline flex items-center bg-slate-100 rounded-full ">*/}
              {/*                  <div className='bg-gradient-to-tr from-[#cb2d3e] to-[#ef473a]  hover:to-[#cb2d3e] text-white font-bold py-2 pl-2 pr-2 rounded-full'>*/}
              {/*                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5 -mr-px ">*/}
              {/*                          <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z" />*/}
              {/*                      </svg>*/}
              {/*                  </div>*/}
              {/*                </button>*/}
              {/*              </div>*/}
              {/*              <div className="flex">*/}
              {/*                {prompt.link && (*/}
              {/*                  <iframe */}
              {/*                    width="100%"  // Adjust the width as needed*/}
              {/*                    height="100%"  // Adjust the height as needed (keep the 16:9 aspect ratio)*/}
              {/*                    src={`https://www.youtube.com/embed/${prompt.link}`}  // put into a */}
              {/*                    title="YouTube video player" */}
              {/*                    frameBorder="0" */}
              {/*                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" */}
              {/*                    allowFullScreen*/}
              {/*                    modestbranding="0">*/}
              {/*                  </iframe>*/}
              {/*                )}*/}
              {/*              </div>*/}
              {/*              <div className='pt-2 px-1 font-medium text-base text-slate-600 tracking-tight text-wrap'>*/}
              {/*                  {prompt.title}*/}
              {/*              </div>*/}
              {/*              <div className='text-slate-500 px-1 text-xs font-light mt-2'>*/}
              {/*                  Нажми чтобы ответить*/}
              {/*              </div>*/}
              {/*            </div>*/}
              {/*          </div>*/}
              {/*        ))}*/}
              {/*    </div>*/}
              {/*      </div>*/}
              {/*  ))}    */}
              {/*</div>*/}

              {/*{categories.filter(category => category.created_by === "denis").length === 0 && (*/}
              {/*  <div className='flex items-center justify-center w-full p-3'>*/}
              {/*    <div className='text-center'>*/}
              {/*      <div className='flex items-center justify-center font-light'>*/}
              {/*      <svg aria-hidden="true" className="w-5 h-5 mr-2 text-slate-500 animate-spin dark:text-slate-600 fill-slate-50" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
              {/*        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>*/}
              {/*        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>*/}
              {/*      </svg>*/}
              {/*        Powering up...*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*)}*/}

              <div className="flex flex-col w-full mt-8">
                {/*<div className='font-semibold text-slate-600 text-base ml-3'>*/}
                {/*  <span className='mr-2'>*/}
                {/*    Getting to know yourself & others*/}
                {/*  </span>*/}
                {/*</div>*/}

                <div className="p-2 md:p-2 md:ml-0 md:mr-0 rounded-none md:rounded-3xl bg-white border-slate-200 w-full">
                  <div className="flex flex-col gap-2 w-full">
                    {categories
                      .sort((a, b) => a.position - b.position)
                      .filter(category => category.created_by !== 'denis')
                      .map(category => {
                        // Sort prompts so answered prompts go to the bottom
                        const sortedPrompts = [...category.prompts].sort((a, b) => {
                          const aAnswered = userQuestions.includes(a.id);
                          const bAnswered = userQuestions.includes(b.id);
                          if (aAnswered !== bAnswered) {
                            return aAnswered ? 1 : -1;
                          }
                          return new Date(a.created_at) - new Date(b.created_at); // Sort by created_at
                        });

                        const unansweredPrompts = sortedPrompts.filter(
                          prompt => !userQuestions.includes(prompt.id)
                        ).length;

                        return (
                          <>
                            {category.position == 1 ? (
                              <>
                                <div
                                  key={category.id}
                                  className="text-sm mb-7 rounded-2xl font-semibold border-slate-200/50"
                                >
                                  <div className="mb-4 p-1 text-rose-500 font-semibold text-base">
                                    {category.name}{' '}
                                    <span className="text-slate-400 px-2 py-0.5 font-normal text-xs rounded-full">
                                      {NEW_QUESTION_EVERY_DAY}
                                    </span>
                                  </div>
                                  <div>
                                    {sortedPrompts.map(prompt => (
                                      <div
                                        key={prompt.id}
                                        onClick={() => selectPrompt(category.id, prompt.id)}
                                        className={`cursor-pointer hover:bg-rose-300/50 hover:text-slate-700 p-1 rounded-lg mb-1 flex ${
                                          userQuestions.includes(prompt.id)
                                            ? 'line-through hover:[text-decoration-line:none] text-slate-300'
                                            : 'text-slate-600'
                                        }`}
                                      >
                                        <div>
                                          {userQuestions.includes(prompt.id) ? (
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              fill="none"
                                              viewBox="0 0 24 24"
                                              strokeWidth={1.5}
                                              stroke="currentColor"
                                              className="w-5 h-5 mr-1"
                                            >
                                              <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                              />
                                            </svg>
                                          ) : (
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              fill="none"
                                              viewBox="0 0 24 24"
                                              strokeWidth={1.5}
                                              stroke="currentColor"
                                              className="w-5 h-5 mr-1"
                                            >
                                              <circle
                                                cx="12"
                                                cy="12"
                                                r="9"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                            </svg>
                                          )}
                                        </div>
                                        {prompt.title}
                                      </div>
                                    ))}
                                  </div>
                                </div>
                                {/* <div className='h-px flex bg-gradient-to-l from-white via-slate-300 to-white rounded-full mb-8'></div> */}
                              </>
                            ) : (
                              <div
                                key={category.id}
                                className="text-sm rounded-md mb-10 border-slate-200/50"
                              >
                                <div className="mb-1 p-1 text-slate-600 font-semibold">
                                  {category.trending_status && (
                                    <span className="bg-rose-500 text-white px-2 py-0.5 rounded-full mr-1 font-normal text-xs">
                                      {category.trending_status}
                                    </span>
                                  )}
                                  {category.name}
                                  {![2, 3, 4].includes(category.position) && (
                                    <span className="bg-slate-100 px-2 py-0.5 font-normal ml-1 text-xs rounded-full">
                                      {unansweredPrompts} {LEFT}
                                    </span>
                                  )}
                                </div>
                                <div className="mb-3 px-1 text-slate-500 text-xs">
                                  {category.created_by}
                                </div>
                                {category.description && (
                                  <div className="p-2 rounded-xl text-slate-600 text-sm bg-gradient-to-r from-slate-50 via-slate-50/50 to-white mb-2">
                                    {category.description}
                                  </div>
                                )}

                                <div>
                                  {sortedPrompts.map(prompt => (
                                    <div
                                      key={prompt.id}
                                      onClick={() => selectPrompt(category.id, prompt.id)}
                                      className={`cursor-pointer hover:bg-rose-300/50 hover:text-slate-700 p-1 rounded-lg mb-1 flex flex-col ${
                                        userQuestions.includes(prompt.id) &&
                                        ![2, 3, 4].includes(category.position)
                                          ? 'line-through hover:[text-decoration-line:none] text-slate-300'
                                          : 'text-slate-600'
                                      }`}
                                    >
                                      <div className="flex items-center">
                                        <div>
                                          {userQuestions.includes(prompt.id) &&
                                          ![2, 3, 4].includes(category.position) ? (
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              fill="none"
                                              viewBox="0 0 24 24"
                                              strokeWidth={1.5}
                                              stroke="currentColor"
                                              className="w-5 h-5 mr-1"
                                            >
                                              <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                              />
                                            </svg>
                                          ) : (
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              fill="none"
                                              viewBox="0 0 24 24"
                                              strokeWidth={1.5}
                                              stroke="currentColor"
                                              className="w-5 h-5 mr-1"
                                            >
                                              <circle
                                                cx="12"
                                                cy="12"
                                                r="9"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                            </svg>
                                          )}
                                        </div>
                                        {prompt.title}
                                      </div>
                                      <div className="text-xs pl-6 mt-1 text-slate-400">
                                        {prompt.description}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}
                          </>
                        );
                      })}
                  </div>

                  {categories.length === 0 && (
                    <div className="flex items-center justify-center w-full p-3">
                      <div className="text-center">
                        <div className="flex items-center justify-center font-light">
                          <svg
                            aria-hidden="true"
                            className="w-5 h-5 mr-2 text-slate-500 animate-spin dark:text-slate-600 fill-slate-50"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                          {POWERING_UP}
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className=" mt-10">
                  {!isAiQuestionLoading && numberOfEntries < 3 && (
                    <div className="px-2 mx-2 py-1 mb-1 text-xs border border-emerald-200  bg-emerald-100/50 text-emerald-700 rounded-full inline-flex font-light items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-4 h-4 mr-1"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                        />
                      </svg>
                      {UNLOCK_AI_QUESTIONS}
                    </div>
                  )}

                  <div className="font-semibold text-slate-600 text-sm mb-2 ml-3">
                    <span className="mr-2 inline-flex">{AI_PROMPT}</span>
                    <span className="text-slate-500 text-xs font-light">
                      {AI_PROMPT_DESCRIPTION}
                    </span>
                  </div>
                  <div className="p-1 md:p-2 flex">
                    <div className="h-[9rem] w-28 bg-slate-200 rounded-lg relative">
                      <img
                        className=" absolute inset-0 h-full rounded-lg object-cover"
                        src="https://rnjyfufrpnxocfzxmeih.supabase.co/storage/v1/object/public/bot_assets/luke-chesser-CxBx_J3yp9g-unsplash.jpg"
                      />
                      <div className="absolute inset-0 bg-gradient-to-t from-slate-800/30 via-slate-800/10 to-transparent h-full rounded-lg flex items-end">
                        <button
                          type="button"
                          disabled={isAiQuestionLoading || numberOfEntries < 3}
                          onClick={() => selectAIQuestion(aiQuestion)}
                          className={`px-2 py-1 md:px-3 md:py-5 text-left text-base ${
                            isAiQuestionLoading || numberOfEntries < 3
                              ? 'bg-white/30 cursor-auto'
                              : 'hover:bg-rose-300/30'
                          }  h-full w-full flex items-end text-white font-bold rounded-md border-slate-200/50 cursor-pointer`}
                        >
                          {isAiQuestionLoading ? (
                            <div role="status" className="flex flex-col items-center text-sm">
                              <svg
                                aria-hidden="true"
                                className="w-5 h-5  text-rose-500 animate-spin dark:text-slate-600 fill-slate-50 mr-1 mb-2"
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                  fill="currentFill"
                                />
                              </svg>
                              {GENERATING}
                              <span className="sr-only">Loading...</span>
                            </div>
                          ) : (
                            <div className="flex flex-col h-full justify-between">
                              <div className="flex">
                                <div
                                  className={`px-2 py-0.5 font-bold  rounded-full text-xs ${
                                    numberOfEntries < 3 ? 'bg-slate-900/20' : 'bg-slate-900/50'
                                  } `}
                                >
                                  AI
                                </div>
                              </div>

                              {/*<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mr-1">*/}
                              {/*  <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z" />*/}
                              {/*</svg>*/}

                              {REFLECT_ON_YOUR_BEAMS}
                            </div>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition>
          )}
        </>
      ) : (
        <>
          <div className="mx-auto lg:mx-0 text-center lg:flex-auto flex  flex-col items-center justify-center">
            <h2 className="text-3xl font-bold tracking-tight  text-slate-700 md:text-4xl md:leading-[3rem]">
              {HEADLINE}
            </h2>
            <p className="mt-6 text-slate-600 text-sm md:text-base md:leading-7 antialiased">
              Express your feelings, share challenges, and voice ideas to discover only individuals
              who resonate with them. Clique AI will help you reflect and explore shared thoughts in
              a community where{' '}
              <span className="font-bold">
                your voice is heard by only the ones who understand.
              </span>
              <br />
              <SignUpButton
                className="plausible-event-name=Signup bg-gradient-to-tr from-[#cb2d3e] to-[#ef473a]  hover:to-[#cb2d3e] text-slate-50 font-bold py-3 px-5 mt-4 rounded-2xl inline-flex items-center cursor-pointer"
                style={{ pointerEvents: 'auto' }}
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-flare w-4 h-4 mr-2"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M12 3l3 6l6 3l-6 3l-3 6l-3 -6l-6 -3l6 -3z" />
                  </svg>
                  Record your first beam
                </span>
              </SignUpButton>
            </p>
          </div>
        </>
      )}
    </div>
  );
});

export default EntrySubmit;

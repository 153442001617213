import React, { useEffect, createContext, useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const RecorderWidgetContext = createContext();

export const RECORD_STATE = {
  INIT: '',
  NEW_RECORD: '#new-record',
  PREVIEW_RECORD: '#preview-record',
};

export const RecorderWidgetProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(null);
  const [state, setState] = useState(RECORD_STATE.INIT);

  useEffect(() => {
    const handleHashChange = () => {
      const currentHash = location.hash;
      if (
        (currentHash === RECORD_STATE.NEW_RECORD || currentHash === RECORD_STATE.PREVIEW_RECORD) &&
        !isOpen
      ) {
        showRecorderWidget(true);
      } else if (
        !(currentHash === RECORD_STATE.NEW_RECORD || currentHash === RECORD_STATE.PREVIEW_RECORD) &&
        isOpen
      ) {
        hideRecorderWidget();
      }
    };

    handleHashChange();
  }, [location, isOpen]);

  const showRecorderWidget = (replace = false) => {
    setState(RECORD_STATE.NEW_RECORD);
    navigate(RECORD_STATE.NEW_RECORD, { replace });
    setIsOpen(true);
  };

  const showPreviewRecorderWidget = () => {
    setState(RECORD_STATE.PREVIEW_RECORD);
    navigate(RECORD_STATE.PREVIEW_RECORD);
  };

  const hideRecorderWidget = () => {
    setState(RECORD_STATE.INIT);
    navigate(RECORD_STATE.INIT);
    setIsOpen(false);
    setTimeout(() => { setIsOpen(null) },530);
  };

  const goBack = () => {
    if (state === RECORD_STATE.PREVIEW_RECORD) {
      setState(RECORD_STATE.NEW_RECORD);
      navigate(RECORD_STATE.NEW_RECORD);
    } else if (state === RECORD_STATE.NEW_RECORD) {
      setState(RECORD_STATE.INIT);
      navigate(RECORD_STATE.INIT);
    }
  };

  return (
    <RecorderWidgetContext.Provider
      value={{
        isRecorderWidgetOpen: isOpen,
        recorderWidgetState: state,
        goBack,
        showRecorderWidget,
        showPreviewRecorderWidget,
        hideRecorderWidget,
      }}
    >
      {children}
    </RecorderWidgetContext.Provider>
  );
};

export const useRecorderWidget = () => {
  return useContext(RecorderWidgetContext);
};

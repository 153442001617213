import React, { useState } from 'react';

export function Switcher({ leftLabel, rightLabel, defaultState, onToggle, variant, isInverted }) {
  const [isOn, setIsOn] = useState(!!defaultState);
  let variantClassNames;
  const visualIsOn = !isInverted ? isOn : !isOn;

  switch (variant) {
    case 'transparent': {
      variantClassNames = {
        wrapper: 'bg-[#0000001A]',
        control: 'bg-white',
        text: 'text-white',
      };
      break;
    }
    case 'primary': {
      variantClassNames = {
        wrapper: 'bg-[#EE473A]',
        control: 'bg-white',
        text: 'text-[#292626]',
      };
      break;
    }
    default: {
      variantClassNames = {
        wrapper: 'bg-[#0000001A]',
        control: 'bg-white',
        text: 'text-white',
      };
      break;
    }
  }

  const handleToggleVoiceOrText = () => {
    setIsOn(!isOn);
    onToggle && onToggle(!isOn);
  };

  return (
    <div className="switcher flex items-center gap-1 text-white">
      {leftLabel && (
        <label htmlFor="switch" className={`${variantClassNames.text} text-xs ${visualIsOn ? 'opacity-50' : 'opacity-100'}`}>
          {leftLabel}
        </label>
      )}
      <div
        role="switch"
        aria-checked={isOn}
        onClick={handleToggleVoiceOrText}
        className={`${isOn ? variantClassNames.wrapper : 'bg-[#0000001A]'} relative w-10 h-6 flex items-center rounded-[100px] cursor-pointer transition-colors duration-300`}
      >
        <input
          id="switch"
          type="checkbox"
          checked={isOn}
          className="hidden"
        />
        <div
          className={`${variantClassNames.control} absolute w-5 h-5 bg-white rounded-full shadow-md transform transition-transform duration-300 ${visualIsOn ? 'translate-x-4' : 'translate-x-1'}`}
        />
      </div>
      {rightLabel && (
        <label htmlFor="switch" className={`${variantClassNames.text} text-xs ${visualIsOn ? 'opacity-100' : 'opacity-50'}`}>
          {rightLabel}
        </label>
      )}
    </div>
  );
}
